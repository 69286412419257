<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div>
    <div class="button-container my-4 align-items-center">
      <Node
        text="Nieuw"
        iconName="plus"
        navTo="chaptereditor"
        @click="newCaseModalVisable = true"
      />
      <Node
        text="Instellingen"
        iconName="cog"
        navTo="generalsettings"
        @click="navTo"
      />
      <router-link :to="{ name: 'manual'}" target="_blank" style="text-decoration: none;" class="node-container">
      <Node
        text="Documentatie"
        iconName="book-open"
        navTo="manual"
      />
      </router-link>
    </div>
    <div
      class="scenario-list-container w-100 d-flex justify-content-center align-items-center"
    >
      <div class="scenario-list d-flex w-100 mb-3 col-10">
        <div class="scenario-list-content d-flex flex-column w-100 px-3 py-3">
          <ListItem
            title="Naam:"
            code="Code"
            id="Casus:"
            lastModified="Laatst aangepast:"
            published="Zichtbaar in app:"
            color="0"
            mode="Modus:"
            header="true"
          />
          <ListItem
            v-for="(c, i) in cases"
            :key="c.case_id"
            :id="c.case_id"
            :title="c.name"
            :code="c.case_code"
            :lastModified="c.last_modified"
            :published="c.published"
            :mode="c.mode"
            :color="i % 2"
            @changePublished="changePublished"
          />
        </div>
      </div>
    </div>

    <NewCaseModal
      v-show="newCaseModalVisable"
      v-bind:isCase="true"
      @close="newCaseModalVisable = false"
      @create="createCase"
    />
  </div>
</template>

<script>
import Node from "../components/Dashboard/Node";
import ListItem from "../components/Dashboard/ListItem";
import NewCaseModal from "../components/NewCaseModal";

export default {
  name: "Dashboard",
  components: { Node, ListItem, NewCaseModal },
  methods: {
    /** Get all cases */
    getCases() {
      this.$axios
        .get(process.env.VUE_APP_API_HOST + "/cases/all")
        .then(data => {
          this.cases = data.data.corkboards;
        })
        .catch(errors => {
          console.error(errors);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    navTo(name) {
      this.$router.push({
        name: name
      });
    },
    /** Create a new case */
    createCase(name) {
      if (name == "") name = "Nieuwe Casus ";

      this.newCaseModalVisable = false;
      this.$axios
        .put(process.env.VUE_APP_API_HOST + "/case/create/" + name)
        .then(reply => {
          this.getCases();
          this.$router.push({
            name: "corkboard",
            query: {
              case: reply.data
            }
          });
        })
        .catch(errors => {
          console.error(errors);
        });
    },
    changePublished(id, value) {
      return this.$axios
        .post(process.env.VUE_APP_API_HOST + "/case/update/published", {
          caseId: id,
          value: value
        })
        .then(() => {
          this.$router.go();
        })
        .catch(errors => {
          console.error(errors);
        });
    }
  },
  data() {
    return {
      cases: [],
      newCaseModalVisable: false
    };
  },
  created() {
    this.getCases();
  }
};
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  justify-content: space-around;
  min-width: 25em;
}

.scenario-list {
  box-shadow: $box-shadow-white;
  border-radius: 2em;
  min-width: 25em;
}

.scenario-list-content {
  overflow-y: auto;
}
.node-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
