//This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)

import Vue from "vue";
import axios from "axios";

Vue.mixin({
  beforeCreate() {
    // Function to get a new access token if there is still a refreshtoken and commits to the store
    const getNewAccessToken = () => {
      const refreshToken = this.$store.state.refreshToken;
      if (refreshToken) {
        return axios
          .post(process.env.VUE_APP_API_HOST + "/auth/refreshtoken", {
            token: refreshToken
          })
          .then(response => {
            this.$store.commit("setAccessToken", {
              accessToken: response.data.accessToken
            });
          })
          .catch(() => {
            // Go to login page if refreshing the access token failed
            this.$router.push({ name: "Login" });
          });
      } else {
        // Go to login page if there was no refresh token
        this.$router.push({ name: "Login" });
        return Promise.reject("Invalid refresh token");
      }
    };
    //Create an instance of axios to use in components
    const axiosApiInstance = axios.create();
    // Request interceptor for API calls that adds access token to all requests
    axiosApiInstance.interceptors.request.use(
      async config => {
        config.headers = {
          Authorization: `Bearer ${this.$store.state.accessToken}`,
          "Content-Type": "application/json"
        };
        return config;
      },
      error => {
        Promise.reject(error);
      }
    );

    // Response interceptor for API calls, automatically refreshes access token on a 403 error
    axiosApiInstance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        const originalRequest = error.config;
        if (error?.response?.status === 403 && !originalRequest._retry) {
          originalRequest._retry = true;
          originalRequest.baseURL = undefined;
          return getNewAccessToken().then(() => {
            originalRequest.headers.Authorization = `Bearer ${this.$store.state.accessToken}`;
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${this.$store.state.refreshToken}`;
            return axiosApiInstance(originalRequest);
          });
        }
        return Promise.reject(error);
      }
    );
    const options = this.$options;
    if (options.axios || (options.parent && options.parent.$axios))
      this.$axios = axiosApiInstance;
  },
  //Adds the tokens from storage to the store on page load
  created() {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (accessToken && this.$store) {
      this.$store.commit("setAccessToken", { accessToken, refreshToken });
    }
  }
});

export default axios;
