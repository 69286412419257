//This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)

export default {
  props: [
    {
      name: "Backpack",
      image: require("@/assets/Props/Bagpack.png")
    },
    {
      name: "Big_gun",
      image: require("@/assets/Props/Big_gun.png")
    },
    {
      name: "Boy_lying_down",
      image: require("@/assets/Props/Boy_lying_down.png")
    },
    {
      name: "Camera",
      image: require("@/assets/Props/Camera.png")
    },
    {
      name: "Captain",
      image: require("@/assets/Props/Captain.png"),
      person: true
    },
    {
      name: "Closed_container",
      image: require("@/assets/Props/Closed_container.png")
    },
    {
      name: "Coke",
      image: require("@/assets/Props/Coke.png")
    },
    {
      name: "Courier",
      image: require("@/assets/Props/Courier.png"),
      person: true
    },
    {
      name: "Cup",
      image: require("@/assets/Props/Cup.png")
    },
    {
      name: "Door_close",
      image: require("@/assets/Props/Door_close.png")
    },
    {
      name: "Door_open",
      image: require("@/assets/Props/Door_open.png")
    },
    {
      name: "Fingerprint",
      image: require("@/assets/Props/Fingerprint.png"),
      white: true
    },
    {
      name: "Flashy_car",
      image: require("@/assets/Props/Flashy_car.png")
    },
    {
      name: "Footprints",
      image: require("@/assets/Props/Footprints.png")
    },
    {
      name: "Girl_lying_down",
      image: require("@/assets/Props/Girl_lying_down.png")
    },
    {
      name: "Handcuffs",
      image: require("@/assets/Props/Handcuffs.png")
    },
    {
      name: "HeadOfficer",
      image: require("@/assets/Props/HeadOfficer.png")
    },
    {
      name: "HeadOfficer2",
      image: require("@/assets/Props/HeadOfficer2.png"),
      person: true
    },
    {
      name: "Key",
      image: require("@/assets/Props/Key.png")
    },
    {
      name: "Kingpin",
      image: require("@/assets/Props/Kingpin.png"),
      person: true
    },
    {
      name: "Knife_blood",
      image: require("@/assets/Props/Knife_blood.png")
    },
    {
      name: "Knife",
      image: require("@/assets/Props/Knife.png")
    },
    {
      name: "Lab-boy",
      image: require("@/assets/Props/Lab-boy.png"),
      person: true
    },
    {
      name: "Laptop_open",
      image: require("@/assets/Props/Laptop_open.png")
    },
    {
      name: "Laptop",
      image: require("@/assets/Props/Laptop.png")
    },
    {
      name: "MainCharacter",
      image: require("@/assets/Props/MainCharacter.png"),
      person: true
    },
    {
      name: "Medium_gunfla",
      image: require("@/assets/Props/Medium_gunfla.png")
    },
    {
      name: "MicroscopyExpert",
      image: require("@/assets/Props/MicroscopyExpert.png"),
      person: true
    },
    {
      name: "MicroscopyResearcher",
      image: require("@/assets/Props/MicroscopyResearcher.png"),
      person: true
    },
    {
      name: "Money_bill_small",
      image: require("@/assets/Props/Money_bill_small.png")
    },
    {
      name: "Money_bills_big",
      image: require("@/assets/Props/Money_bills_big.png")
    },
    {
      name: "Money_bills_medium",
      image: require("@/assets/Props/Money_bills_medium.png")
    },
    {
      name: "Money_coin_big",
      image: require("@/assets/Props/Money_coin_big.png")
    },
    {
      name: "Money_coin_medium",
      image: require("@/assets/Props/Money_coin_medium.png")
    },
    {
      name: "Money_coin_small",
      image: require("@/assets/Props/Money_coin_small.png")
    },
    {
      name: "OldManJenkins",
      image: require("@/assets/Props/OldManJenkins.png"),
      person: true
    },
    {
      name: "Open_container_texture",
      image: require("@/assets/Props/Open_container_texture.png")
    },
    {
      name: "Open_container",
      image: require("@/assets/Props/Open_container.png")
    },
    {
      name: "Paper_Big",
      image: require("@/assets/Props/Paper_Big.png")
    },
    {
      name: "Paperboy",
      image: require("@/assets/Props/Paperboy.png"),
      person: true
    },
    {
      name: "Phone",
      image: require("@/assets/Props/Phone.png")
    },
    {
      name: "Pills_1",
      image: require("@/assets/Props/Pills_1.png")
    },
    {
      name: "Pills_2",
      image: require("@/assets/Props/Pills_2.png")
    },
    {
      name: "Police_bus",
      image: require("@/assets/Props/Police_bus.png")
    },
    {
      name: "Police_car_behind",
      image: require("@/assets/Props/Police_car_behind.png")
    },
    {
      name: "Police_car_front",
      image: require("@/assets/Props/Police_car_front.png")
    },
    {
      name: "Police_car",
      image: require("@/assets/Props/Police_car.png")
    },
    {
      name: "SecretAgent",
      image: require("@/assets/Props/SecretAgent.png"),
      person: true
    },
    {
      name: "Small_gun",
      image: require("@/assets/Props/Small_gun.png")
    },
    {
      name: "Smoker",
      image: require("@/assets/Props/Smoker.png"),
      person: true
    },
    {
      name: "Sport_car_behind",
      image: require("@/assets/Props/Sport_car_behind.png")
    },
    {
      name: "Sport_car_front",
      image: require("@/assets/Props/Sport_car_front.png")
    },
    {
      name: "Sport_car",
      image: require("@/assets/Props/Sport_car.png")
    },
    {
      name: "Squad_car_inside",
      image: require("@/assets/Props/Squad_car_inside.png")
    },
    {
      name: "Suitcase",
      image: require("@/assets/Props/Suitcase.png")
    },
    {
      name: "Taxi",
      image: require("@/assets/Props/Taxi.png")
    },
    {
      name: "Ton",
      image: require("@/assets/Props/Ton.png")
    },
    {
      name: "Truck_texture",
      image: require("@/assets/Props/Truck_texture.png")
    },
    {
      name: "Truck",
      image: require("@/assets/Props/Truck.png")
    },
    {
      name: "Van",
      image: require("@/assets/Props/Van.png")
    },
    {
      name: "Wallet",
      image: require("@/assets/Props/Wallet.png")
    },
    {
      name: "Weed_greenhouse",
      image: require("@/assets/Props/Weed_greenhouse.png")
    },
    {
      name: "Weed_plant",
      image: require("@/assets/Props/Weed_plant.png")
    },
    {
      white: true,
      name: "Mist1",
      image: require("@/assets/Shadows/Mist1.png")
    },
    {
      white: true,
      name: "Mist2",
      image: require("@/assets/Shadows/Mist2.png")
    },
    {
      name: "Shadow1",
      image: require("@/assets/Shadows/Shadow1.png")
    },
    {
      name: "Shadow2",
      image: require("@/assets/Shadows/Shadow2.png")
    },
    {
      name: "Shadow3",
      image: require("@/assets/Shadows/Shadow3.png")
    },
    {
      name: "Belgian_officer",
      image: require("@/assets/Props/Belgian_officer.png"),
      person: true
    },
    {
      name: "dealer",
      image: require("@/assets/Props/dealer.png"),
      person: true
    },
    {
      name: "driver",
      image: require("@/assets/Props/driver.png"),
      person: true
    },
    {
      name: "Drugboss",
      image: require("@/assets/Props/Drugboss.png"),
      person: true
    },
    {
      name: "Dutch_officer",
      image: require("@/assets/Props/Dutch_officer.png"),
      person: true
    },
    {
      name: "Festival_person",
      image: require("@/assets/Props/Festival_person.png"),
      person: true
    },
    {
      name: "German_officer",
      image: require("@/assets/Props/German_officer.png"),
      person: true
    },
    {
      name: "Informant",
      image: require("@/assets/Props/Informant.png"),
      person: true
    },
    {
      name: "Kid",
      image: require("@/assets/Props/Kid.png"),
      person: true
    },
    {
      name: "Lab_worker",
      image: require("@/assets/Props/Lab_worker.png"),
      person: true
    },
    {
      name: "mayor",
      image: require("@/assets/Props/mayor.png"),
      person: true
    },
    {
      name: "Trucker",
      image: require("@/assets/Props/Trucker.png"),
      person: true
    }
  ]
};
