var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loadNode)?_c('Moveable',_vm._b({ref:"moveable",staticClass:"node overflow-hidden ",style:({
    transform: 'matrix(1, 0, 0, 1, ' + _vm.position.x + ', ' + _vm.position.y + ')'
  }),attrs:{"container":_vm.canvas,"scrollContainer":_vm.canvasScroll},on:{"drag":_vm.handleDrag,"dragEnd":_vm.handleEndDrag,"scroll":_vm.handleScroll},nativeOn:{"contextmenu":function($event){$event.preventDefault();return _vm.$emit('openInfoPane')}}},'Moveable',_vm.moveable,false),[(_vm.nodeTypes[_vm.nodeType] != _vm.nodeTypes.START)?_c('div',{staticClass:"inputdot d-flex justify-content-center"},[_c('div',{ref:"inputNode",staticClass:"dot",attrs:{"id":'inputNode_' +
          (_vm.nodeTypes[_vm.nodeType] == _vm.nodeTypes.SCENE ? _vm.sceneid : 'end')},on:{"mouseup":function($event){return _vm.$emit('selectInputNode')}}})]):_vm._e(),_c('span',{staticClass:"pb-1 mx-4",class:{ 'node-id': _vm.nodeTypes[_vm.nodeType] == _vm.nodeTypes.SCENE }},[_vm._v(_vm._s(_vm.name))]),(_vm.nodeTypes[_vm.nodeType] != _vm.nodeTypes.START)?_c('div',{staticClass:"d-flex align-self-center justify-content-center mx-2"},[(_vm.nodeTypes[_vm.nodeType] == _vm.nodeTypes.SCENE)?_c('button',{staticClass:"button edit d-flex align-self-center justify-content-center mx-1",on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.stopPropagation();return _vm.$emit('editNode')}}},[_c('font-awesome-icon',{staticClass:"icon node-icon-edit d-flex align-self-center ",attrs:{"icon":"pen"}})],1):_vm._e(),_c('button',{staticClass:"button info  d-flex align-self-center justify-content-center mx-1",class:{
        button:_vm.nodeTypes[_vm.nodeType] == _vm.nodeTypes.SCENE,
        'button-endnode':_vm.nodeTypes[_vm.nodeType] == _vm.nodeTypes.END
      },on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.stopPropagation();return _vm.$emit('openInfoPane')}}},[_c('font-awesome-icon',{staticClass:"icon node-icon-info d-flex align-self-center",attrs:{"icon":"info"}})],1),(_vm.nodeTypes[_vm.nodeType] == _vm.nodeTypes.SCENE)?_c('button',{staticClass:"button info d-flex align-self-center justify-content-center mx-1",on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.stopPropagation();return _vm.$emit('openSoundPane')}}},[_c('font-awesome-icon',{staticClass:"icon node-icon-info d-flex align-self-center",attrs:{"icon":"music"}})],1):_vm._e()]):_vm._e(),(_vm.nodeTypes[_vm.nodeType] == _vm.nodeTypes.SCENE)?_c('div',{staticClass:"scenedots row mx-0 h-100 d-flex justify-content-center align-items-center"},_vm._l((_vm.sceneChanges),function(sceneChange,i){return _c('div',{key:i,staticClass:"scenechange"},[_c('div',{staticClass:"d-flex flex-column justify-content-center"},[_c('div',{staticClass:"prop-container flex-column d-flex justify-content-center align-items-center"},[_c('small',[_c('small',[(sceneChange.letter)?_c('span',[_vm._v(" "+_vm._s(sceneChange.letter)+" ")]):_vm._e()])]),_c('div',{staticClass:"propimg"},[_c('img',{staticClass:"image",attrs:{"src":sceneChange.image}})])]),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('div',{ref:_vm.sceneid + '_dot_' + i,refInFor:true,staticClass:"dot dotoutput",on:{"mousedown":function($event){_vm.linedrag = true;
              _vm.$emit('selectOutputNode', i);}}})])])])}),0):_vm._e(),(_vm.nodeTypes[_vm.nodeType] == _vm.nodeTypes.START)?_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"dot",on:{"mousedown":function($event){$event.stopPropagation();return _vm.$emit('selectStartNode')}}})]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }