<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div>
    <table class="table mb-0">
      <thead class="thead-light">
        <tr>
          <th scope="col" class="py-2">#</th>
          <th scope="col" class="pl-5 py-2">Land:</th>
          <th class="right"></th>
        </tr>
      </thead>
      <td scope="row"></td>
      <td class="px-5 w-100 py-2">
        <select
          class="form-control"
          :value="country"
          @change="$emit('countryChange', $event)"
        >
          <option value="NL" selected>Nederland</option>
          <option value="BE">Belgie</option>
          <option value="DE">Duitsland</option>
        </select>
      </td>
      <td class="right pr-4"></td>
    </table>
  </div>
</template>

<script>
export default {
  name: "CountrySelect",
  props: ["country"]
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate !important;
  border-spacing: 0;
}
</style>
