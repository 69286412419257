//This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)

export default {
  "event:changeFlag": "Verander Flag",
  "event:dialogue": "Dialoog",
  "event:options": "Opties",
  "event:sound": "Geluid",
  "event:changeScene": "Verander Scene",
  "event:hide": "Verberg",
  "event:show": "Verschijn",
  "event:time": "Tijd",
  "event:notebook": "Notitieblok",
  "event:minigame": "Minigame",
  "event:emptyEvent": "Leeg Event"
};
