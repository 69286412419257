var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loadNode)?_c('Moveable',_vm._b({ref:"moveable",staticClass:"node overflow-hidden",class:{
      playerdialogue:_vm.node.type == 'Player',
      nonplayerdialogue:_vm.node.type == 'NonPlayer', 
      choicemoment:_vm.node.type == 'Choice' && !_vm.selected,
      selectedchoice:_vm.node.type == 'Choice' && _vm.selected,
      optiondialogue:_vm.node.type == 'Option',
      startdialogue:_vm.node.type == 'Start',
      
      selected:_vm.node.type != 'Choice' && _vm.selected,
    },style:({
      transform:
        'matrix(1, 0, 0, 1, ' + _vm.node.position.x + ', ' + _vm.node.position.y + ')',
    }),attrs:{"container":_vm.canvas,"scrollContainer":_vm.canvasScroll,"className":'dialogue-moveable'},on:{"dragStart":_vm.openEditPanel,"drag":_vm.handleDrag,"dragEnd":_vm.handleEndDrag,"scroll":_vm.handleScroll,"click":_vm.openEditPanel}},'Moveable',_vm.moveable,false),[_c('div',{ref:"dialogueNode",staticClass:"h-100",class:{
        playerdialogue:_vm.node.type == 'Player',
        nonplayerdialogue:_vm.node.type == 'NonPlayer', 
        choicemoment:_vm.node.type == 'Choice' && !_vm.selected,
        selectedchoice:_vm.node.type == 'Choice' && _vm.selected,
        optiondialogue:_vm.node.type == 'Option',
        startdialogue:_vm.node.type == 'Start',
        
        selected:_vm.node.type != 'Choice' && _vm.selected,
      },attrs:{"id":_vm._uid}},[(this.node.type!='Start')?_c('div',{staticClass:"d-flex justify-content-center h-em-1"},[_c('div',{ref:"dotinput",staticClass:"dot inputdot",on:{"mouseup":function($event){return _vm.$emit('selectInputDot', _vm.node)}}}),(this.node.type=='NonPlayer')?_c('div',{staticClass:"prop-container w-100 align-items-end mr-1 mt-1"},[_c('div',{staticClass:"icon-box"},[(this.node.portrait == 'Nothing')?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"ban"}}):_c('img',{staticClass:"icon",attrs:{"src":require('../../assets/Props/' + this.node.portrait + '.png')}})],1)]):_vm._e()]):_vm._e(),(this.node.type!='Start' && this.node.type!='Choice')?_c('div',{class:{'gray-line mt-2':!_vm.selected, 'gray-line-dark mt-2':_vm.selected}}):_vm._e(),(this.node.type!='Start' && this.node.type!='Choice')?_c('div',{staticClass:"d-flex align-items-center justify-content-center w-100 h-70"},[_c('div',{staticClass:"w-100 h-100 overflow-auto px-1"},[_c('span',[_vm._v(_vm._s(this.node.text))])])]):_vm._e(),(this.node.type!='Start' && this.node.type!='Choice')?_c('div',{class:{'gray-line':!_vm.selected, 'gray-line-dark':_vm.selected}}):_vm._e(),(this.node.type=='Start')?_c('div',{staticClass:"justify-content-center h-100 mt-3 mr-1"},[_c('span',[_vm._v("Start")])]):_vm._e(),_c('div',{staticClass:"scenedots d-flex justify-content-center"},[_c('div',{staticClass:"row"},_vm._l((_vm.node.outputDots),function(dot,i){return _c('NodeDot',{key:i,ref:"nodeDot",refInFor:true,staticClass:"col p-0",attrs:{"dotData":dot},on:{"createArrow":_vm.createArrow,"updateOutputDotPositions":_vm.updateOutputDotPositions,"mousedown":function($event){_vm.linedrag = true;
              _vm.$emit('selectOutputNode', i)}}})}),1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }