<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <Moveable
    v-bind="moveable"
    @drag="handleDrag"
    @dragEnd="handleEndDrag"
    @scroll="handleScroll"
    :container="canvas"
    :scrollContainer="scrollCanvas"
    v-if="loadNode"
    :id="id"
    class="node d-flex flex-column align-items-center justify-content-center"
    :style="{
      transform:
        'matrix(1, 0, 0, 1, ' +
        chapter.corkboardX +
        ', ' +
        chapter.corkboardY +
        ')',
      left: chapter.corkboardX,
      top: chapter.corkboardY
    }"
    ref="moveable"
  >
    <!-- The input dot -->
    <div class="inputdot d-flex justify-content-center">
      <div
        class="dot"
        ref="inputDot"
        @mouseup="$emit('selectInputNode', inputDot)"
      ></div>
    </div>
    <!-- The chapter name -->
    <span class="node-id pb-1 mx-4"> {{ name }}</span>
    <!-- A preview image -->
    <div v-if="image != null">
      <img
        :src="image"
        @load="
          updateDotPos();
          $refs.moveable.updateRect();
        "
        class="preview-image"
      />
    </div>
    <div v-else>
      <img
        src="../../assets/Props/Paper_Big.png"
        @load="
          updateDotPos();
          $refs.moveable.updateRect();
        "
        class="preview-image"
      />
    </div>
    <!-- Buttons to add or remove output dots -->
    <div class="d-flex align-self-center justify-content-center mx-2">
      <button
        class="button add d-flex align-self-center justify-content-center mx-1"
        @click.stop="addOutputNode"
      >
        <font-awesome-icon
          class="icon node-icon-add d-flex align-self-center "
          icon="plus"
        />
      </button>
      <button
        class="button delete d-flex align-self-center justify-content-center mx-1"
        @click.stop="deleteOutputNode"
      >
        <font-awesome-icon
          class="icon node-icon-delete d-flex align-self-center"
          icon="minus"
        />
      </button>
    </div>
    <!-- The output dots -->
    <div
      class="scenedots row h-100 d-flex justify-content-center align-items-center"
    >
      <div
        v-for="(dot, i) in outputNodes"
        :key="i"
        class="d-flex align-items-center justify-content-center"
        :ref="'dot_' + i"
      >
        <div class="dot dotoutput" @mousedown.stop="selectOutputNode(i)"></div>
      </div>
    </div>
  </Moveable>
</template>

<script>
import Moveable from "vue-moveable";
export default {
  name: "ChapterNode",
  components: {
    Moveable
  },
  props: {
    id: Number,
    name: String,
    image: undefined,
    connections: undefined,
    chapter: Object,
    canvas: HTMLDivElement,
    scrollCanvas: HTMLDivElement,
    overlaps: Function,
  },
  data() {
    return {
      outputNodes: [],
      inputDot: {},
      initConnections: false,
      moveable: {
        draggable: true,
        throttleDrag: 1,
        snappable: true,
        scrollable: true,
        scrollThreshold: 0
      },
      loadNode: false,
      loadedDots: false,
    };
  },
  methods: {
    addOutputNode(initChapterId = null) {
      let node = {
        dotId: this.outputNodes.length,
        dotPos: { x: 0, y: 0 },
        initChapterId: initChapterId
      };
      this.outputNodes.push(node);
      this.$nextTick(() => {
        this.updateDotPos();
        this.$refs.moveable.updateRect();
      });
    },
    //deletes the connection between appropiate nodes when an output dot is deleted
    deleteOutputNode() {
      if (this.connections != undefined) {
        this.$emit(
          "deleteChapterConnection",
          this.id,
          this.outputNodes.length - 1
        );
      }
      this.outputNodes.pop();
      this.$nextTick(() => {
        this.updateDotPos();
        this.$refs.moveable.updateRect();
      });
    },
    //calculates the output dot position
    dotPos(dotId) {
      let dot = this.$refs["dot_" + dotId][0].getBoundingClientRect();
      return {
        x: dot.left + dot.width / 2 - this.canvas.getBoundingClientRect().left,
        y: dot.top + dot.height / 2 - this.canvas.getBoundingClientRect().top
      };
    },
    //returns an output dot, given an id
    getDot(dotId) {
      for (let i = 0; i < this.outputNodes.length; i++) {
        if (dotId == this.outputNodes[i].dotId) {
          return this.outputNodes[i];
        }
      }
    },
    //when selecting an output node, create an object to pass information and emit it to create a line
    selectOutputNode(dotId) {
      let outputNode = {
        chapterId: this.id,
        dotId: dotId,
        dot: this.getDot(dotId)
      };
      this.$emit("selectOutputNode", outputNode);
    },
    //updates the input dot position
    updateInputDotPos() {
      let inputDotPos = this.$refs.inputDot.getBoundingClientRect();
      return {
        x:
          inputDotPos.left +
          inputDotPos.width / 2 -
          this.canvas.getBoundingClientRect().left,
        y:
          inputDotPos.top +
          inputDotPos.height / 2 -
          this.canvas.getBoundingClientRect().top
      };
    },
    //updates every output dots and the input dot
    updateDotPos() {
      this.inputDot.dotPos = this.updateInputDotPos();
      this.outputNodes.forEach(dot => {
        dot.dotPos = this.dotPos(dot.dotId);
      });
    },
    //Sets the position when node is being dragged
    handleDrag({ target, transform }) {
      target.style.transform = transform;
      const position = {
        x:
          parseInt(transform.split(")")[0].split(",")[4]) +
          parseInt(
            transform
              .split("(")[2]
              .split(",")[0]
              .split("px")[0]
          ),
        y:
          parseInt(transform.split(")")[0].split(",")[5]) +
          parseInt(
            transform
              .split("(")[2]
              .split(",")[1]
              .split("px")[0]
          )
      };
      this.$emit("setPosition", position);
      this.updateDotPos();
    },
    //Reset the node's position when overlapping another node after a drag
    handleEndDrag({ lastEvent }) {
      const rect = this.$refs.moveable.getRect();
      const overlapping = this.overlaps(rect);
      if (overlapping) {
        const position = {
          x: this.chapter.corkboardX - lastEvent.beforeTranslate[0],
          y: this.chapter.corkboardY - lastEvent.beforeTranslate[1]
        };
        this.$emit("setPosition", position);
        this.$nextTick(() => {
          this.$refs.moveable.updateRect();
          this.updateDotPos();
        });
      }
    },
    //Handle the scroll whenever the node is being dragged close to the edges of the canvas
    handleScroll({ scrollContainer, direction }) {
      scrollContainer.scrollTo(
        scrollContainer.scrollLeft + direction[0] * 5,
        scrollContainer.scrollTop + direction[1] * 5
      );
    },
    //When the image is loaded, update the moveable rectangle
    onImageLoad() {
      this.$refs.moveable.updateRect();
    }
  },
  mounted() {
    //emit the input dots to the corkboard, to initialise line drawings
    this.$nextTick(() => {
      this.inputDot = {
        chapterId: this.id,
        dotPos: this.updateInputDotPos()
      };
      this.$emit("initInputDots", this.inputDot);
    });
    this.loadNode = true;
    //Update the moveable bounds
    const rect = this.canvas.getBoundingClientRect();
    this.moveable.bounds = {
      top: 0,
      left: 0,
      right: rect.width,
      bottom: rect.height
    };
    this.$nextTick(() => {
      this.$refs.moveable.updateRect();
    });
  },
  watch: {
    //initialise lines when the page is loaded
    connections: function() {      
      if (this.loadedDots)
        return;
      //Only do this at the start
      this.loadedDots = true;
      if (this.connections != undefined) {
        //Add output dots equal to the outputCount for this chapter
        for (let i = 0; i < this.chapter.outputCount; i++) {
          this.addOutputNode(this.chapter.chapterId);
        }
        //Tell the Corkboard that we can load the appropiate lines.
        this.$emit("loadOutputLines", this.outputNodes);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.node {
  background-color: $lightgray;
  position: absolute;
  text-align: center;
  border: 0px;
  flex: 0 0 auto;
  width: 6em;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
}

.prop-container {
  height: 2.7em;
  position: relative;
  display: flex;
  flex-direction: column;
}
.propimg {
  height: 1.5em;
  width: 1.7em;
}

.icon {
  position: relative;
  width: 0.7em;
  height: 0.7em;
}
.preview-image {
  width: 100%;
  height: 100%;
}
.button {
  border-radius: 20px;
  border: 0px;
  width: 1.5em;
  height: 1.5em;
  background-color: $white;
  margin-top: 10px;
  padding: 5px;
  border: solid 0.1em rgba(0, 0, 0, 0);
  outline: none;
  &:hover {
    border: solid 2px $black;
  }
  &:focus {
    border: solid 2px $green;
  }
}

.node-id {
  position: relative;
  border-bottom: $basic-border-black;
  text-align: center;
  width: 100%;
}

.scenedots {
  position: relative;
  bottom: 0px;
  width: 100%;
  min-height: 1em;
}

.scenechange {
  width: 2em;
  height: 4em;

  .image {
    max-width: 100%;
    max-height: 100%;
  }
}

.inputdot {
  position: relative;
  top: 0em;
  width: 100%;
  left: 0;
}

.dot {
  width: 0.7em;
  height: 0.7em;
  background-color: $black;
  border-radius: 100%;
  margin: 0.2em;

  &:hover {
    background-color: rgb(0, 120, 240);
  }
}

.col {
  position: inherit !important;
}
</style>
