<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <slot name="header">
          <b v-if="isCase">Casus naam:</b>
          <b v-else-if="isPassword && password != null">
            <b>Vul niks in om de huidige code te verwijderen. </b>
            <b>Huidige code: {{ password }}</b>
          </b>
          <b v-else-if="isPassword"
            >Code toevoegen (een code kan alleen bestaan uit cijfers):</b
          >
          <b v-else>Hoofdstuk naam:</b>
        </slot>
      </header>
      <section class="modal-body">
        <slot name="body">
          <input
            v-if="!isPassword"
            maxlength="25"
            autocomplete="off"
            v-model="inputValue"
            :placeholder="isCase ? 'Nieuwe Casus' : 'Nieuwe Hoofdstuk'"
          />
          <input
            v-else
            maxlength="8"
            autocomplete="off"
            v-model="inputValue"
            :placeholder="password != null ? '' : 'Nieuwe Code'"
          />
        </slot>
      </section>
      <footer class="modal-footer">
        <slot name="footer">
          <button
            type="button"
            class="button cancel px-3 py-1 ml-5"
            @click="$emit('close')"
          >
            Annuleren
          </button>
          <button
            type="button"
            class="button ok px-3 py-1 mr-5"
            @click="create"
          >
            Aanmaken
          </button>
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewCaseModal",
  props: { isCase: Boolean, isPassword: Boolean, password: null },
  data() {
    return {
      inputValue: ""
    };
  },
  methods: {
    create() {
      this.$emit("create", this.inputValue);
      this.inputValue = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.modal-header {
  border: 0px;
  display: flex;
  justify-content: center;
}
.modal-body {
  border: 0px;
  display: flex;
  justify-content: center;
  input {
    border-radius: 1em;
    border: $basic-border;
    &:focus {
      outline: unset;
      box-shadow: $box-shadow-focus;
    }
  }
}
.modal-footer {
  border: 0px;
  display: flex;
  justify-content: space-between;
}
.modal {
  background: #ffffff;
  box-shadow: 0px 0px 1.2em -0.3em;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  border-radius: 2em;
  top: auto;
  left: auto;
  width: 30em;
  height: 12em;
  z-index: 1;
}
.button {
  font-family: $default-font;
  border-radius: 2em;
  border: 0px;
  &:focus {
    outline: unset;
    box-shadow: $box-shadow-focus;
  }
}
.cancel {
  background-color: $red;
  color: $white;
}
.ok {
  background-color: $green;
  color: $white;
}
</style>
