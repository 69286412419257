<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div class="header-row mx-5 mt-4 mb-1 d-flex">
    <div class="text-container mx-3 d-flex" v-if="showTitle">
      <span class="scenario-name-prefix d-flex">{{ this.prefix }} - </span>
      <!-- The title/name of this view before you start editing it -->
      <span v-if="!this.editingName" class="name-container d-flex">
        <span class="scenario-name" contenteditable="false">{{
          this.name
        }}</span>
        <!-- Show the edit button if the title is editable -->
        <font-awesome-icon
          v-if="editable"
          @click="editName"
          class="header-icon ml-2 align-self-center d-flex"
          icon="pen"
        />
      </span>
      <!-- The editable input field for the title/name -->
      <span v-else class="name-container d-flex">
        <span
          role="textbox"
          type="text"
          ref="inputSpan"
          autocomplete="none"
          class="scenario-input scenario-name"
          placeholder="Scenario naam..."
          @keypress="checkInputLength"
          contenteditable
          >{{ this.name }}</span
        >
        <font-awesome-icon
          @click="editName"
          class="header-check header-icon ml-1 align-self-center d-flex"
          icon="check"
        />
      </span>
    </div>

    <div
      class="buttons-container d-flex mt-0"
      :class="
        isCorkboard
          ? 'header-corkboard mr-3 ml-auto '
          : 'buttons-container-box mr-3 ml-auto '
      "
    >
      <button @click="$emit('navigate')" class="button button-back w-100">
        Terug
      </button>
      <!--Don't show the delete button in the general settings-->
      <button v-if="$route.name != 'generalsettings'" @click="$emit('remove')" class="button button-delete w-100">
        Verwijder
      </button>
      <button
        @click="$emit('save')"
        class="button button-save w-100"
        :disabled="saving"
      >
        Opslaan
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderRow",
  data() {
    return {
      editingName: false
    };
  },
  props: {
    id: Number,
    name: String,
    prefix: String,
    saving: Boolean,
    chapterEditor: Boolean,
    isCorkboard: {
      default: false,
      type: Boolean
    },
    editable: Boolean,
    showTitle: Boolean
  },
  methods: {
    /** Check the length of the input and add a limit */
    checkInputLength(x) {
      if (x.key == "Enter") {
        this.editName();
        return;
      }

      /**  cap string if needed */
      if (this.$refs.inputSpan.innerHTML.length > 25) {
        this.$refs.inputSpan.innerHTML = this.$refs.inputSpan.innerHTML.substring(
          0,
          26
        );
        this.editName();
      }
    },
    /** Edit the name of the case */
    editName() {
      let valid = true;

      if (this.$refs.inputSpan) {
        this.name = this.name.trim();
        var name = this.$refs.inputSpan.innerHTML;
        name = name.trim();

        if (name.length == 0) valid = false;
        else this.$emit("updateName", name);
      }
      if (valid) this.editingName = !this.editingName;
    }
  }
};
</script>

<style lang="scss" scoped>
.header-row {
  height: 3em;
}
.text-container {
  font-family: $default-font;
  font-size: 2em;
  color: $black;
  overflow: hidden;
}

.name-container {
  overflow: hidden;
}
.scenario-name-prefix {
  white-space: pre;
}

.scenario-name {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: pre;
  &::-webkit-scrollbar {
    display: none;
  }
}
.header-icon {
  width: 0.5em;
  height: 0.5em;
  &:hover {
    cursor: pointer;
  }
}

.scenario-input {
  border: 0px;
  border-bottom: $basic-border;
  white-space: pre;
}

.header-check {
  width: 0.7em;
  height: 0.7em;
  &:hover {
    color: $green;
  }
}
.header-corkboard {
  //needs a better way to center it with the nieuw hoofdstuk button in the corkboard view
  width: 19em;
  margin-right: 17.7em;
  margin-left: auto;
}
.buttons-container {
  flex: 0 0 auto;
  border-radius: 2em;
  /* box-shadow: 0px 0px 15px 1px rgba(79, 79, 79, 0.25); */
  box-shadow: $box-shadow-white;
}
.buttons-container-box {
  width: 19em;
  height: 3em;
}
.button {
  font-family: $default-font;
  border: 0px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  &:focus {
    outline: unset;
    box-shadow: $box-shadow-focus;
  }
}

.button-back {
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
  background-color: $white;
  &:hover{
    background-color: $semiwhite;
  }
}

.button-save {
  border-top-right-radius: 2em;
  border-bottom-right-radius: 2em;
  background-color: $green;
  color: $white;
  &:hover{
    background-color: $darkgreen;
  }
}

.button-delete {
  background-color: $red;
  color: $white;
  &:hover{
    background-color: $darkred;
  }
}
</style>
