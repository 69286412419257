<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <svg v-if="loaded">
    <defs>
      <marker
        id="arrowhead"
        markerWidth="20"
        markerHeight="14"
        refX="0"
        refY="7"
        orient="auto"
      >
        <polygon points="20 0, 0 7, 20 14" />
      </marker>
    </defs>
    <polyline
      :points="line.x2 + ',' + line.y2 + ' ' + line.x1 + ',' + line.y1"
      fill="none"
      stroke-width="1px"
      stroke="black"
      marker-start="url(#arrowhead)"
    />
  </svg>
</template>

<script>
export default {
  name: "NodeLine",
  data() {
    return {
      mousePos: { x: 0, y: 0 },
      inputPos: { x: 0, y: 0 },
      loaded: false
    };
  },
  props: [
    "node",
    "inputNode",
    "canvas",
    "startScenePosition",
  ],
  methods: {
    drawLine(event) {
      this.mousePos = { x: event.clientX, y: event.clientY };
    },
    completeLine() {
      if (!this.node.inputNode || this.node.inputNode == -1) {
        if (this.node.startNode) {
          this.$emit("resetStartNode");
        }
        this.$emit("removeLine", this.node);
      }
    },
    // Update the input position
    updateInputPos() {
      if (this.node.inputNode) {
        const vueNode = this.inputNode;
        if (vueNode && vueNode[0]) {
          this.$nextTick(() => {
            const inputDot = vueNode[0].$refs.inputNode;
            this.inputPos = {
              x:
                this.node.inputNode.x +
                vueNode[0].getSize().width / 2 -
                inputDot.getBoundingClientRect().width / 1.25,
              y: this.node.inputNode.y
            };
          });
        }
      }
    }
  },
  computed: {
    // Computed input node position
    inputNodePos() {
      if (this.node.inputNode) {
        return { x: this.node.inputNode.x, y: this.node.inputNode.y };
      }
      return null;
    },
    // Compute line positions
    line() {
      const canvasPos = this.canvas.getBoundingClientRect();
      let line = {};
      line.x1 = this.node.position.x;
      line.y1 = this.node.position.y;
      if (this.node.startNode) {
        line.x1 += 41;
        line.y1 += 32;
      } else {
        line.x1 -= 1;
        line.y1 -= 8;
      }
      if (this.node.inputNode && this.node.inputNode != -1) {
          line.x2 = this.inputPos.x + 9;
          line.y2 = this.inputPos.y + 8;
      } else {
        line.x2 = this.mousePos.x - canvasPos.left;
        line.y2 = this.mousePos.y - canvasPos.top;
      }

      return line;
    },
    inputNodeName() {
      return this.node.inputNode ? this.node.inputNode.name : undefined;
    }
  },
  created() {
    document.addEventListener("mousedown", this.drawLine);
    document.addEventListener("mousemove", this.drawLine);
    document.addEventListener("mouseup", this.completeLine);
  },
  mounted() {
    this.loaded = true;
    this.$nextTick(() => this.$nextTick(this.updateInputPos()));
  },
  beforeDestroy() {
    document.removeEventListener("mousedown", this.drawLine);
    document.removeEventListener("mousemove", this.drawLine);
    document.removeEventListener("mouseup", this.completeLine);
  },
  watch: {
    // If input node position is changed then update input position
    inputNodePos() {
      this.updateInputPos();
    },
    // If the input node name changes, also update the input position
    inputNodeName() {
      this.updateInputPos();
    }
  }
};
</script>

<style lang="scss" scoped></style>
