import { render, staticRenderFns } from "./AttributeOverview.vue?vue&type=template&id=756f0f23&scoped=true&"
import script from "./AttributeOverview.vue?vue&type=script&lang=js&"
export * from "./AttributeOverview.vue?vue&type=script&lang=js&"
import style0 from "./AttributeOverview.vue?vue&type=style&index=0&id=756f0f23&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "756f0f23",
  null
  
)

export default component.exports