<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div class="scene-editor  w-100 h-100">
    <div v-if="loading">
      <LoadingMessage />
    </div>
    <div class="d-flex flex-column w-100 h-100" v-else>
      <ToastMessage
        :message="'Opgeslagen!'"
        ref="toast"
        v-if="saveVisible"
        :close="
          () => {
            saveVisible = false;
          }
        "
        :closeTimeout="2000"
      />
      <HeaderRow
        :editable="true"
        :id="this.scene.sceneId"
        :name="this.scene.name"
        :prefix="'Scene'"
        :saving="saving"
        :chapterEditor="false"
        :showTitle="true"
        @updateName="editSceneTitle"
        @navigate="navigateUp"
        @remove="showVerifyDeleteSceneModal = true"
        @save="saveScene"
      />

      <div class="canvas-properties-container px-5 py-4 d-flex h-100">
        <div
          class="canvas-container d-flex mx-3 w-100 h-100"
          v-if="!this.showAttributes"
        >
          <div
            id="canvas"
            ref="canvas"
            class="canvas w-100 h-100 position-relative"
            :style="[
              {
                backgroundImage:
                  'url(' + backgrounds[scene.background].image + ')'
              }
            ]"
          >
            <Props
              v-for="prop in Object.values(props).filter(
                pr => pr.name != 'contactsProp'
              )"
              :key="prop.prop_id"
              :prop="prop"
              :index="prop.prop_id"
              :selected="propEditID == prop.prop_id"
              @editProp="editProp"
              :canvasSize="canvasSize"
              :canvas="$refs.canvas"
              @loaded="onWindowResize"
              @setRotation="prop.rotation = $event"
              @setPosition="prop.position = $event"
              @setScale="prop.scale = $event"
            />
          </div>
        </div>
        <div
          v-if="this.showAttributes"
          class="attribute-overview-container d-flex flex-column mr-4 w-100 mb-4 ml-3"
        >
          <CountrySelect
            :country="country"
            @countryChange="country = $event.target.value"
          />
        </div>
        <div
          class="properties-container d-flex flex-column align-self-start mx-3   "
        >
          <!-- The header of the properties panel that contains each of the icon buttons -->
          <div class="properties-header d-flex w-100">
            <span
              @click="setWindow(1)"
              :class="getActiveStyle(1)"
              class="properties-span properties-span-border"
              data-toggle="tooltip"
              title="Achtergronden"
            >
              <small><font-awesome-icon icon="image"/></small>
            </span>
            <span
              @click="setWindow(2)"
              :class="getActiveStyle(2)"
              class="properties-span properties-span-border"
              data-toggle="tooltip"
              title="Props"
            >
              <small><font-awesome-icon icon="box"/></small>
            </span>
            <span
              @click="setWindow(4)"
              :class="{ notActive: !showAttributes }"
              class="properties-span properties-span-border"
              data-toggle="tooltip"
              title="Land uitkiezen"
            >
              <small><font-awesome-icon icon="flag"/></small>
            </span>
            <span
              @click="setWindow(5)"
              :class="getActiveStyle(5)"
              class="properties-span properties-span-border"
              data-toggle="tooltip"
              title="Contact belmomenten"
            >
              <small><font-awesome-icon icon="mobile"/></small>
            </span>
            <span
              :class="getActiveStyle(3)"
              class="properties-span no-hover"
              data-toggle="tooltip"
              title="Prop instellingen"
            >
              <small><font-awesome-icon icon="cog"/></small>
            </span>
          </div>

          <!-- CONTENT 1 -->
          <div
            class="properties-content1 d-flex flex-column"
            v-if="this.selectedProperties == 1"
          >
            <span class="properties-header mt-2 d-flex justify-content-center"
              ><b>Achtergrond:</b></span
            >
            <div class="divider mt-1 d-flex"></div>
            <div class="scroll ">
              <div
                class="background-carousel mb-3 d-flex flex-column w-100 "
                v-for="(x, i) in backgrounds"
                :key="i"
              >
                <button
                  class="w-100 p-0 d-flex justify-content-center align-items-center h-100 w-100"
                  @click="changeBackground(i)"
                >
                  <img
                    :src="x.image"
                    alt=""
                    height="auto"
                    draggable="false"
                    class="d-flex w-100"
                  />
                </button>
              </div>
            </div>
          </div>

          <!-- CONTENT 2 -->
          <div
            class="properties-content1 flex-column d-flex h-100"
            v-else-if="this.selectedProperties == 2"
          >
            <span class="properties-header mt-2"><b>Objecten:</b></span>
            <div class="divider mt-1"></div>

            <div class="scroll">
              <div v-for="(propButton, i) in propButtons" :key="i">
                <PropButtons
                  :propButton="propButton"
                  :whiteImage="propButton.white"
                  :index="i"
                  @addProp="addProp"
                />
              </div>
            </div>
          </div>

          <!-- CONTENT 3 -->
          <div
            class="properties-content3 px-1 pb-4"
            v-else-if="this.selectedProperties == 3"
          >
            <div class="d-flex justify-content-center">
              <button
                class="node-button w-100 mt-2"
                @click="showVerifyDeletePropModal = true"
              >
                Verwijder object
              </button>
            </div>

            <span class="properties-header add-event-header mt-2"
              ><b class="mt-1" style="display:inline-block;"
                >Grootte aanpassen:</b
              ></span
            >
            <div class="mx-4">
              <input
                type="range"
                class="custom-range"
                autocomplete="off"
                min="0.5"
                max="5"
                step="0.01"
                v-model.number="props[propEditID].scale"
              />
            </div>

            <span class="properties-header"
              ><b style="display:inline-block;">Rotatie aanpassen:</b></span
            >
            <!--Rotation slider starts here-->
            <div class="mx-4">
              <round-slider
                style="margin:5% 19%"
                v-model.number="props[propEditID].rotation"
                min="-180"
                max="180"
                startAngle="270"
                width="8"
                handleSize="16"
                radius="75"
                pathColor="#dee2e6"
                rangeColor="#dee2e6"
                tooltipColor="#2c3e50"
                keyboardAction="false"
                :tooltipFormat="roundSliderTooltip"
              />
            </div>
            <!--Rotation slider ends here-->

            <span class="properties-header"
              ><b style="display:inline-block;">Diepte aanpassen:</b></span
            >
            <!-- Number Input Spinner package implemented. Spinner goes from 0 to 10.-->
            <NumberInputSpinner
              :key="propEditID"
              :min="0"
              :max="10"
              :step="1"
              :integerOnly="true"
              :mouseDownSpeed="200"
              v-model.number="props[propEditID].depth"
              class="mx-4 my-1 pb-5"
            />

            <div class="d-flex justify-content-center w-100 m-0 text-left">
              <div>
                <div class="position-relative">
                  <input
                    id="flipXcheckbox"
                    type="checkbox"
                    autocomplete="off"
                    class="form-check-input"
                    v-model="props[propEditID].flip_x"
                  />
                  <label class="form-check-label" for="flipXcheckbox"
                    >Spiegelen X</label
                  >
                </div>

                <div class="position-relative">
                  <input
                    id="flipYcheckbox"
                    type="checkbox"
                    autocomplete="off"
                    class="form-check-input"
                    v-model="props[propEditID].flip_y"
                  />
                  <label class="form-check-label" for="flipYcheckbox"
                    >Spiegelen Y</label
                  >
                </div>

                <div class="position-relative">
                  <input
                    id="Hidecheckbox"
                    type="checkbox"
                    autocomplete="off"
                    class="form-check-input"
                    v-model="props[propEditID].hide"
                  />
                  <label class="form-check-label" for="Hidecheckbox"
                    >Verberg</label
                  >
                </div>
              </div>
            </div>

            <span class="properties-header add-event-header mt-2"
              ><b class="mt-1" style="display:inline-block;"
                >Evenement toevoegen:</b
              ></span
            >
            <PropEditor
              :index="propEditID"
              :showEventFlag="showEventFlag"
              :events="props[propEditID].events"
              :chapterEventData="events"
              :isContactsPanel="false"
              @openDialogueEditor="openDialogueEditor"
              @openConditionModal="openConditionModal"
              @push-events="addEvent"
              @splice-events="props[propEditID].events.splice($event, 1)"
              ref="propEditor"
            />
          </div>

          <!-- CONTENT 5 -->
          <div
            class="properties-content1 px-1 pb-4"
            v-else-if="this.selectedProperties == 5"
          >
            <span class="properties-header mt-2"
              ><b class="mt-1" style="display:inline-block;"
                >Contact dialoog toevoegen:</b
              ></span
            >
            <PropEditor
              :index="propEditID"
              :showEventFlag="showEventFlag"
              :events="props[propEditID].events"
              :chapterEventData="events"
              :isContactsPanel="true"
              :contactsList="contacts"
              @openDialogueEditor="openDialogueEditor"
              @openConditionModal="openConditionModal"
              @push-events="addEvent($event, true)"
              @splice-events="props[propEditID].events.splice($event, 1)"
              ref="contactsEditor"
            />
          </div>
        </div>
      </div>
    </div>

    <VerifyModal
      v-show="showVerifyDeleteSceneModal"
      @cancel-click="showVerifyDeleteSceneModal = false"
      @ok-click="
        navigateUp(true); //Set the deletion paramter to true
        showVerifyDeleteSceneModal = false;
      "
      okButtonStyle="negative"
      cancelButtonStyle="neutral"
      okButtonText="Verwijder"
      cancelButtonText="Terug"
    >
      Weet je zeker dat je deze scene wilt verwijderen?
    </VerifyModal>

    <VerifyModal
      v-show="showVerifyDeletePropModal"
      @cancel-click="showVerifyDeletePropModal = false"
      @ok-click="
        removeProp();
        showVerifyDeletePropModal = false;
      "
      okButtonStyle="negative"
      cancelButtonStyle="neutral"
      okButtonText="Verwijder"
      cancelButtonText="Terug"
    >
      Weet je zeker dat je dit wilt verwijderen?
    </VerifyModal>
    <!-- Component to show the unsaved changes menu -->
    <VerifyModal
      v-show="showVerifyUnsavedModal"
      @cancel-click="
        currentNextFunction(false);
        showVerifyUnsavedModal = false;
      "
      @ok-click="
        currentNextFunction();
        showVerifyUnsavedModal = false;
      "
      okButtonStyle="negative"
      cancelButtonStyle="positive"
      okButtonText="Verlaat pagina"
      cancelButtonText="Blijf hier"
    >
      Weet je zeker dat je deze pagina wilt verlaten zonder op te slaan?
    </VerifyModal>
    <!-- CONDITION selection-->
    <ConditionModal
      ref="conditionModal"
      @selectedCondition="conditionModalEventSelected"
      :condIndex="conditionIndex"
      :treeID="2"
      :nodes="treeData"
      :events="events"
    />
  </div>
</template>

<script>
import PropEditor from "../components/SceneEditor/PropEditor";
import PropButtons from "../components/SceneEditor/PropButtons";
import CountrySelect from "../components/SceneEditor/CountrySelect";
import Props from "../components/SceneEditor/Props";
import ToastMessage from "../components/layout/ToastMessage";
import LoadingMessage from "../components/layout/LoadingMessage";
import VerifyModal from "../components/VerifyModal";
import HeaderRow from "../components/HeaderRow";

import backgroundList from "../util/backgroundList";
import propList from "../util/propList";

import NumberInputSpinner from "vue-number-input-spinner";
import RoundSlider from "vue-round-slider";

import ConditionModal from "../components/ConditionModal";
import soundList from "../util/soundList";

export default {
  name: "SceneEditor",
  data() {
    return {
      saveVisible: false,
      modalPage: 1,
      loading: false,
      saving: false,
      selectedProperties: 1,
      showVerifyDeletePropModal: false,
      scene: null,
      events: {},
      tips: null,
      propEditID: null,
      showEventFlag: null,
      showDialogueEditor: false,
      setDialogueId: null,
      optionsEventEdit: false,
      tempProp: null,
      showAttributes: false,
      props: {},
      country: "NL",
      newIdCounter: 0,
      newDialogueId: 0,
      unsavedChanges: false,
      showVerifyUnsavedModal: false,
      showVerifyDeleteSceneModal: false,
      currentNextFunction: function() {},

      canvasSize: {
        width: 0,
        height: 0
      },

      /** constants */
      /** List of all backgrounds (see util folder) */
      backgrounds: backgroundList.backgrounds,
      /** List of all props (see util folder) */
      propButtons: propList.props,
      /** List of all sounds (see util folder) */
      sounds: soundList.sounds,

      slickSettings: {
        dots: true,
        arrows: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        touchThreshold: 4,
        vertical: true,
        verticalSwiping: true
      },

      contactsPropId: undefined,
      contacts: [],

      treeData: [{ text: "Scene evenementen", id: "A" }],
      conditionIndex: 0,
      modalEvent: {}
    };
  },
  components: {
    CountrySelect,
    PropButtons,
    Props,
    PropEditor,
    ToastMessage,
    LoadingMessage,
    VerifyModal,
    HeaderRow,
    NumberInputSpinner,
    RoundSlider,
    ConditionModal
  },
  methods: {
    /** Open the dialogue editor for dialogue event */
    async openDialogueEditor(eventId) {
      await this.saveScene(eventId);
      this.$nextTick(() => {
        //Add which case, scene and event this dialogue editor belongs to
        this.$router
          .push({
            name: "dialogueeditor",
            query: {
              case: this.$route.query["case"],
              chapter: this.$route.query["chapter"],
              scene: this.scene.sceneId,
              eventId: this.newDialogueId ? this.newDialogueId : eventId
            }
          })
          .catch(errors => {
            if (errors?.type != 4)
              //type 4 is manually aborted in guard
              console.log("navigation went wrong", errors);
          });
      });
    },
    /** set windows of properties pane */
    setWindow(b) {
      this.selectedProperties = b;
      if (b != 3 && b != 5) this.propEditID = null; // Stop editing a prop if a panel other than the prop editor or contact events panel is selected
      if (b == 5) this.propEditID = this.contactsPropId; // Start editing the contacts prop if the contact events panel is selected

      if (b == 4) {
        this.showAttributes = true;
      } else {
        this.showAttributes = false;
      }
    },
    /** get active style class for properties pane tabs. Pane == 1 is the backgrounds panel, 2 is the props panel,
     * 3 is the prop editor, 4 is the flags panel, and 5 is the contact events panel */
    getActiveStyle(pane) {
      // For the prop editor, if no prop is selected the button is unclickable
      if (pane == 3 && (this.propEditID == null || this.selectedProperties == 5)) return "notClickable";
      if (this.selectedProperties != pane) return "notActive";
      else return "";
    },
    /** Navigate up to chapter editor */
    navigateUp(del) {
      this.$router
        .push({
          name: "chaptereditor",
          query: {
            case: this.$route.query["case"],
            chapter: this.$route.query["chapter"],
            deletedScene: del ? this.$route.query["scene"] : undefined, //Delete the scene as soon as you get to the chapter editor
          }
        })
        .catch(errors => {
          if (errors?.type != 4)
            //type 4 is manually aborted in guard
            console.log("navigation went wrong", errors);
        });
    },
    /** Select prop to start editing in prop editor window */
    editProp(i) {
      if (this.propEditID == i) {
        this.propEditID = null;
        this.selectedProperties = 1;
        return;
      }

      this.selectedProperties = 3;
      this.propEditID = i;
    },
    /** save all scene info */
    saveScene(eventId) {
      this.saving = true;
      const sceneData = {
        //Set all the data needed for saving the scene
        sceneId: this.scene.sceneId,
        name: this.scene.name,
        description: this.scene.description,
        background: this.scene.background,
        props: this.props,
        country: this.country
      };

      return this.$axios
        .post(process.env.VUE_APP_API_HOST + "/scene/update", {
          sceneData,
          eventId
        })
        .then(response => {
          this.newDialogueId = response.data.id;
          if (!eventId) {
            this.saveVisible = true;
            this.$router.go();
          } else this.unsavedChanges = false;
        })
        .catch(errors => {
          console.error(errors);
        })
        .finally(() => {
          this.saving = false;
        });
    },

    /** Start editing the scene title */
    editSceneTitle(name) {
      if (name) {
        name = name.trim();

        this.scene.name = name;
        this.updateName();
      }
    },
    /** Save / update the scene title */
    updateName() {
      this.$axios
        .post(process.env.VUE_APP_API_HOST + "/scene/update/name", {
          sceneId: this.scene.sceneId,
          name: this.scene.name
        })
        .catch(errors => {
          console.error(errors);
        });
    },

    /** Get scene info from databases */
    getScene() {
      this.loading = true;
      return this.$axios
        .get(process.env.VUE_APP_API_HOST + "/scene/info/" + this.$route.query["scene"])
        .then(response => {
          //The response sends [scene data, prop data, event data]
          this.scene = response.data[0];

          // Load all the props
          const propData = response.data[1] ? response.data[1] : [];
          let contactsPropExists = false;
          propData.forEach(prop => {
            if (prop.name == "contactsProp") {
              contactsPropExists = true;
              this.contactsPropId = prop.prop_id;
            }
            if (prop.depth > NumberInputSpinner.props.max.default) {
              //If the depth is more than max, / 10
              prop.depth = Math.round(prop.depth / 10);
            }
            prop.events = []; //Initialize events array
            prop.position = { x: prop.position_x, y: prop.position_y };
            delete prop.position_x;
            delete prop.position_y;
            this.props[prop.prop_id] = prop;
            this.props = Object.assign({}, this.props); //Make it a reactive assign
          });

          // Create a new empty contacts prop, if such a prop doesn't already exist.
          if (!contactsPropExists) {
            this.addContactsProp();
          }

          const propEvents = response.data[2];
          propEvents.forEach(event => {
            let eventData = {
              //Set all the event data
              event_id: event.event_id,
              fk_prop_id: event.fk_prop_id,
              conditionString: event.conditional,
              name: event.name,
              value: event.value,
              runType: event.runType,
              type: event.type,
              seconds: event.seconds
            };
            //Get the sound based on sound id
            if (eventData.type == "event:sound") {
              for (let index = 0; index < this.sounds.length; index++) {
                if (this.sounds[index].id == eventData.value)
                  eventData.value = this.sounds[index];
              }
            }
            eventData.condition = [];
            if (eventData.conditionString != null) {
              //If there are conditions from the database
              const conditions = eventData.conditionString.split("/");
              while (
                ["", "A", "O", "-", "+"].includes(
                  conditions[conditions.length - 1]
                ) //remove unwanted characters from the end of the array
              )
                conditions.splice(-1, 1);
              if (conditions && conditions.length > 1) {
                conditions.forEach(condition => {
                  eventData.condition.push(condition);
                });
              } else {
                eventData.condition = ["+", "0"];
              }
            } else {
              eventData.condition = ["+", "0"];
            }
            delete eventData.conditionString;
            this.props[event.fk_prop_id].events.push(eventData);
          });

          this.country = this.scene.country ? this.scene.country : "NL"; //Default to Netherlands if none listed
        })
        .catch(async errors => {
          if (errors?.response?.status == 404) {
            this.$router.push({ name: "404" });
          }
          console.log("something went wrong");
        })
        .finally(() => {
          this.unsavedChanges = false;
          this.loading = false;
        });
    },
    getEvents() {
      return this.$axios
        .get(process.env.VUE_APP_API_HOST + "/chapter/get/chapter_events/" + this.$route.query["chapter"])
        .then(response => {
          const eventData = response.data;
          eventData.forEach(event => {
            this.events[event.event_id] = {
              name: event.name,
              eventType: event.type,
              dialogue: {}
            };
          });
          this.getDialogueEvents();
        });
    },
    getDialogueEvents() {
      return this.$axios
        .get(process.env.VUE_APP_API_HOST + "/dialogue/get/trees/" + this.$route.query["chapter"])
        .then(response => {
          const dialogueData = response.data;
          dialogueData.forEach(event => {
            this.events[event.event_id].dialogue[event.dialogue_id] = {
              name: event.name,
              value: event.value,
              type: event.type,
              portrait: event.portrait
            };
          });
        });
    },
    //Load the contacts from the database
    loadContacts() {
      return this.$axios
        .get(process.env.VUE_APP_API_HOST + "/settings/get/attributes/contact")
        .then(response => {
          this.contacts = response.data;
        })
        .catch(errors => {
          console.log(
            "Receiving contacts data from the database failed as the process ran into an error",
            errors
          );
        });
    },
    /** Create a prop in the scene when clicking on a prop from the prop list */
    addProp(i) {
      //Initialize prop with default values based on prop selected.
      let newProp = {};
      newProp.prop_id = "new".concat(this.newIdCounter++); //Add new keyword to check when saving
      newProp.image = this.propButtons[i].image;
      newProp.name = this.propButtons[i].name;
      newProp.image_width = this.propButtons[i].imageSize.width;
      newProp.image_height = this.propButtons[i].imageSize.height;
      //Set in the middle of the scene
      newProp.position = { x: 0.5, y: 0.5 };
      newProp.scale = 1;
      newProp.rotation = 0;
      newProp.depth = 5;
      newProp.flip_x = false;
      newProp.flip_y = false;
      newProp.hidden = false;
      newProp.events = [];

      this.props[newProp.prop_id] = newProp;
      this.props = Object.assign({}, this.props); //Reactive assign
    },
    /** Remove a prop */
    removeProp() {
      delete this.props[this.propEditID];
      this.props = Object.assign({}, this.props); //Reactive assign
      this.propEditID = null;
      this.selectedProperties = 1;
    },
    /** Change background of scene */
    changeBackground(name) {
      this.scene.background = name;
    },
    /** Add an event to the edited prop, with a unique id with the new keyword */
    addEvent(event) {
      event.event_id = "new".concat(this.newIdCounter++);
      this.props[this.propEditID].events.push(event);
    },
    addContactsProp() {
      let newProp = {};
      newProp.prop_id = "new".concat(this.newIdCounter++); //Add new keyword to check when saving
      this.contactsPropId = newProp.prop_id;
      newProp.image = null;
      newProp.name = "contactsProp";
      newProp.image_width = null;
      newProp.image_height = null;
      //Set in the middle of the scene
      newProp.position = { x: null, y: null };
      newProp.scale = null;
      newProp.rotation = null;
      newProp.depth = null;
      newProp.flip_x = null;
      newProp.flip_y = null;
      newProp.hidden = true;
      newProp.events = [];

      this.props[newProp.prop_id] = newProp;
      this.props = Object.assign({}, this.props); //Reactive assign
    },

    onWindowResize() {
      this.canvasSize = this.$refs.canvas.getBoundingClientRect();
    },

    //When the condition button is clicked, open the condition selection menu
    openConditionModal(index, event) {
      this.conditionIndex = index;
      this.modalEvent = event;
      this.$refs["conditionModal"].toggleConditionPanel(event.event_id, false);
    },

    //When a condition is selected in the condition selection menu
    conditionModalEventSelected(conditionId, conditionIndex) {
      this.$set(this.modalEvent.condition, conditionIndex, conditionId);
    },

    //The value of the rotation slider
    roundSliderTooltip() {
      return this.props[this.propEditID].rotation + "°";
    }
  },
  created() {
    this.getScene()
      .then(() => {
        this.propButtons.forEach(propButton => {
          let image = new Image();
          image.src = propButton.image;
          image.onload = function() {
            propButton.imageSize = {
              width: image.naturalWidth / 3,
              height: image.naturalHeight / 3
            };
          };
        });
      })
      .catch(err => {
        console.error("Unable to get scene");
        console.error(err);
      });
    this.getEvents();
    this.loadContacts();
    window.addEventListener("resize", this.onWindowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  watch: {
    scene: {
      handler() {
        this.unsavedChanges = true;
      },
      deep: true
    },
    props: {
      handler() {
        this.unsavedChanges = true;
      },
      deep: true
    },
    country: {
      handler() {
        this.unsavedChanges = true;
      },
      deep: true
    },
    tips: {
      handler() {
        this.unsavedChanges = true;
      },
      deep: true
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.unsavedChanges) {
      next();
    } else {
      this.currentNextFunction = next;
      this.showVerifyUnsavedModal = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.scene-editor {
  position: relative;
  overflow-y: auto;
  z-index: 0;
  width: 100%;
  min-width: max-content;
}
.node-button {
  border: 0px;
  color: $white;
  background-color: $red;
  border-radius: 1em;
  max-width: 12em;
  &:focus{
    outline: none;
  }
  &:hover{
    background-color: $darkred;
  }
}
.canvas-properties-container {
  height: 100%;
  position: relative;
  overflow-y: hidden;
}
.canvas-container {
  min-width: 10em;
}
.add-event-header {
  border-top: $basic-border;
}
.canvas {
  min-width: 22em;
  min-height: 12.375em;
  max-height: calc(56.25vw - 16.4em);
  max-width: calc(177.77vh - 19.85em);

  box-shadow: $box-shadow-white;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 1;
}
.attribute-overview-container {
  width: 100%;
  min-width: 20em;
  box-shadow: $box-shadow-white;
  border-radius: 2em;
  overflow: auto;
  height: fit-content;
  max-height: 100%;
}
.button-options {
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
  background-color: $white;
  color: $black;
}
.button-flags {
  border-top-right-radius: 2em;
  border-bottom-right-radius: 2em;
  border-left: $basic-border-black;
  background-color: $white;
  color: $black;
}
.properties-container {
  width: min-content;
  min-width: 19em;
  box-shadow: $box-shadow-white;
  border-radius: 2em;
  text-align: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  
  position: relative;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.properties-content3 {
  overflow-y: auto;
}
.properties-header {
  position: relative;
  display: inline-block;

  font-family: $default-font;
}

.properties-span {
  display: inline-block;
  border-bottom: 0.1em solid lightgray;
  width: 100%;
  &:hover {
    background-color: $semiwhite;
  }
  &-border {
    border-right: $basic-border;
  }
}
.notActive {
  color: $darkgray;
  &:hover {
    color: $darkergray;
    cursor: pointer;
  }
}
.notClickable {
  color: $semiwhite;
}
.no-hover {
  cursor: default !important;
  &:hover {
    background-color: $white;
  }
}
.background-carousel {
  button {
    border: 0px;
    background-color: $white;
    &:focus {
      outline: unset;
    }
  }
  img {
    width: 15em;
  }
}

.divider {
  margin-bottom: 1em;
  border-bottom: $basic-border;
  width: 100%;
  height: 1em;
}

.properties-content1 {
  height: 100%;
  width: 19em;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}
.scroll {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.toast {
  pointer-events: none;
}
.rs-handle {
  box-shadow: 0 0 0px 0 #000;
  background-color: #007bff;
}
</style>
