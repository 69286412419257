<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div :id="id" class="toast" :class="{ error: error }">
    <div class="toast-body">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ToastMessage",
  props: ["message", "id", "error", "close", "closeTimeout"],

  mounted() {
    setTimeout(this.close, this.closeTimeout);
  }
};
</script>

<style lang="scss" scoped>
.toast {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  display: block;
  opacity: 1;
  z-index: 2;
}

.error {
  color: red;
}
</style>
