<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div
    class="change-password-container d-flex justify-content-center align-items-center w-100 h-100"
    v-if="show"
  >
    <div
      class="modal d-flex flex-column align-items-center justify-content-center"
    >
      <header class="modal-header mt-2">
        <h4>Wachtwoord veranderen</h4>
      </header>
      <section class="modal-body d-flex align-items-center" v-if="success">
        <div class="w-100">
          <p class="text-center">
            Wachtwoord succesvol veranderd!
          </p>
          <div class="d-flex justify-content-center mt-3">
            <button
              class="button-close btn btn-primary"
              @click="
                success = false;
                $emit('close');
              "
            >
              Sluiten
            </button>
          </div>
        </div>
      </section>
      <section class="modal-body" v-else>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            autocomplete="current-password"
            placeholder="Huidig wachtwoord"
            v-model="currentPassword"
          />
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            autocomplete="new-password"
            placeholder="Nieuw wachtwoord"
            v-model="newPassword"
          />
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            autocomplete="new-password"
            placeholder="Herhaal nieuw wachtwoord"
            v-model="newPasswordRepeat"
          />
        </div>
        <p class="error-message text-danger text-center mt-3 mb-0">
          {{ error }}
        </p>
        <div class="d-flex justify-content-center mt-3">
          <button
            class="button-change-password btn btn-primary mr-3"
            @click="changePassword"
          >
            Verander wachtwoord
          </button>
          <button
            class="button-close btn btn-secondary"
            @click="
              clearAll();
              $emit('close');
            "
          >
            Annuleren
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangePassword",
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
      error: "",
      success: false
    };
  },
  props: ["show"],
  methods: {
    changePassword() {
      if (
        !this.currentPassword ||
        !this.newPassword ||
        !this.newPasswordRepeat
      ) {
        this.error = "Vul alle velden in a.u.b.";
        return;
      }
      const data = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
        newPasswordRepeat: this.newPasswordRepeat
      };
      this.$axios
        .post(process.env.VUE_APP_API_HOST + "/auth/changepassword", data)
        .then(() => {
          this.success = true;
          this.clearAll();
        })
        .catch(errors => {
          if (errors?.response?.status == 400) {
            this.error = "Nieuwe wachtwoorden zijn niet gelijk";
          } else if (errors?.response?.status == 401) {
            this.error = "Huidig wachtwoord is verkeerd";
          } else {
            this.error = "Er ging iets mis";
          }
        });
    },
    clearAll() {
      this.currentPassword = "";
      this.newPassword = "";
      this.newPasswordRepeat = "";
      this.error = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.change-password-container {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 999;
}

.modal {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 1.2em -0.3em;
  border-radius: 2em;

  width: 25em;
  height: 22em;
}

.modal-header {
  position: relative;
  display: flex;
  justify-content: center;
}

.modal-body {
  position: relative;
}

.button-change-password {
  background-color: $black;
  border: none;
}

.button-close {
  background-color: $red;
  border: none;
}
</style>
