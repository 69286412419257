<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div class="accordion">
    <!-- The clickable accordion header -->
    <div class="prop-events-header-container" :id="'heading' + this.index">
      <span
        class="prop-events-header mx-2"
        @click="this.clickCard"
        :aria-expanded="this.cardOpen"
        :aria-controls="'collapse' + this.index"
      >
        <span class="width-29">
          <i>{{ eventDictionary[this.event.type] }}:</i>
        </span>

        <!-- The name of the event, which is only editable if it is not a contact event -->
        <span
          v-if="isContactsPanel"
          class="event-name-contacts"
          :id="'event-name-' + event.event_id"
        >
          {{ this.event.name }}
        </span>

        <input
          v-else
          v-model="event.name"
          placeholder="Name"
          autocomplete="off"
          @click.stop
          class="event-name"
          :maxlength="45"
          :id="'event-name-' + event.event_id"
        />

        <div class="icon-container">
          <font-awesome-icon
            v-if="this.cardOpen"
            class="accordion-dropdown"
            icon="angle-up"
          />

          <font-awesome-icon
            v-else
            class="accordion-dropdown"
            icon="angle-down"
          />

          <font-awesome-icon
            @click="$emit('removeEvent', index)"
            class="accordion-delete"
            icon="times"
            @click.stop
          />
        </div>
      </span>
    </div>
    <transition name="slide" :css="animated">
      <!-- The body of this accordion, which is only visible if it has been opened -->
      <div
        :id="'collapse' + this.index"
        :aria-labelledby="'heading' + this.index"
        :class="'collapsible'"
        v-show="cardOpen"
        ref="collapsible"
        :style="`--height: ${height}px;`"
      >
        <div class="card-body px-2 py-3">
          <PropEvent
            :event="event"
            :chapterEventData="chapterEventData"
            :isContactsPanel="isContactsPanel"
            @openDialogueEditor="$emit('openDialogueEditor')"
            @openConditionModal="$emit('openConditionModal', $event)"
            @addEvent="$emit('addEvent', a, b, c)"
            @removeEvent="$emit('removeEventById', a)"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import PropEvent from "./PropEvent";

import EventDict from "../../util/EventEnumToName";

export default {
  name: "AccordionCard",
  props: ["index", "event", "chapterEventData", "isContactsPanel"],
  components: { PropEvent },
  data() {
    return {
      cardOpen: false,
      animated: false,
      eventDictionary: EventDict,
      height: 0
    };
  },
  methods: {
    /** Toggle between open or close card when clicked */
    clickCard() {
      this.$emit("closeOpenCard", this.event.event_id);
      this.cardOpen = !this.cardOpen;
    }
  },
  mounted() {
    document.getElementById("event-name-" + this.event.event_id);
    this.cardOpen = true;
    this.$nextTick(() => {
      this.height = this.$refs.collapsible.getBoundingClientRect().height;
      this.cardOpen = false;
      this.$nextTick(() => {
        this.animated = true;
      });
    });
  }
};
</script>

<style lang="scss" scoped>
.prop-events-header-container {
  text-align: left;
  border-bottom: $basic-border;
}
.prop-events-header {
  display: flex;
  margin-top: 2%;
  margin-bottom: 2%;
}

[class^="event-name"] {
  height: 1.5em;
  width: 55%;
  margin-left: 5px;
  overflow: auto;
  border: 1px solid $semiwhite;
}
.event-name {
  &-contacts {
    border: none;
  }

  border: $basic-border;
  &:focus {
    outline: 2px auto $darkgray;
  }
}

.icon-container {
  display: flex;
  margin-left: auto;
}
.accordion-dropdown {
  width: 1.2em;
  height: 1.2em;
  &:hover {
    cursor: pointer;
    color: $green;
  }
}
.accordion-delete {
  width: 1.2em;
  height: 1.2em;
  &:hover {
    cursor: pointer;
    color: $red;
  }
}

.width{
  &-29{
    width: 29%;
  }
}

.slide-enter-active {
  animation: slide-in 0.5s;
}
.slide-leave-active {
  animation: slide-in 0.5s reverse;
}
@keyframes slide-in {
  0% {
    transition: all 0.5s ease-in-out;
    height: 0;
    opacity: 0;
  }

  100% {
    opacity: 1;
    height: var(--height);
  }
}

::placeholder {
  color: $lightgray;
}
</style>
