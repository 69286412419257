<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div id="app">
    <link
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"
      rel="stylesheet"
    />
    <div class="d-flex flex-column w-100 h-100" v-if="!isMobile">
      <Header />
      <div class="page-body h-100 w-100"><router-view /></div>
    </div>
    <div v-if="isMobile">
      <h1 class="m-5">
        Unfortunately this application is not available for mobile devices.
      </h1>
    </div>
  </div>
</template>

<script>
import MobileDetect from "mobile-detect";
import Header from "./components/layout/Header";

export default {
  components: {
    Header
  },
  data() {
    return {
      isMobile: false
    };
  },
  created() {
    var md = new MobileDetect(window.navigator.userAgent);
    if (md.mobile()) {
      this.isMobile = true;
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
}
.page-body {
  overflow: auto;
}

::-webkit-scrollbar-track {
  background: gainsboro;
  border-radius: 0.3rem;
}
::-webkit-scrollbar {
  width: 0.7rem;
  height: 0.7rem;
}
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 0.3rem;
}
</style>
