var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chapter-editor d-flex flex-column h-100"},[(_vm.loading)?_c('div',[_c('LoadingMessage')],1):_c('div',{staticClass:"chapter-editor d-flex flex-column h-100"},[(_vm.saveVisible)?_c('ToastMessage',{ref:"saveToast",attrs:{"message":'Opgeslagen!',"id":'saveToast',"close":function () {
          _vm.saveVisible = false;
        },"closeTimeout":2000}}):_vm._e(),(_vm.errorVisible)?_c('ToastMessage',{ref:"errorToast",attrs:{"message":_vm.errorMessage,"id":'errorToast',"error":true,"close":function () {
          _vm.errorVisible = false;
        },"closeTimeout":2000}}):_vm._e(),_c('HeaderRow',{attrs:{"editable":true,"id":this.scenario.id,"name":this.scenario.name,"prefix":'Hoofdstuk',"saving":_vm.saving,"chapterEditor":true,"showTitle":true},on:{"updateName":_vm.updateName,"navigate":_vm.goBack,"remove":function($event){_vm.showVerifyDeleteChapterModal = true},"save":_vm.saveChapter}}),_c('div',{staticClass:"canvas-container px-5 py-4 d-flex h-100 w-100"},[_c('div',{ref:"canvasScroll",staticClass:"canvas-scroll d-flex mr-3",attrs:{"id":"canvas-scroll"}},[_c('div',{ref:"canvas",staticClass:"canvas",attrs:{"id":"canvas"}},[_c('SceneNode',{ref:'startNode',attrs:{"position":_vm.startNodePosition,"canvas":_vm.$refs.canvas,"canvasScroll":_vm.$refs.canvasScroll,"nodeType":'START',"name":'Start',"overlaps":_vm.overlapsStart},on:{"selectStartNode":_vm.selectStartNode,"setPosition":function($event){_vm.startNode.position.x = $event.x;
              _vm.startNode.position.y = $event.y;}}}),_vm._l((_vm.scenario.scenes),function(sc,i){return _c('SceneNode',{key:sc.sceneId,ref:'chapter_' + sc.sceneId,refInFor:true,attrs:{"index":i,"canvas":_vm.$refs.canvas,"canvasScroll":_vm.$refs.canvasScroll,"nodeType":sc.isEndNode ? 'END' : 'SCENE',"position":{ x: sc.x, y: sc.y },"name":sc.name,"sceneChanges":sc.sceneChanges ? sc.sceneChanges : [],"sceneid":sc.sceneId,"overlaps":function (rect) { return _vm.overlapsNode(rect, sc.sceneId); }},on:{"openInfoPane":function($event){_vm.selectedScene = sc;
              _vm.selectedProperties = 2;},"selectOutputNode":function($event){return _vm.selectOutputNode(sc.sceneId, $event)},"selectInputNode":function($event){return _vm.selectInputNode(sc.sceneId)},"openSoundPane":function($event){_vm.selectedScene = sc;
              _vm.selectedProperties = 3;},"updateDotPosition":function($event){_vm.scenario.scenes[i].sceneChanges[$event.dotIndex].position = $event.newPos},"editNode":function($event){return _vm.openSceneEditor(sc.sceneId)},"setPosition":function($event){sc.x = $event.x;
              sc.y = $event.y;}}})}),_c('svg',{attrs:{"id":"mySVG"}},_vm._l((_vm.lines),function(line,i){return _c('NodeLine',{key:i,attrs:{"node":line,"lineIndex":i,"canvas":_vm.$refs.canvas,"inputNode":line.inputNode
                  ? _vm.$refs['chapter_' + line.inputNode.sceneId]
                  : undefined},on:{"removeLine":_vm.removeLine,"resetStartNode":_vm.resetStartNode}})}),1)],2)]),_c('PropertiesPane',{ref:"propertiesPane",attrs:{"selectedScene":_vm.selectedScene,"published":_vm.scenario.published,"random":_vm.scenario.randomOption,"timer":_vm.scenario.timer,"description":_vm.scenario.description,"selectedProperties":_vm.selectedProperties},on:{"removeScene":function($event){_vm.showVerifyDeleteSceneModal = true},"changeProperties":function($event){_vm.selectedProperties = $event},"addScene":_vm.addScene,"unselect-node":function($event){_vm.selectedScene = null},"chapterPublished":function($event){_vm.scenario.published = $event},"chapterRandom":function($event){_vm.scenario.randomOption = $event},"chapterTimer":function($event){_vm.scenario.timer = $event},"description-change":function($event){_vm.scenario.description = $event.target.value},"changeName":function($event){_vm.selectedScene.name = $event},"setSceneMusic":function($event){_vm.selectedScene.music = $event;
          _vm.$forceUpdate();}}})],1)],1),_c('VerifyModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVerifyDeleteChapterModal),expression:"showVerifyDeleteChapterModal"}],attrs:{"okButtonStyle":"negative","cancelButtonStyle":"neutral","okButtonText":"Verwijder","cancelButtonText":"Terug"},on:{"cancel-click":function($event){_vm.showVerifyDeleteChapterModal = false},"ok-click":_vm.removeChapter}},[_vm._v(" Weet je zeker dat je dit wilt verwijderen? ")]),_c('VerifyModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVerifyUnsavedModal),expression:"showVerifyUnsavedModal"}],attrs:{"okButtonStyle":"negative","cancelButtonStyle":"positive","okButtonText":"Verlaat pagina","cancelButtonText":"Blijf hier"},on:{"cancel-click":function($event){_vm.currentNextFunction(false);
      _vm.showVerifyUnsavedModal = false;},"ok-click":function($event){_vm.currentNextFunction();
      _vm.showVerifyUnsavedModal = false;}}},[_vm._v(" Weet je zeker dat je deze pagina wilt verlaten zonder op te slaan? ")]),_c('VerifyModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVerifyDeleteSceneModal),expression:"showVerifyDeleteSceneModal"}],attrs:{"okButtonStyle":"negative","cancelButtonStyle":"neutral","okButtonText":"Verwijder","cancelButtonText":"Terug"},on:{"cancel-click":function($event){_vm.showVerifyDeleteSceneModal = false},"ok-click":function($event){_vm.removeScene(_vm.selectedScene.sceneId);
      _vm.showVerifyDeleteSceneModal = false;}}},[_vm._v(" Weet je zeker dat je dit wilt verwijderen? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }