<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div class="chapter-editor d-flex flex-column h-100">
    <div v-if="loading">
      <LoadingMessage />
    </div>
    <div class="chapter-editor d-flex flex-column h-100" v-else>
      <ToastMessage
        :message="'Opgeslagen!'"
        :id="'saveToast'"
        ref="saveToast"
        v-if="saveVisible"
        :close="
          () => {
            saveVisible = false;
          }
        "
        :closeTimeout="2000"
      />
      <ToastMessage
        :message="errorMessage"
        :id="'errorToast'"
        ref="errorToast"
        :error="true"
        v-if="errorVisible"
        :close="
          () => {
            errorVisible = false;
          }
        "
        :closeTimeout="2000"
      />

      <HeaderRow
        :editable="true"
        :id="this.scenario.id"
        :name="this.scenario.name"
        :prefix="'Hoofdstuk'"
        :saving="saving"
        :chapterEditor="true"
        :showTitle="true"
        @updateName="updateName"
        @navigate="goBack"
        @remove="showVerifyDeleteChapterModal = true"
        @save="saveChapter"
      />

      <div class="canvas-container px-5 py-4 d-flex h-100 w-100">
        <div
          class="canvas-scroll d-flex mr-3"
          id="canvas-scroll"
          ref="canvasScroll"
        >
          <div class="canvas" id="canvas" ref="canvas">
            <SceneNode
              :position="startNodePosition"
              @selectStartNode="selectStartNode"
              :ref="'startNode'"
              :canvas="$refs.canvas"
              :canvasScroll="$refs.canvasScroll"
              :nodeType="'START'"
              :name="'Start'"
              @setPosition="
                startNode.position.x = $event.x;
                startNode.position.y = $event.y;
              "
              :overlaps="overlapsStart"
            />
            <SceneNode
              v-for="(sc, i) in scenario.scenes"
              @openInfoPane="
                selectedScene = sc;
                selectedProperties = 2;
              "
              @selectOutputNode="selectOutputNode(sc.sceneId, $event)"
              @selectInputNode="selectInputNode(sc.sceneId)"
              @openSoundPane="
                selectedScene = sc;
                selectedProperties = 3;
              "
              :key="sc.sceneId"
              :index="i"
              :ref="'chapter_' + sc.sceneId"
              :canvas="$refs.canvas"
              :canvasScroll="$refs.canvasScroll"
              @updateDotPosition="scenario.scenes[i].sceneChanges[$event.dotIndex].position = $event.newPos"
              @editNode="openSceneEditor(sc.sceneId)"
              @setPosition="
                sc.x = $event.x;
                sc.y = $event.y;
              "
              :nodeType="sc.isEndNode ? 'END' : 'SCENE'"
              :position="{ x: sc.x, y: sc.y }"
              :name="sc.name"
              :sceneChanges="sc.sceneChanges ? sc.sceneChanges : []"
              :sceneid="sc.sceneId"
              :overlaps="rect => overlapsNode(rect, sc.sceneId)"
            />

            <svg id="mySVG">
              <NodeLine
                v-for="(line, i) in lines"
                :key="i"
                :node="line"
                :lineIndex="i"
                @removeLine="removeLine"
                @resetStartNode="resetStartNode"
                :canvas="$refs.canvas"
                :inputNode="
                  line.inputNode
                    ? $refs['chapter_' + line.inputNode.sceneId]
                    : undefined
                "
              />
            </svg>
          </div>
        </div>

        <PropertiesPane
          :selectedScene="selectedScene"
          :published="scenario.published"
          :random="scenario.randomOption"
          :timer="scenario.timer"
          :description="scenario.description"
          :selectedProperties="selectedProperties"
          @removeScene="showVerifyDeleteSceneModal = true"
          @changeProperties="selectedProperties = $event"
          @addScene="addScene"
          @unselect-node="selectedScene = null"
          @chapterPublished="scenario.published = $event"
          @chapterRandom="scenario.randomOption = $event"
          @chapterTimer="scenario.timer = $event"
          @description-change="scenario.description = $event.target.value"
          @changeName="selectedScene.name = $event"
          @setSceneMusic="
            selectedScene.music = $event;
            $forceUpdate();
          "
          ref="propertiesPane"
        />
      </div>
    </div>

    <VerifyModal
      v-show="showVerifyDeleteChapterModal"
      @cancel-click="showVerifyDeleteChapterModal = false"
      @ok-click="removeChapter"
      okButtonStyle="negative"
      cancelButtonStyle="neutral"
      okButtonText="Verwijder"
      cancelButtonText="Terug"
    >
      Weet je zeker dat je dit wilt verwijderen?
    </VerifyModal>

    <!-- Component to show the unsaved changes menu -->
    <VerifyModal
      v-show="showVerifyUnsavedModal"
      @cancel-click="
        currentNextFunction(false);
        showVerifyUnsavedModal = false;
      "
      @ok-click="
        currentNextFunction();
        showVerifyUnsavedModal = false;
      "
      okButtonStyle="negative"
      cancelButtonStyle="positive"
      okButtonText="Verlaat pagina"
      cancelButtonText="Blijf hier"
    >
      Weet je zeker dat je deze pagina wilt verlaten zonder op te slaan?
    </VerifyModal>

    <VerifyModal
      v-show="showVerifyDeleteSceneModal"
      @cancel-click="showVerifyDeleteSceneModal = false"
      @ok-click="
        removeScene(selectedScene.sceneId);
        showVerifyDeleteSceneModal = false;
      "
      okButtonStyle="negative"
      cancelButtonStyle="neutral"
      okButtonText="Verwijder"
      cancelButtonText="Terug"
    >
      Weet je zeker dat je dit wilt verwijderen?
    </VerifyModal>
  </div>
</template>

<script>
import SceneNode from "../components/ChapterEditor/SceneNode";
import HeaderRow from "../components/HeaderRow";
import PropertiesPane from "../components/ChapterEditor/PropertiesPane";
import ToastMessage from "../components/layout/ToastMessage";
import LoadingMessage from "../components/layout/LoadingMessage";
import NodeLine from "../components/ChapterEditor/NodeLine";
import VerifyModal from "../components/VerifyModal";

import soundList from "../util/soundList"; //TODO: Change to background music

export default {
  name: "ChapterEditor",
  components: {
    SceneNode,
    VerifyModal,
    NodeLine,
    HeaderRow,
    PropertiesPane,
    ToastMessage,
    LoadingMessage
  },
  data() {
    return {
      saveVisible: false,
      errorVisible: false,
      errorMessage: null,
      loadNodes: false,
      lines: [],
      inputNode: null,
      loading: true,
      saving: false,
      editingName: false,
      selectedScene: null,
      selectedProperties: 1,
      showVerifyDeleteChapterModal: false,
      showVerifyDeleteSceneModal: false,
      outputNode: { sceneid: null, sceneChangeIndex: null },
      tempStartNodeLine: null,
      newSceneCounter: 0,
      newIdCounter: 0,
      newSceneId: 0,
      unsavedChanges: false,
      showVerifyUnsavedModal: false,
      currentNextFunction: function() {},
      startNode: {
        position: {
          x: 0,
          y: 0
        },
        startNode: true
      },
      sounds: soundList.music,
      scenario: {
        name: "",
        description: "",
        created: "",
        last_modified: "",
        total_score: 0,
        completed: false,
        pending: false,
        scenes: []
      },
      emptyScene: {
        x: 0,
        y: 0,
        description: "",
        sceneChanges: [],
        name: "Nieuwe Scene",
        data: {
          sceneId: 0,
          name: "Nieuwe Scene",
          description: "",
          background: "Airport",
          props: []
        }
      }
    };
  },

  methods: {
    /** remove chapter based on id */
    removeChapter() {
      this.$axios
        .delete(process.env.VUE_APP_API_HOST + "/chapter/remove/" + this.scenario.chapterId)
        .then(res => {
          this.unsavedChanges = false;
          this.$router.push({
            name: "corkboard",
            query: {
              case: res.data[0].caseId
            }
          });
        })
        .catch(errors => {
          console.error(errors);
        });
    },
    /** Go back one page*/
    goBack() {
      this.$router
        .push({ name: "corkboard", query: { case: this.$route.query["case"] } })
        .catch(errors => {
          if (errors?.type != 4)
            //type 4 is manually aborted in guard
            console.log("navigation went wrong", errors);
        });
    },
    /** get chapter info */
    getChapter() {
      const id = this.$route.query["chapter"];
      if (id == 0) {
        this.loading = false;
        this.unsavedChanges = false;
        return;
      }

      this.$axios
        .get(process.env.VUE_APP_API_HOST + "/chapter/info/" + id)
        .then(response => {
          const chapterData = response.data[0];

          this.scenario = chapterData;
          this.scenario = Object.assign({}, this.scenario, {
            scenes: response.data[1]
          });
          const events = response.data[2];
          let startScene = null;
          //Rename to the scene id to the naming convention of the frontend and delete the old variable
          for (let index = 0; index < this.scenario.scenes.length; index++) {
            const scene = this.scenario.scenes[index];
            if (scene.sceneId == chapterData.startSceneId) {
              //Set the start scene
              startScene = scene;
            }
            //Loop through all sounds to return the music of the scene based on the id of the music
            for (let index = 0; index < this.sounds.length; index++) {
              if (this.sounds[index].id == scene.bgMusic)
                scene.music = this.sounds[index];
            }
            this.scenario.scenes[index].sceneChanges = [];
            for (let i = 0; i < events.length; i++) {
              const event = events[i];
              //If the scene change belongs to an event
              if (event.fk_scene_id == scene.sceneId) {
                let inputNode = null;
                if (event.changeSceneId == 0) {
                  //If it goes to an end node
                  inputNode = { endNode: true };
                } else {
                  inputNode = this.getSceneFromId(event.changeSceneId)
                    ? this.getSceneFromId(event.changeSceneId)
                    : -1;
                }
                const sceneChange = {
                  changeSceneId: event.changeSceneId,
                  image: event.image,
                  position: { x: 0, y: 0 },
                  eventIndex: event.event,
                  propIndex: event.prop_id,
                  inputNode
                };
                this.scenario.scenes[index].sceneChanges.push(sceneChange);
                if (inputNode != -1) this.addLine(sceneChange);
              }
            }
          }

          this.startNode = {
            ...this.startNode,
            inputNode: startScene,
            position: {
              x: chapterData.startSceneX,
              y: chapterData.startSceneY
            }
          };
          this.addLine(this.startNode);
        })
        .catch(async errors => {
          if (errors?.response?.status == 404) {
            this.$router.push({ name: "404" });
          }
          console.log("something went wrong");
        })
        .finally(() => {
          this.loading = false;
          this.unsavedChanges = false;
        });
    },
    /** Save chapter */
    async saveChapter(sceneId) {
      this.saving = true;
      let chapterData = this.scenario;
      chapterData.startNode = this.startNode;
      let sceneChanges = [];
      chapterData.scenes.forEach(
        scene => scene.sceneChanges.forEach(sc => sceneChanges.push(sc)) //Note down all sceneChanges data
      );
      chapterData.sceneChanges = sceneChanges;

      return this.$axios
        .post(process.env.VUE_APP_API_HOST + "/chapter/update", {
          chapter: chapterData,
          sceneId: sceneId
        })
        .then(response => {
          this.newSceneId = response.data.id[sceneId];
          this.saveVisible = true;
          if (!sceneId) this.$router.go();
          else this.unsavedChanges = false;
        })
        .catch(errors => {
          console.error(errors);
        });
    },

    /** Add a new scene node to the chapter */
    addScene(isEnd) {
      let newScene = JSON.parse(JSON.stringify(this.emptyScene));
      newScene.isEndNode = isEnd;
      if (isEnd)
        newScene.name = "Succes";
      newScene.sceneId = "new".concat(this.newSceneCounter++); //Add a new keyword so we know which scenes were added when saving
      newScene.chapterId = Number(this.$route.query["chapter"]);
      newScene.x = this.$refs.canvasScroll.scrollLeft;
      newScene.y = this.$refs.canvasScroll.scrollTop;
      this.scenario.scenes.push(newScene);
      this.$forceUpdate();
    },
    /** Remove a scene node from the chapter based on id */
    removeScene(id) {
      if (id == this.scenario.startSceneId) { //If the user is trying to remove the start scene
        this.errorMessage = "De start scene mag niet verwijderd worden";
        this.errorVisible = true;
        return;
      }
      if (this.$refs.propertiesPane) this.$refs.propertiesPane.setWindow(1);
      let removableScene = this.scenario.scenes.find(
        scene => id == scene.sceneId
      );
      this.lines = this.lines.filter(line =>  !removableScene.sceneChanges.includes(line));
      this.lines = this.lines.filter(line =>  line.inputNode.sceneId != removableScene.sceneId);
      this.scenario.scenes = this.scenario.scenes.filter(
        scene => id != scene.sceneId
      );
      this.selectedScene = null;
    },

    /** Update name of chapter */
    updateName(name) {
      return this.$axios
        .post(process.env.VUE_APP_API_HOST + "/chapter/update/name", {
          chapterId: this.$route.query["chapter"],
          name: name
        })
        .then(() => {
          this.scenario.name = name;
        })
        .catch(errors => {
          console.error(errors);
        });
    },
    /** Set output node selection (when connecting nodes) */
    selectOutputNode(sceneid, i) {
      this.outputNode.sceneid = sceneid;
      this.outputNode.sceneChangeIndex = i;
      const outputScene = this.getSceneFromId(sceneid);
      let sceneChange = outputScene.sceneChanges[i];
      if (sceneChange.inputNode != null && sceneChange.inputNode != -1) {
        sceneChange.inputNode = null;
        this.lines[this.startNode.lineIndex] = sceneChange;
      } else {
        this.addLine(sceneChange);
      }
    },
    /** Select start node */
    selectStartNode() {
      this.startNode = {
        ...this.startNode
      };
      this.outputNode.sceneid = 0;
      this.startNode.inputNode = null;
      const nodeIndex = this.lines.findIndex(x => x.startNode);
      this.tempStartNodeLine = this.lines[nodeIndex];
      this.lines.splice(nodeIndex, 1);
      this.addLine(this.startNode);
    },
    /** Reset start node */
    resetStartNode() {
      this.lines.push(this.tempStartNodeLine);
    },
    /** Check if the scene changes go in a circle or not */
    isNotCircle(scene) {
      if (scene.sceneChanges) {
        return scene.sceneChanges.every(x => {
          //If the scene change in this every is the same as the current outputNode, it's a circle
          if (x.inputNode?.sceneId == this.outputNode.sceneid) {
            return false;
          } else if (x.inputNode) {
            return this.isNotCircle(x.inputNode);
          }
          return true;
        });
      } else {
        return true;
      }
    },
    /** Set scene change (when connecting nodes) */
    selectInputNode(id) {
      if (
        this.outputNode.sceneid != null &&
        this.outputNode.sceneChangeIndex != null
      ) {
        const scene = this.getSceneFromId(id);
        /** Only allow scene changes if it doesn't go in a circle */
        if (this.isNotCircle(scene)) {
          const outputScene = this.getSceneFromId(this.outputNode.sceneid);

          if (outputScene.sceneId == scene.sceneId) {
            this.errorMessage = 'Scene veranderingen in een cirkel is niet toegestaan';
            this.errorVisible = true;
            return;
          }

          let sceneChange =
            outputScene.sceneChanges[this.outputNode.sceneChangeIndex];
          sceneChange.inputNode = scene;
          sceneChange.changeSceneId = sceneChange.inputNode.sceneId;
        } else {
          this.errorMessage = 'Scene veranderingen in een cirkel is niet toegestaan';
          this.errorVisible = true;
        }
      } else if (this.outputNode.sceneid == 0) {
        /** from start to scene node */

        const scene = this.getSceneFromId(id);
        this.startNode.inputNode = scene;
        this.scenario.startSceneId = id;
      }
      this.outputNode.sceneid = null;
      this.outputNode.sceneChangeIndex = null;
      this.inputNode = null;
    },
    /** Select input end node */
    selectInputEndNode() {
      if (
        this.outputNode.sceneid != null &&
        this.outputNode.sceneChangeIndex != null
      ) {
        const outputScene = this.getSceneFromId(this.outputNode.sceneid);
        let sceneChange =
          outputScene.sceneChanges[this.outputNode.sceneChangeIndex];

        sceneChange.inputNode = {
          endNode: true
        };
        sceneChange.changeSceneId = 0;

        this.outputNode.sceneid = null;
        this.outputNode.sceneChangeIndex = null;
        this.inputNode = null;
      }
    },
    /** Remove a scene change line */
    removeLine(lineNode) {
      if (!lineNode.startNode) {
        lineNode.changeSceneId = -1;
      }
      const index = this.lines.indexOf(lineNode);
      this.lines.splice(index, 1);

      this.outputNode.sceneid = null;
      this.outputNode.sceneChangeIndex = null;
      this.inputNode = null;
    },
    /** add node connection line */
    addLine(node) {
      this.lines.push(node);
    },
    /** save all scene info */
    saveScene(scene = null) {
      return this.$axios
        .post(process.env.VUE_APP_API_HOST + "/scene/update", {
          sceneData: scene
        })
        .catch(errors => {
          console.error(errors);
        });
    },
    //Go to the scene editor
    async openSceneEditor(sceneId) {
      await this.saveChapter(sceneId);
      //If this is a new scene, use the id it gets from the database stored in newSceneId from savechapter, else just use sceneId
      this.$router
        .push({
          name: "sceneeditor",
          query: {
            case: this.$route.query["case"],
            chapter: this.$route.query["chapter"],
            scene: this.newSceneId ? this.newSceneId : sceneId
          }
        })
        .catch(errors => {
          if (errors?.type != 4)
            //type 4 is manually aborted in guard
            console.log("navigation went wrong", errors);
        });
    },

    /** Get scene from id */
    getSceneFromId(id) {
      return this.scenario.scenes.filter(x => x.sceneId == id)[0];
    },
    /** Get scene index from id */
    getSceneIndexFromId(id) {
      return this.scenario.scenes.indexOf(this.getSceneFromId(id));
    },
    /** Calculate overlap for two rectangles */
    conditionCheckPositions(rect, otherRect) {
      const otherNodeWidth = otherRect.width;
      const otherNodeHeight = otherRect.height;
      const maxWidthNode = rect.left + rect.width;
      const maxHeightNode = rect.top + rect.height;
      const maxWidthOtherNode = otherRect.left + otherNodeWidth;

      return (
        otherRect.left <= maxWidthNode &&
        maxWidthOtherNode >= rect.left &&
        otherRect.top <= maxHeightNode &&
        otherNodeHeight + otherRect.top >= rect.top
      );
    },
    /** Checks for each chapter node if there is any overlap with the given rect */
    checkPositionsSceneNode(rect, sceneid) {
      return this.scenario.scenes.some(sceneNode => {
        if (sceneNode.sceneId != sceneid) {
          const otherNode = this.$refs[
            "chapter_" + sceneNode.sceneId
          ][0].$refs.moveable.getRect();
          return this.conditionCheckPositions(rect, otherNode);
        }
      });
    },
    /** Checks if there is any overlap between rect and the start node */
    checkPositionsStartNode(rect) {
      const startNode = this.$refs.startNode.$refs.moveable.getRect();
      return this.conditionCheckPositions(rect, startNode);
    },
    /** Checks for the given rect if it overlaps with anything */
    overlapsNode(rect, sceneid) {
      return (
        this.checkPositionsSceneNode(rect, sceneid) ||
        this.checkPositionsStartNode(rect)
      );
    },
    /** Checks for the given rect of the start node if it overlaps with anything */
    overlapsStart(rect) {
      return (
        this.checkPositionsSceneNode(rect, undefined)
      );
    },
  },
  created() {
    this.getChapter();
  },
  mounted() {
    // Set to no unsaved changes after all initial setup has been handled
    setTimeout(() => {
      this.unsavedChanges = false;

      const deletedSceneId = this.$route.query["deletedScene"];
      //If the user pressed the delete scene button in the scene editor, delete the scene here and update the router query
      if (deletedSceneId) {
        this.removeScene(deletedSceneId);
        let query = Object.assign({}, this.$route.query);
        delete query.deletedScene;
        this.$router.replace({ query });
      }
    }, 100);
    this.loadNodes = true;
  },
  computed: {
    /** Computed start node position */
    startNodePosition() {
      return {
        x: this.startNode.position.x,
        y: this.startNode.position.y
      };
    },
  },
  watch: {
    scenario: {
      handler() {
        this.unsavedChanges = true;
      },
      deep: true
    },
    lines: {
      handler() {
        this.unsavedChanges = true;
      },
      deep: true
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.unsavedChanges) {
      next();
    } else {
      this.currentNextFunction = next;
      this.showVerifyUnsavedModal = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.chapter-editor {
  height: 100%;
  width: 100%;
  min-width: max-content;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.canvas-container {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.canvas-scroll {
  height: 100%;
  width: 100%;
  position: relative;
  box-shadow: $box-shadow-white;
  border-radius: 2em;
  overflow: auto;
}
.canvas {
  position: absolute;
  width: 2000px;
  height: 3000px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
}
#mySVG {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
#errorToast,
#saveToast {
  pointer-events: none;
}
</style>
