//This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessToken: null,
    refreshToken: null
  },
  mutations: {
    /** set access token for user */
    setAccessToken(state, tokens) {
      state.accessToken = tokens.accessToken;
      localStorage.setItem("accessToken", tokens.accessToken);
      if (tokens.refreshToken) {
        state.refreshToken = tokens.refreshToken;
        localStorage.setItem("refreshToken", tokens.refreshToken);
      }
    },
    /** logout user */
    logout(state) {
      state.accessToken = null;
      state.refreshToken = null;
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
    }
  }
});
