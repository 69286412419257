<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <Moveable
    v-bind="moveable"
    @drag="handleDrag"
    @dragEnd="handleEndDrag"
    @scroll="handleScroll"
    @contextmenu.prevent.native="$emit('openInfoPane')"
    :container="canvas"
    :scrollContainer="canvasScroll"
    v-if="loadNode"
    class="node overflow-hidden "
    :style="{
      transform: 'matrix(1, 0, 0, 1, ' + position.x + ', ' + position.y + ')'
    }"
    ref="moveable"
  >
    <!-- The input dot -->
    <div
      v-if="nodeTypes[nodeType] != nodeTypes.START"
      class="inputdot d-flex justify-content-center"
    >
      <div
        :id="
          'inputNode_' +
            (nodeTypes[nodeType] == nodeTypes.SCENE ? sceneid : 'end')
        "
        class="dot"
        @mouseup="$emit('selectInputNode')"
        ref="inputNode"
      ></div>
    </div>
    <!-- The edit scene and view scene info buttons -->
    <span
      class="pb-1 mx-4"
      :class="{ 'node-id': nodeTypes[nodeType] == nodeTypes.SCENE }"
      >{{ name }}</span
    >
    <div
      v-if="nodeTypes[nodeType] != nodeTypes.START"
      class="d-flex align-self-center justify-content-center mx-2"
    >
      <button
        v-if="nodeTypes[nodeType] == nodeTypes.SCENE" 
        class="button edit d-flex align-self-center justify-content-center mx-1"
        @mousedown.left.stop="$emit('editNode')"
      >
        <font-awesome-icon
          class="icon node-icon-edit d-flex align-self-center "
          icon="pen"
        />
      </button>
      <button
        class="button info  d-flex align-self-center justify-content-center mx-1"
        @mousedown.left.stop="$emit('openInfoPane')"
        :class="{
          button:nodeTypes[nodeType] == nodeTypes.SCENE,
          'button-endnode':nodeTypes[nodeType] == nodeTypes.END
        }"
      >
        <font-awesome-icon
          class="icon node-icon-info d-flex align-self-center"
          icon="info"
        />
      </button>
      <button
        v-if="nodeTypes[nodeType] == nodeTypes.SCENE" 
        class="button info d-flex align-self-center justify-content-center mx-1"
        @mousedown.left.stop="$emit('openSoundPane')"
      >
        <font-awesome-icon
          class="icon node-icon-info d-flex align-self-center"
          icon="music"
        />
      </button>
    </div>
    <!-- The list of scene changes in the form of prop images and output dots -->
    <div
      v-if="nodeTypes[nodeType] == nodeTypes.SCENE"
      class="scenedots row mx-0 h-100 d-flex justify-content-center align-items-center"
    >
      <div
        v-for="(sceneChange, i) in sceneChanges"
        :key="i"
        class="scenechange"
      >
        <div class="d-flex flex-column justify-content-center">
          <!-- scene change letter and prop image -->
          <div
            class="prop-container flex-column d-flex justify-content-center align-items-center"
          >
            <small
              ><small>
                <span v-if="sceneChange.letter">
                  {{ sceneChange.letter }}
                </span>
              </small>
            </small>
            <div class="propimg">
              <img :src="sceneChange.image" class="image" />
            </div>
          </div>
          <!-- Output dot -->
          <div class="d-flex align-items-center justify-content-center">
            <div
              class="dot dotoutput"
              :ref="sceneid + '_dot_' + i"
              @mousedown="
                linedrag = true;
                $emit('selectOutputNode', i);
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- The output dot for the start node -->
    <div
      v-if="nodeTypes[nodeType] == nodeTypes.START"
      class="d-flex align-items-center justify-content-center"
    >
      <div class="dot" @mousedown.stop="$emit('selectStartNode')"></div>
    </div>
  </Moveable>
</template>

<script>
import Moveable from "vue-moveable";

export default {
  name: "SceneNode",
  components: {
    Moveable
  },
  props: [
    "canvas",
    "canvasScroll",
    "nodeType",
    "position",
    "name",
    "sceneChanges",
    "sceneid",
    "overlaps"
  ],
  data() {
    return {
      moveable: {
        draggable: true,
        throttleDrag: 1,
        snappable: true,
        scrollable: true,
        scrollThreshold: 0
      },
      loadNode: false,
      linedrag: false,
      nodeTypes: {
        START: 1,
        SCENE: 2,
        END: 3
      }
    };
  },
  methods: {
    /** Update the scene change positions in the node */
    updateDotPositions() {
      if (this.nodeTypes[this.nodeType] != this.nodeTypes.SCENE) return;

      for (let dotIndex = 0; dotIndex < this.sceneChanges.length; dotIndex++) {
        const dot = this.$refs[this.sceneid + "_dot_" + dotIndex];
        const dotPos = dot[0].getBoundingClientRect();
        const canvasPos = this.canvas.getBoundingClientRect();
        const newPos = {
          x: dotPos.left + dotPos.width - canvasPos.left - 4,
          y: dotPos.top + dotPos.height - canvasPos.top + 2
        };
        this.$emit("updateDotPosition", { dotIndex, newPos });
      }
    },
    /** Updates the positions while the node is dragged */
    handleDrag({ target, transform }) {
      if (this.linedrag) return;

      target.style.transform = transform;
      const newPosition = {
        x:
          parseInt(transform.split(")")[0].split(",")[4]) +
          parseInt(
            transform
              .split("(")[2]
              .split(",")[0]
              .split("px")[0]
          ),
        y:
          parseInt(transform.split(")")[0].split(",")[5]) +
          parseInt(
            transform
              .split("(")[2]
              .split(",")[1]
              .split("px")[0]
          )
      };
      this.$emit("setPosition", newPosition);
      this.updateDotPositions();
    },
    /** Checks if there is any overlap with other nodes and if so resets the position */
    handleEndDrag({ lastEvent }) {
      if (this.linedrag) return;

      const rect = this.$refs.moveable.getRect();

      const overlapping = this.overlaps(rect);

      if (overlapping) {
        const oldPosition = {
          x: this.position.x - lastEvent.beforeTranslate[0],
          y: this.position.y - lastEvent.beforeTranslate[1]
        };
        this.$emit("setPosition", oldPosition);
        this.$nextTick(() => {
          this.updateDotPositions();
          this.$refs.moveable.updateRect();
        });
      }
    },
    /** Allows you to scroll across the canvas when dragging this node past the window edges */
    handleScroll({ scrollContainer, direction }) {
      scrollContainer.scrollTo(
        scrollContainer.scrollLeft + direction[0] * 5,
        scrollContainer.scrollTop + direction[1] * 5
      );
    },
    getSize() {
      return this.$refs.moveable.getRect();
    }
  },
  created() {
    document.addEventListener("mouseup", () =>
      this.$nextTick(() => {
        this.linedrag = false;
      })
    );
  },
  mounted() {
    // used in v-if to avoid rendering before container refs have been set
    this.loadNode = true;
    this.$nextTick(() => {
      this.updateDotPositions();
    });
    const rect = canvas.getBoundingClientRect();
    this.moveable.bounds = {
      top: 0,
      left: 0,
      right: rect.width,
      bottom: rect.height
    };
  },
  beforeDestroy() {
    document.removeEventListener("mouseup", () =>
      this.$nextTick(() => {
        this.linedrag = false;
      })
    );
  },
  watch: {
    // Update the moveable bounds when you change the name
    name: function() {
      this.$nextTick(() => {
        this.$refs.moveable.updateRect();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.node {
  background-color: $lightgray;
  position: absolute;
  text-align: center;
  border: 0px;
  flex: 0 0 auto;
  width: fit-content;
}

.prop-container {
  height: 2.7em;
  position: relative;
  display: flex;
  flex-direction: column;
}
.propimg {
  height: 1.5em;
  width: 1.7em;
}

.icon {
  position: relative;
  width: 0.7em;
  height: 0.7em;
}

.button {
  border-radius: 20px;
  border: 0px;
  width: 2.5em;
  height: 1.3em;
  background-color: $white;
  margin-top: 10px;
  padding: 5px;
  border: solid 0.1em rgba(0, 0, 0, 0);
  outline: none;
  &:hover {
    border: solid 2px $black;
  }
  &:focus {
    border: solid 2px $green;
  }

  &-endnode{
    border-radius: 20px;
    border: 0px;
    width: 2.5em;
    height: 1.3em;
    background-color: $white;
    margin-top: 2px;
    padding: 5px;
    border: solid 0.1em rgba(0, 0, 0, 0);
    outline: none;
    &:hover {
      border: solid 2px $black;
    }
    &:focus {
      border: solid 2px $green;
    }
  }
}

.node-id {
  position: relative;
  border-bottom: $basic-border-black;
  text-align: center;
  width: 100%;
}

.scenedots {
  position: relative;
  bottom: 0px;
  width: 100%;
  min-height: 1em;
}

.scenechange {
  width: 2em;
  height: 4em;

  .image {
    max-width: 100%;
    max-height: 100%;
  }
}

.inputdot {
  position: relative;
  top: 0em;
  width: 100%;
  left: 0;
}

.dot {
  width: 0.7em;
  height: 0.7em;
  background-color: $black;
  border-radius: 100%;
  margin: 0.2em;

  &:hover {
    background-color: rgb(0, 120, 240);
  }
}

.col {
  position: inherit !important;
}
</style>
