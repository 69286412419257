<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div>
    <!-- ====================
          Event Types
    ===================== -->

    <!-- NOTEBOOK -->
    <div v-if="event.type == $ENUM_EVENTTYPE.NOTEBOOK">
      <div class="input-row">
        <div class="form-group form-inline">
          <label><b>Tekst:</b></label>
          <textarea
            type="text"
            class="noteblock-text"
            placeholder="Tekst..."
            v-model="event.value"
          />
        </div>
      </div>
    </div>

    <!-- SOUND -->
    <div class="input-row" v-if="event.type == $ENUM_EVENTTYPE.SOUND">
      <div class="form-group form-inline">
        <label><b>Geluid:</b></label>
        <select class="custom-select" v-model="event.value">
          <option v-for="sound in sounds" :key="sound.id" :value="sound">
            {{ sound.name }}
          </option>
        </select>
      </div>
      <div v-if="event.value">
        <audio
          class="form-group form-inline"
          controls
          controlsList="nodownload"
          :src="event.value.audio"
          ref="audioRef"
        />
        <av-line
          class="form-group form-inline"
          :canv-width="250"
          :ref-link="'audioRef'"
        />
      </div>
    </div>

    <!-- CHANGESCENE -->
    <div v-if="event.type == $ENUM_EVENTTYPE.CHANGESCENE">
      <small
        >U kunt de scene verandering nu configureren in het overzicht.</small
      >
      <hr />
    </div>

    <!-- DIALOGUE -->
    <div v-if="event.type == $ENUM_EVENTTYPE.DIALOGUE">
      <div class="input-row">
        <div class="form-group form-inline">
          <label><b></b></label>
          <button
            class="dialogue-button mb-1"
            @click="
              $emit('openDialogueEditor', {
                id: event.event_id,
                name: event.name
              })
            "
          >
            Dialoog bewerken
          </button>
        </div>
      </div>
    </div>

    <!-- MINIGAME -->
    <div v-if="event.type == $ENUM_EVENTTYPE.MINIGAME">
      <div class="input-row">
        <div class="form-group form-inline">
          <label><b>Minigame:</b></label>
          <input
            type="text"
            class="add-flag-input"
            placeholder="Text"
            autocomplete="off"
            v-model="event.value"
          />
        </div>
      </div>
    </div>

    <!-- ====================
            Run Types 
        ===================== -->

    <!-- Select run type -->
    <div class="input-row" v-if="!isContactsPanel"> <!--Don't show this for contacts-->
      <div class="form-group form-inline">
        <label><b>Wanneer:</b></label>
        <select class="custom-select" v-model="event.runType"> 
          <!--A prop that has a show event can't be clicked, so Click and Observe can't be chosen for those events-->
          <option
            :value="$ENUM_EVENTRUNTYPE.CLICK"
            v-if="event.type != $ENUM_EVENTTYPE.SHOW"
          >
            Klik
          </option>
          <option :value="$ENUM_EVENTRUNTYPE.OBSERVE"
            v-if="event.type != $ENUM_EVENTTYPE.SHOW"
          >
            Klik (Observeer)
          </option>
          <option :value="$ENUM_EVENTRUNTYPE.TIME">
            Tijd
          </option>
          <option :value="$ENUM_EVENTRUNTYPE.CONDITION">
            Conditie
          </option>
        </select>
      </div>
    </div>

    <!-- TIME -->
    <div class="input-row" v-if="event.runType == $ENUM_EVENTRUNTYPE.TIME">
      <div class="form-group form-inline">
        <label><b>Na:</b></label>
        <input
          type="number"
          class="form-control"
          autocomplete="off"
          value="0"
          min="0"
          v-model="event.seconds"
        />
        <span class="sec">seconden</span>
      </div>
    </div>

    <label class="float-left"><b>Voorwaarden:</b></label>

    <!-- CONDITION dropdown-->
    <div class="d-flex justify-content-center mt-1 w-100 condition-dropdown">
      <div class="w-100">
        <div v-for="(condition, i) in event.condition" :key="i"> 
          <ConditionDropdown
            v-if="!isNaN(parseInt(condition)) || condition == ' '"
            :condition="condition"
            :andOrOperator="event.condition[i+1]"
            :negatedIcon="event.condition[i-1] == '-'"
            :index="i"
            :chapterEventData="chapterEventData"
            
            @onConditionButton="$emit('openConditionModal', $event)"
            @toggleNegation="toggleNegation"
            @addCondition="addCondition"
            @removeCondition="removeCondition"
            @switchOperator="switchOperator"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConditionDropdown from "../ConditionDropdown";
import soundList from "../../util/soundList";

export default {
  name: "PropEvent",
  components: { ConditionDropdown },
  props: { event: {}, chapterEventData: {}, isContactsPanel: false },
  data() {
    return {
      openConditionPanel: false,
      selected: 0,
      /** List of all sounds */
      sounds: soundList.sounds
    };
  },
  methods: {
    //When the +/- operator is toggled in the condition menu
    toggleNegation(conditionIndex, operator) {
      this.$set(this.event.condition, conditionIndex - 1, operator);
    },

    //When the And/Or operator switches
    switchOperator(conditionIndex, operator) {
      this.$set(this.event.condition, conditionIndex + 1, operator);
    },

    //Add a condition to this event
    addCondition(index) {
      if (this.event.condition[index] && this.event.condition[index] == 0) {
        //this.printErrorMessage("Er is nog een conditie die niet is ingevuld")
        return;
      }
      this.event.condition.push("A"); //Push a default and condition for the previous condition
      this.event.condition.push("+"); //Push a default + condition to the new condition
      this.event.condition.push("0"); //Push a default condition as new condition
    },

    //Remove condition from this event
    removeCondition(index) {
      if (this.event.condition.length <= 2)
        //Replace with the default if it's the last condition that's left
        this.event.condition = ["+", "0"];
      else {
        if (index == 1)
          //If the first condition is being removed
          this.event.condition.splice(index - 1, 3);
        //We have to remove to and/or of this condition, the +/- of this condition, and this condition itself
        else this.event.condition.splice(index - 2, 3); //We have to remove to and/or of the previous condition, the +/- of this condition, and this condition itself
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.condition-dropdown{
    margin-left: -8px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.404);
}
.form-group {
  width: 100%;
  margin-bottom: 0.5rem;
}
.dialogue-button {
  font-family: $default-font;
  border: 0px;
  background-color: $black;
  color: $white;
  border-radius: 30px;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: $lightblack;
  }
}
.input-row {
  label {
    line-height: 100%;
  }
  select {
    width: 70% !important;
    margin-left: auto;
  }
  input {
    width: 70% !important;
    margin-left: auto;
  }
  button {
    width: 100% !important;
    margin-left: auto;
  }
  position: relative;
}

.modal-backdrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.sec {
  position: absolute;
  top: auto;
  right: 35px;
}

.noteblock-text {
  margin-left: auto;
  width: 70%;
  border: $basic-border;
  &:focus {
    outline: 2px auto $darkgray;
  }
}

::placeholder {
  color: $lightgray;
}
</style>
