<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div v-show="openConditionPanel" class="modal-backdrop">
    <ModalPage>
      <template v-slot:header>
        <b class="ml-auto"><h4>Selecteer een evenement</h4></b>
        <font-awesome-icon
          @click="toggleConditionPanel"
          class="close-icon ml-auto"
          icon="times"
        />
      </template>
      <template v-slot:body>
        <Tree
          id="treeID"
          ref="conditionTree"
          class="tree-border"
          :custom-styles="treeStyle"
          :condIndex="condIndex"
          :custom-options="treeHandling"
          :nodes="nodes"
        />
      </template>
    </ModalPage>
  </div>
</template>

<script>
import ModalPage from "./DialogueEditor/ModalPage";
import Tree from "vuejs-tree";

import EventDict from "../util/EventEnumToName";

export default {
  name: "ConditionModal",
  components: { ModalPage, Tree },
  props: [
    "condIndex",
    "nodes",
    "treeID",
    "events"
  ],

  data() {
    return {
      openConditionPanel: false,
      eventDictionary: EventDict,
    };
  },

  methods: {
    loadEventData(currentEventId, excludeCurrent) {
      for (const [event_id, event] of Object.entries(this.events)) {
        if (currentEventId == event_id && excludeCurrent) continue;
        this.insertTreeData("A", {
          text: event_id + ": " + this.getEventDisplayString(event_id),
          id: "A" + event_id
        });
        for (const [dialogue_id, dialogue] of Object.entries(event.dialogue)) {
          this.insertTreeData("A" + event_id, {
            text: dialogue_id + ": " + this.getDialogueDisplayString(event_id, dialogue_id),
            id: "A" + event_id + "." + dialogue_id
          });
        }
      }

      //Start with the base node expanded
      this.$refs["conditionTree"].expandNode("A", 1);
      this.$refs["conditionTree"].expandNode("B", 1);
    },

    //Convert the event id into a suitable string for displaying information in the event explorer.
    getEventDisplayString(eventId) {
      if (!this.events) return;
      
      let condData = this.events[eventId];
      if (!condData)
        return;
      
      return this.eventDictionary[condData.eventType] + " - " + condData.name;
    },

    //Convert the event id into a suitable string for displaying information in the event explorer.
    getDialogueDisplayString(eventId, dialogueId) {
      if (!this.events) return;
      
      let condData = this.events[eventId];
      if (!condData)
        return;
      
      condData = condData.dialogue[dialogueId];
      let displayString = "";
      if (condData.type == "Start") //Start node
        return displayString + "Start=>";
      else if (condData.type == "Choice") //Choice node
        return displayString + "<Choice>";
      else if (condData.type == "Option") //Option node
        return displayString + " > " + this.quoteString(condData.value);
      else if (condData.type == "Player" || condData.portrait != "Nothing") //Said by someone
        return displayString + " \"" + this.quoteString(condData.value) + "\"";
      else //Else it's narrative text
        return displayString + " \'" + this.quoteString(condData.value) + "\'";
    },
    quoteString(sentence){ //Shorten a string to its first and last three words
      const words = sentence.split(" ");
      if (words.length > 6)
        return words[0] + " " + words[1] + " " + words[2] + " ... " + words[words.length - 3] + " " + words[words.length - 2] + " " + words[words.length - 1];
      else
        return sentence;
    },

    eventSelected(nodeId) {
      if (this.$refs["conditionTree"].findNode(nodeId).nodes) {
        //If there are still children underneath, do nothing
        return;
      }

      //Return the selected as condition
      const conditionId = nodeId.substring(1); //Remove the A or B
      if (!conditionId)
        //If there's no valid condition (possibly because there were no events)
        return;

      this.$emit("selectedCondition", conditionId, this.condIndex);
      this.toggleConditionPanel();
    },
    toggleConditionPanel(eventId = 0, excludeCurrentEvent = true) {
      this.openConditionPanel = !this.openConditionPanel;
      if (!this.openConditionPanel) {
        this.$refs["conditionTree"].collapseAllNodes();
        this.clearTreeChildren("A");
      } else this.loadEventData(eventId, excludeCurrentEvent);
    },
    clearTreeChildren(parentId) {
      this.$refs["conditionTree"].findNode(parentId).nodes = [];
    },
    insertTreeData(parentId, data) {
      let parentFolder = this.$refs["conditionTree"].findNode(parentId);
      if (parentFolder.nodes) {
        parentFolder.nodes.push({ id: data.id, text: data.text });
      } else {
        parentFolder.nodes = [{ id: data.id, text: data.text }];
      }
    }
  },
  computed: {
    treeHandling() {
      return {
        treeEvents: {
          collapsed: {
            state: true,
            fn: this.eventSelected
          }
        },
        events: {
          expanded: {
            state: true
          },
          editableName: {
            state: true,
            calledEvent: "expanded"
          }
        }
      };
    },
    treeStyle() {
      return {
        tree: {
          height: 'auto',
          maxHeight: '300px',
          overflowY: 'visible',
          display: 'inline-block',
          textAlign: 'left',
          width: '100%',
          height: '100%',
          'padding-inline-start': 0,
        },
        row: {
          width: '100%',
          cursor: 'pointer',
          child: {
            height: 'auto',
            margin: '6px 0 6px 0',
            'border-style': 'solid',
            'border-width': '0 0 1px 0',
            'border-color': 'rgb(239, 239, 239)',
            'padding-left': '1em'
          },
        },
        rowIndent: {
          'padding-left': '0px',
          'margin-left': '20px',
          'border-style': 'dashed',
          'border-width': '0 0 0 1px',
          'border-color': 'lightgray',
        },
        text: {
          style: {
            'text-transform': 'none'
          },
        }
      };
    },
  }
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-icon {
  margin-top: auto;
  margin-bottom: auto;
  &:hover {
    color: red;
    cursor: pointer;
  }
}

.tree-border {
  border-style: dashed;
  border-width: 0 0 0 1px;
  border-color: lightgray;
}

.width{
  &-1-em{
    width: auto;
  }
}
</style>
