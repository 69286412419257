var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loadNode)?_c('Moveable',_vm._b({ref:"moveable",staticClass:"draggableprop position-absolute d-flex",style:({
    transform:
      'matrix(1, 0, 0, 1, ' +
      this.position.x +
      ', ' +
      this.position.y +
      ') rotate(' +
      _vm.prop.rotation +
      'deg) scaleX(' +
      _vm.prop.scale +
      ') scaleY(' +
      _vm.prop.scale +
      ')',
    zIndex: _vm.prop.depth,
    margin: '-0.1em'
  }),attrs:{"container":_vm.canvas,"className":_vm.selected ? 'selected-prop-moveable' : 'unselected-prop-moveable',"propname":_vm.prop.name},on:{"dragStart":_vm.handleStartDrag,"drag":_vm.handleDrag,"dragEnd":_vm.handleEndDrag,"rotate":_vm.handleRotate,"rotateEnd":_vm.handleEndRotate,"scale":_vm.handleScale,"scaleEnd":_vm.handleEndScale},nativeOn:{"contextmenu":function($event){$event.preventDefault();return _vm.editProp(true)},"click":function($event){$event.stopPropagation();return _vm.editProp(false)}}},'Moveable',_vm.moveable,false),[_c('img',{style:({
      transform: 'scaleX(' + _vm.flipX + ') scaleY(' + _vm.flipY + ')'
    }),attrs:{"src":_vm.prop.image,"alt":"","width":(_vm.canvasSize.width / 1920) * _vm.prop.image_width,"height":(_vm.canvasSize.width / 1920) * _vm.prop.image_height}})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }