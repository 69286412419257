<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <Moveable
    v-bind="moveable"
    @dragStart="handleStartDrag"
    @drag="handleDrag"
    @dragEnd="handleEndDrag"
    @rotate="handleRotate"
    @rotateEnd="handleEndRotate"
    @scale="handleScale"
    @scaleEnd="handleEndScale"
    :container="canvas"
    :className="
      selected ? 'selected-prop-moveable' : 'unselected-prop-moveable'
    "
    :propname="prop.name"
    v-if="loadNode"
    class="draggableprop position-absolute d-flex"
    :style="{
      transform:
        'matrix(1, 0, 0, 1, ' +
        this.position.x +
        ', ' +
        this.position.y +
        ') rotate(' +
        prop.rotation +
        'deg) scaleX(' +
        prop.scale +
        ') scaleY(' +
        prop.scale +
        ')',
      zIndex: prop.depth,
      margin: '-0.1em'
    }"
    ref="moveable"
    @contextmenu.native.prevent="editProp(true)"
    @click.native.stop="editProp(false)"
  >
    <img
      :src="prop.image"
      alt=""
      :width="(canvasSize.width / 1920) * prop.image_width"
      :height="(canvasSize.width / 1920) * prop.image_height"
      :style="{
        transform: 'scaleX(' + flipX + ') scaleY(' + flipY + ')'
      }"
    />
  </Moveable>
</template>

<script>
import Moveable from "vue-moveable";

export default {
  name: "Props",
  components: {
    Moveable
  },
  props: ["prop", "index", "selected", "canvasSize", "canvas"],
  data() {
    return {
      dragged: false,
      loadNode: false
    };
  },
  methods: {
    handleStartDrag() {
      if (!this.selected) {
        this.editProp(false);
      }
      this.dragged = true;
    },
    handleDrag({ target, transform }) {
      target.style.transform = transform;
    },
    handleEndDrag({ lastEvent }) {
      if (lastEvent) {
        const pos = {
          x: this.position.x + lastEvent.left,
          y: this.position.y + lastEvent.top
        };
        this.$emit("setPosition", this.posToRelative(pos));
      }
      this.$nextTick(() => (this.dragged = false));
    },
    handleRotate({ target, transform }) {
      target.style.transform = transform;
    },
    handleEndRotate({ lastEvent }) {
      if (lastEvent) {
        let rotate = lastEvent.rotate;
        rotate = this.prop.rotation + Math.round(rotate);
        rotate %= 360;
        if (rotate > 180) {
          rotate -= 360;
        }
        if (rotate < -180) {
          rotate += 360;
        }
        this.$emit("setRotation", rotate);
      }
    },
    handleScale({ target, scale, transform }) {
      if (this.prop.scale * scale[0] > 0.5) {
        target.style.transform = transform;
      }
    },
    handleEndScale({ lastEvent }) {
      if (lastEvent) {
        var scale = lastEvent.scale[0];
        if (this.prop.scale * scale > 0.5) {
          scale *= this.prop.scale;
        } else {
          scale = 0.5;
        }
        this.$emit("setScale", scale);
        this.$nextTick(() => this.$refs.moveable.updateRect());
      }
    },
    /** select this prop to edit in prop editor */
    editProp(allowUnselect) {
      if (!this.selected || allowUnselect) {
        this.$emit("editProp", this.index);
      }
    },
    /** Absolute to relative position functions */
    posToRelative(pos) {
      const width = (this.canvasSize.width / 1920) * this.prop.image_width;
      const height = (this.prop.image_height / this.prop.image_width) * width;
      return {
        x: (pos.x + width / 2) / this.canvasSize.width, //positionX
        y: (pos.y + height / 2) / this.canvasSize.height //positionY
      };
    },
    /** Relative to absolute position functions */
    posToAbsolute(pos) {
      const width = (this.canvasSize.width / 1920) * this.prop.image_width;
      const height = (this.prop.image_height / this.prop.image_width) * width;
      return {
        x: pos.x * this.canvasSize.width - width / 2, //positionX
        y: pos.y * this.canvasSize.height - height / 2 //positionY
      };
    },
    outOfBounds(rectangle) {
      return (
        rectangle.top < -1 ||
        rectangle.left < -1 ||
        rectangle.left + rectangle.width > this.moveable.bounds.right + 1 ||
        rectangle.top + rectangle.height > this.moveable.bounds.bottom + 1
      );
    }
  },
  mounted() {
    //used in v-if to avoid rendering before container refs have been set
    this.loadNode = true;
    this.$emit("loaded");
  },
  computed: {
    /** check if prop needs to be flipped based on settings */
    flipX() {
      return this.prop.flip_x ? -1 : 1;
    },
    flipY() {
      return this.prop.flip_y ? -1 : 1;
    },
    moveable() {
      let moveable = {
        draggable: true,
        throttleDrag: 1,
        snappable: true,
        scrollable: false,
        rotatable: this.selected,
        scalable: this.selected,
        keepRatio: true,
        snapThreshold: 1
      };
      if (this.canvasSize.width > 0 && this.canvasSize.height > 0) {
        moveable.bounds = {
          top: 0,
          left: 0,
          right: this.canvasSize.width,
          bottom: this.canvasSize.height
        };
      }
      return moveable;
    },
    position() {
      return this.posToAbsolute({
        x: this.prop.position.x,
        y: this.prop.position.y
      });
    }
  },
  watch: {
    "prop.rotation": {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.$refs.moveable.updateRect();

          if (this.outOfBounds(this.$refs.moveable.getRect())) {
            this.$emit("setRotation", oldVal);
            this.$refs.moveable.updateRect();
          }
        });
      }
    },
    "prop.scale": {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.$refs.moveable.updateRect();

          if (this.outOfBounds(this.$refs.moveable.getRect())) {
            this.$emit("setScale", oldVal);
            this.$refs.moveable.updateRect();
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
.unselected-prop-moveable {
  opacity: 0 !important;
}

.selected-prop-moveable {
  z-index: 0;
}
</style>
