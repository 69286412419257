<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div class="propborder mb-3">
    <div
      id="prop"
      class="cloneable draggable propbutton d-flex justify-content-center align-items-center py-1"
      :propindex="index"
      @mousedown="$emit('addProp', index)"
      :class="{ darkbackground: whiteImage }"
    >
      <img
        class="propimage"
        :src="propButton.image"
        alt=""
        width="auto"
        height="auto"
        draggable="false"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PropButtons",
  props: ["propButton", "index", "whiteImage"],
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.propborder {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px !important;
  width: 14em;
}

.draggable {
  border: 0px;
}

.darkbackground {
  background-color: #afafaf;
  padding: 0.5em;
}

.propbutton {
  padding: 0 2em;
  height: 100px;
  width: 100%;
}

.propimage {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
</style>
