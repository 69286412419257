<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div class="dropdown-container">
    <!-- The selection dropdown for adding new events -->
    <div
      class="d-flex justify-content-center align-items-center mt-2 mx-4 mb-3"
    >
      <select v-model="eventOption" class="w-100 mx-2 no-focus-outline">
        <option
          v-for="(event, i) in isContactsPanel ? contactsList : eventButtons"
          :key="isContactsPanel ? event.id : event.type"
          :value="i"
          >{{
            isContactsPanel ? event.name : eventDictionary[event.type]
          }}</option
        >
      </select>
      <font-awesome-icon
        @click="addEvent(eventOption)"
        class="addEventIcon"
        icon="plus"
      />
    </div>
    <!-- The list of events as accordions -->
    <div id="accordion">
      <AccordionCard
        ref="accordions"
        v-for="(event, i) in events.filter(x => !x.hidden)"
        :key="i"
        :index="i"
        :event="event"
        :chapterEventData="chapterEventData"
        :isContactsPanel="isContactsPanel"
        @removeEvent="activateRemoveEventModal(event.event_id, event.name)"
        @closeOpenCard="closeOpenCard"
        @openDialogueEditor="$emit('openDialogueEditor', event.event_id)"
        @openConditionModal="$emit('openConditionModal', $event, event)"
        @addEvent="addEvent"
        @removeEventById="removeEvent"
      />
    </div>
    <!-- Modal to verify if the user wants to delete the event -->
    <VerifyModal
      v-show="showVerifyDeleteEventModal"
      @cancel-click="
        showVerifyDeleteEventModal = false;
        removeEventID = -1;
      "
      @ok-click="
        removeEvent(removeEventID);
        removeEventID = -1;
        removeEventName = '';
        showVerifyDeleteEventModal = false;
      "
      okButtonStyle="negative"
      cancelButtonStyle="neutral"
      okButtonText="Verwijder"
      cancelButtonText="Terug"
    >
      Weet je zeker dat je
      {{
        isContactsPanel
          ? "de '" + this.removeEventName + "' dialoog"
          : "'" + this.removeEventName + "'"
      }}
      wilt verwijderen?
    </VerifyModal>
  </div>
</template>

<script>
import AccordionCard from "./AccordionCard";
import VerifyModal from "../VerifyModal";
import EventDict from "../../util/EventEnumToName";

export default {
  name: "PropEditor",
  props: [
    "index",
    "events",
    "chapterEventData",
    "isContactsPanel",
    "contactsList"
  ],
  components: { AccordionCard, VerifyModal },
  methods: {
    /** Remove event handler */
    activateRemoveEventModal(index, name) {
      this.removeEventID = index;
      this.removeEventName = name;
      this.showVerifyDeleteEventModal = true;
    },
    /** Add a new event to the prop being edited */
    addEvent(eventIndex, hidden = false) {
      if (this.eventButtons == -1) return;
      let event = undefined;
      if (this.isContactsPanel) {
        const contact = this.contactsList[eventIndex];
        if (!contact) return; //If no contact has been selected, return          

        // Do not add this contact event if it has already been added to contactsList
        if (this.events.some(e => e.name == contact.name)) return;

        event = JSON.parse(JSON.stringify(this.contactEventTemplate));
        event.value = contact.id;
        event.name = contact.name;
      } else {
        //Set event data to the correct event without changing the original event template
        event = JSON.parse(JSON.stringify(this.eventButtons[eventIndex]));
        event.seconds = null;
      }
      event.condition = ["+", "0"]
      event.fk_prop_id = this.index;
      this.$emit("push-events", event);
    },
    /** Remove a specific event by index */
    removeEvent(eventId) {
      for (let i = 0; i < this.events.length; i++) {
        if (this.events[i].event_id == eventId) {
          this.$emit("splice-events", i);
        }
      }
    },
    /** Close all open cards */
    closeOpenCard(eventId) {
      for (const accordion of this.$refs.accordions) {
        if (accordion.event.event_id == eventId) continue;
        accordion.cardOpen = false;
      }
    }
  },
  data() {
    return {
      eventDictionary: EventDict,
      eventButtons: [
        {
          name: "Notitieblok",
          type: this.$ENUM_EVENTTYPE.NOTEBOOK,
          runType: this.$ENUM_EVENTRUNTYPE.CLICK,
          condition: ""
        },
        {
          name: "Dialoog",
          type: this.$ENUM_EVENTTYPE.DIALOGUE,
          runType: this.$ENUM_EVENTRUNTYPE.CLICK,
          condition: ""
        },
        {
          name: "Scene verandering",
          type: this.$ENUM_EVENTTYPE.CHANGESCENE,
          runType: this.$ENUM_EVENTRUNTYPE.CLICK,
          condition: "",
          change_scene_id: 0
        },
        {
          name: "Geluid",
          type: this.$ENUM_EVENTTYPE.SOUND,
          runType: this.$ENUM_EVENTRUNTYPE.CLICK,
          condition: ""
        },
        {
          name: "Verbergen",
          type: this.$ENUM_EVENTTYPE.HIDE,
          runType: this.$ENUM_EVENTRUNTYPE.CLICK,
          condition: ""
        },
        {
          name: "Tonen",
          type: this.$ENUM_EVENTTYPE.SHOW,
          runType: this.$ENUM_EVENTRUNTYPE.CONDITION,
          condition: ""
        },
        {
          name: "Minigame",
          type: this.$ENUM_EVENTTYPE.MINIGAME,
          runType: this.$ENUM_EVENTRUNTYPE.CLICK,
          conditional: ""
        }
      ],
      eventOption: this.isContactsPanel ? 0 : 2,
      showVerifyDeleteEventModal: false,
      removeEventID: -1,
      removeEventName: "",

      contactEventTemplate: {
        type: this.$ENUM_EVENTTYPE.DIALOGUE,
        runType: this.$ENUM_EVENTRUNTYPE.CLICK,
        conditional: "",
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.dropdown-container select {
  text-align: center;
  font-family: $default-font;
  display: inline-block;
  border-radius: 1em;
}

.addEventIcon:hover {
  color: $green;
  cursor: pointer;
}

.no-focus-outline:focus {
  outline: none;
}

#accordion {
  border-top: $basic-border;
}
</style>
