<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div
    class="login-screen d-flex flex-column align-items-center justify-content-center mt-4"
  >
    <div class="login-container">
      <h1 class="login-welcome pb-4">Welkom</h1>
      <form
        class="login-border row w-100 align-items-center justify-content-sm-center ml-1 px-3"
      >
        <div
          class="input-box d-flex flex-column justify-content-center my-2 ml-3 mr-4"
        >
          <input
            type="text"
            placeholder="gebruikersnaam"
            autocomplete="username"
            v-model="username"
            class="login-input login-email mb-2 mt-3"
          />
          <input
            type="password"
            placeholder="wachtwoord"
            autocomplete="current-password"
            v-model="password"
            class="login-input login-password mt-2 mb-3"
          />
        </div>
        <button
          class="login-button d-flex align-items-center justify-content-center mr-n5"
          @click="login"
        >
          <font-awesome-icon
            class="login-play ml-1"
            icon="play"
            @click="login"
          />
        </button>
      </form>

      <p class="login-error pt-3" v-if="error">
        <small>{{ error }}</small>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginForm",
  data() {
    return {
      username: "",
      password: "",
      error: ""
    };
  },
  methods: {
    /** login function */
    login(e) {
      e.preventDefault();
      const data = {
        username: this.username.toLowerCase(),
        password: this.password
      };
      this.$axios
        .post(process.env.VUE_APP_API_HOST + "/auth/login", data)
        .then(response => {
          this.$store.commit("setAccessToken", {
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken
          });
          this.$router.push({ name: "dashboard" });
        })
        .catch(() => {
          this.error = "Wachtwoord of gebruikersnaam verkeerd";
        });
    }
  },
  created() {
    this.$axios
      .get(process.env.VUE_APP_API_HOST + "/auth/loggedin", {})
      .then(response => {
        if (response.data.loggedin) {
          this.$router.push({ name: "dashboard" });
        }
      })
      .catch(() => {});
  }
};
</script>

<style lang="scss" scoped>
.login-welcome {
  font-family: $default-font;
  text-align: center;
}
.login-screen {
  min-height: 14em;
  height: calc(100vh - 7em);
}
.login-container {
  width: 100%;
  max-width: 23em;
  position: relative;
}
.login-border {
  display: flex;
  position: relative;
  // max-width: 100%;
  // max-height: 135px;
  background: white;
  box-shadow: $box-shadow-white;
  border-radius: 2em;
}
.input-box {
  width: calc(100% - 4em);
}
.login-input {
  position: relative;
  width: 100%;
  // width: 315px;
  height: 2.5em;
  // left: calc(50% - 315px / 2);
  // top: calc(50% - 35px / 2);
  background: #e0e0e0;
  border: 0px;
  box-shadow: inset 0px 0.2em 0.3em rgba(0, 0, 0, 0.25);
  border-radius: 2em;
  font-family: $default-font;
  color: $black;
  text-indent: 2em;
  &::placeholder {
    position: relative;
    font-family: $default-font;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9em;
    color: #afafaf;
  }
  &:focus {
    outline: unset;
    box-shadow: $box-shadow-focus;
  }
}
.login-button {
  position: relative;
  //right: -23.7em;//calc(-40px / 2);
  //top: -4.1em;//calc(135px / 2 - 40px / 2);
  height: 3em;
  width: 3em;
  border: 0px;
  border-radius: 50%;
  background: $black;
  box-shadow: 0px 0px 1em rgba(0, 0, 0, 0.25);
  &:focus {
    outline: unset;
    box-shadow: $box-shadow-focus;
  }
}
.login-play {
  width: 1.7em;
  height: 1.7em;
  color: #c7c7c7;
}
.login-error {
  font-family: $default-font;
  text-align: center;
  font-weight: bold;
  color: $red;
}
</style>
