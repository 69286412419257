var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loadNode)?_c('Moveable',_vm._b({ref:"moveable",staticClass:"node d-flex flex-column align-items-center justify-content-center",style:({
    transform:
      'matrix(1, 0, 0, 1, ' +
      _vm.chapter.corkboardX +
      ', ' +
      _vm.chapter.corkboardY +
      ')',
    left: _vm.chapter.corkboardX,
    top: _vm.chapter.corkboardY
  }),attrs:{"container":_vm.canvas,"scrollContainer":_vm.scrollCanvas,"id":_vm.id},on:{"drag":_vm.handleDrag,"dragEnd":_vm.handleEndDrag,"scroll":_vm.handleScroll}},'Moveable',_vm.moveable,false),[_c('div',{staticClass:"inputdot d-flex justify-content-center"},[_c('div',{ref:"inputDot",staticClass:"dot",on:{"mouseup":function($event){return _vm.$emit('selectInputNode', _vm.inputDot)}}})]),_c('span',{staticClass:"node-id pb-1 mx-4"},[_vm._v(" "+_vm._s(_vm.name))]),(_vm.image != null)?_c('div',[_c('img',{staticClass:"preview-image",attrs:{"src":_vm.image},on:{"load":function($event){_vm.updateDotPos();
        _vm.$refs.moveable.updateRect();}}})]):_c('div',[_c('img',{staticClass:"preview-image",attrs:{"src":require("../../assets/Props/Paper_Big.png")},on:{"load":function($event){_vm.updateDotPos();
        _vm.$refs.moveable.updateRect();}}})]),_c('div',{staticClass:"d-flex align-self-center justify-content-center mx-2"},[_c('button',{staticClass:"button add d-flex align-self-center justify-content-center mx-1",on:{"click":function($event){$event.stopPropagation();return _vm.addOutputNode.apply(null, arguments)}}},[_c('font-awesome-icon',{staticClass:"icon node-icon-add d-flex align-self-center ",attrs:{"icon":"plus"}})],1),_c('button',{staticClass:"button delete d-flex align-self-center justify-content-center mx-1",on:{"click":function($event){$event.stopPropagation();return _vm.deleteOutputNode.apply(null, arguments)}}},[_c('font-awesome-icon',{staticClass:"icon node-icon-delete d-flex align-self-center",attrs:{"icon":"minus"}})],1)]),_c('div',{staticClass:"scenedots row h-100 d-flex justify-content-center align-items-center"},_vm._l((_vm.outputNodes),function(dot,i){return _c('div',{key:i,ref:'dot_' + i,refInFor:true,staticClass:"d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"dot dotoutput",on:{"mousedown":function($event){$event.stopPropagation();return _vm.selectOutputNode(i)}}})])}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }