<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div class="outputdot d-flex justify-content-center">
    <div
      class="dot"
      ref="dotoutput"
      @mousedown.stop="$emit('createArrow', dotData)"
    ></div>
  </div>
</template>

<script>
export default {
  name: "NodeDot",
  props: ["dotData"],
  methods: {
    offsetDotPosition(dialogueNode) {
      return {
        x:
          this.$refs.dotoutput?.offsetLeft +
          this.$refs.dotoutput?.getBoundingClientRect().width / 1.25,
        y:
          dialogueNode.getBoundingClientRect().height -
          this.$refs.dotoutput?.getBoundingClientRect().height / 2
      };
    }
  },
  mounted() {
    this.$emit("updateOutputDotPositions");
  },
  destroyed() {
    this.$emit("updateOutputDotPositions");
  }
};
</script>

<style lang="scss" scoped>
.dot {
  width: 0.6em;
  height: 0.6em;
  background-color: $black;
  border-radius: 100%;
  margin: 0.13em;

  &:hover {
    background-color: rgb(0, 120, 240);
  }
}

.outputdot {
  position: absolute;
  bottom: 0%;
  width: 100%;
}
</style>
