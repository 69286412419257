<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div class="dialogue-editor d-flex flex-column w-100 h-100">
    <ToastMessage
      :message="'Opgeslagen!'"
      :id="'saveToast'"
      ref="saveToast"
      v-if="saveVisible"
      :close="
        () => {
          saveVisible = false;
        }
      "
      :closeTimeout="2000"
    />
    <ToastMessage
      :message="errorMessage"
      v-if="errorVisible"
      :id="'errorToast'"
      :error="true"
      :close="
        () => {
          errorVisible = false;
        }
      "
      :closeTimeout="errorTime"
    />

    <HeaderRow
      :editable="!isContact"
      :prefix="'Dialoog'"
      :name="this.dialogueName"
      :saving="saving"
      :showTitle="true"
      @navigate="navigateToScene"
      @save="saveDialogueTree"
      @remove="showVerifyDeleteDialogueModal = true"
      @updateName="editEventTitle"
    />

    <div class="canvas-container px-5 py-4 d-flex w-100 h-100">
      <div
        class="canvas-scroll mr-3 d-flex"
        id="canvas-scroll"
        ref="canvasScroll"
      >
        <div class="canvas" id="canvas" ref="canvas">
          <DialogueNode
            v-for="node in dialogueNodes"
            :ref="'dialogue_' + node.id"
            :key="node.id"
            :node="node"
            :canvas="$refs.canvas"
            :canvasScroll="$refs.canvasScroll"
            @openEditPanel="
              editPanelOpen = true;
              selectedNode = node;
            "
            @nodeUpdate="updateNodePosition"
            @createArrow="createLine"
            @selectInputDot="connectLine"
            @setPosition="
              node.position.x = $event.x;
              node.position.y = $event.y;
            "
            @setDotPosition="
              node.outputDots[$event.index].position = $event.position
            "
            @loadDialogueLine="loadDialogueLine"
            @addDot="createDot"
            :overlaps="rect => overlapsNode(rect, node.id)"
            :selected="selectedNode == node"
          />
          <svg id="mySVG">
            <DialogueLine
              v-for="(line, i) in lines"
              :key="i"
              :lineData="line"
              :canvas="$refs.canvas"
              :mousePosition="mousePosition"
              :selectedLine="selectedLine"
              @deleteLine="deleteLine"
            />
          </svg>
        </div>
      </div>

      <DialoguePanel
        ref="dialoguePanel"
        :showEditPanel="editPanelOpen"
        :character="character"
        :skills="skills"
        :chapterEventData="events"
        :selectedNode="selectedNode"
        @printErrorMessage="printErrorMessage"
        @add-dialogue="addDialogueNode"
        @deleteNode="deleteSelectedNode"
        @addDot="createDot"
        @deleteDot="deleteDot"
        @openConditionModal="openConditionModal"
        @loadCurrentDialogueEvents="loadCurrentDialogueEvents"
        @addSkill="skill => addSkillToNode(selectedNode, skill)"
        @removeSkill="index => removeSkillFromNode(selectedNode, index)"
      />

      <!-- CONDITION selection-->
      <ConditionModal
        ref="conditionModal"
        @selectedCondition="conditionModalEventSelected"
        :condIndex="conditionIndex"
        :treeID="1"
        :nodes="treeData"
        :events="events"
      />
    </div>
    <VerifyModal
      v-show="showVerifyDeleteDialogueModal"
      @cancel-click="showVerifyDeleteDialogueModal = false"
      @ok-click="
        deleteDialogue();
        showVerifyDeleteDialogueModal = false;
      "
      okButtonStyle="negative"
      cancelButtonStyle="neutral"
      okButtonText="Verwijder"
      cancelButtonText="Terug"
    >
      Weet je zeker dat je '{{ dialogueName }}' wilt verwijderen?
    </VerifyModal>
    <!-- Component to show the unsaved changes menu -->
    <VerifyModal
      v-show="showVerifyUnsavedModal"
      @cancel-click="
        currentNextFunction(false);
        showVerifyUnsavedModal = false;
      "
      @ok-click="
        currentNextFunction();
        showVerifyUnsavedModal = false;
      "
      okButtonStyle="negative"
      cancelButtonStyle="positive"
      okButtonText="Verlaat pagina"
      cancelButtonText="Blijf hier"
    >
      Weet je zeker dat je deze pagina wilt verlaten zonder op te slaan?
    </VerifyModal>
  </div>
</template>

<script>
import HeaderRow from "../components/HeaderRow";
import ToastMessage from "../components/layout/ToastMessage";
import VerifyModal from "../components/VerifyModal";
import ConditionModal from "../components/ConditionModal";
import DialogueNode from "../components/DialogueEditor/DialogueNode";
import DialoguePanel from "../components/DialogueEditor/DialoguePanel";
import DialogueLine from "../components/DialogueEditor/DialogueLine";

export default {
  name: "DialogueEditor",
  components: {
    HeaderRow,
    ToastMessage,
    ConditionModal,
    DialogueNode,
    DialoguePanel,
    DialogueLine,
    VerifyModal
  },
  data() {
    return {
      caseId: 0,
      sceneId: 0,
      eventId: 0,
      dialogueName: "",
      dialogueNodes: {},
      events: {},
      lines: [],
      lineData: [],
      unsavedChanges: false,
      showVerifyUnsavedModal: false,
      currentNextFunction: function() {},
      selectedNode: null,
      selectedLine: null,
      showVerifyDeleteDialogueModal: false,
      saving: false,
      editPanelOpen: false,
      mousePosition: {
        x: 0,
        y: 0
      },
      newIdCounter: 0,
      skills: [],
      errorMessage: "",
      errorTime: 2000,

      treeData: [
        { text: "Scene evenementen", id: "A" },
        { text: "Deze dialoog", id: "B" }
      ],
      conditionIndex: 0,
      modalDotButton: {},

      isContact: false,
      errorVisible: false,
      saveVisible: false
    };
  },
  methods: {
    /** Generic get parameter by name from url */
    saveDialogueTree() {
      return this.$axios
        .post(process.env.VUE_APP_API_HOST + "/dialogue/save", {
          eventId: this.eventId,
          nodes: this.dialogueNodes,
          lines: this.lines
        })
        .then(() => {
          this.saving = false;
          this.saveVisible = true;
          this.$router.go();
        })
        .catch(errors => {
          console.error(errors);
        });
    },

    deleteDialogue() {
      return this.$axios
        .delete(
          process.env.VUE_APP_API_HOST + "/dialogue/delete/" + this.eventId
        )
        .then(() => {
          this.navigateToScene();
          this.$router.go();
        })
        .catch(async errors => {
          if (
            errors?.response?.status == 401 ||
            errors?.response?.status == 403
          ) {
            const success = await this.getNewAccessToken();
            if (success) {
              this.deleteDialogue();
            }
          }
          console.log("something went wrong");
        });
    },

    /** Navigate back to previous scene */
    navigateToScene() {
      this.$router
        .push({
          name: "sceneeditor",
          query: {
            case: this.$route.query["case"],
            chapter: this.caseId,
            scene: this.sceneId
          }
        })
        .catch(errors => {
          if (errors?.type != 4)
            //type 4 is manually aborted in guard
            console.log("navigation went wrong", errors);
        });
    },
    /** Get dialogue data */
    getDialogue() {
      this.caseId = this.$route.query["chapter"];
      this.sceneId = this.$route.query["scene"];
      this.eventId = this.$route.query["eventId"];

      this.getDialogueData().finally(() => {
        this.unsavedChanges = false;
      });
    },

    //Get the data needed from the database
    getDialogueData() {
      return this.$axios
        .get(process.env.VUE_APP_API_HOST + "/dialogue/get/info/" + this.eventId)
        .then(response => {
          const nodeSkillInfo = response.data[4]; //The skills for each node
          this.dialogueName = response.data[2].name;
          let startLoaded = false; //If the start node has been loaded
          const nodeData = response.data[0];
          nodeData.forEach(node => {
            if (node.type == "Start") {
              startLoaded = true;
            }
            this.loadDialogueNode(node, nodeSkillInfo); //Load a dialogue node for each dialogue node
          });
          this.lineData = response.data[1]; //Set the line data to be handled when all dot positions are computed
          if (!startLoaded)
            //If the start node has yet to be loaded, load it in.
            this.addDialogueNode("Start");

          this.loadSkills();
          // Set whether this dialogue is from a contact, in which case the prop name will be equal to "contactsProp"
          this.isContact = response.data[3].name == "contactsProp";
        })
        .catch(async () => {
          console.log(
            "Receiving dialoguetree data from the database failed as the process ran into an error"
          );
          this.navigateToScene(); //If it errors, go back to scene editor
        });
    },

    //Load the skills from the database
    loadSkills() {
      return this.$axios
        .get(process.env.VUE_APP_API_HOST + "/settings/get/attributes/skill")
        .then(response => {
          this.skills = response.data;
        })
        .catch(errors => {
          console.log(
            "Receiving dialoguetree data from the database failed as the process ran into an error",
            errors
          );
        });
    },

    //loads all the events from the database that can be used in a condiion
    getEvents() {
      return this.$axios
        .get(process.env.VUE_APP_API_HOST + "/chapter/get/chapter_events/" + this.$route.query["chapter"])
        .then(response => {
          const eventData = response.data;
          eventData.forEach(event => {
            this.events[event.event_id] = {
              name: event.name,
              eventType: event.type,
              dialogue: {}
            };
          });
          this.getDialogueEvents();
        });
    },

    //a continuation of getEvents, but for the nodes in dialogue trees
    getDialogueEvents() {
      return this.$axios
        .get(process.env.VUE_APP_API_HOST + "/dialogue/get/trees/" + this.$route.query["chapter"])
        .then(response => {
          const dialogueData = response.data;
          dialogueData.forEach(event => {
            this.events[event.event_id].dialogue[event.dialogue_id] = {
              name: event.name,
              value: event.value,
              type: event.type,
              portrait: event.portrait
            };
          });
        });
    },

    /** Start editing the scene title */
    editEventTitle(name) {
      if (name && !this.isContact) {
        name = name.trim();

        this.dialogueName = name;
        this.updateName();
      }
    },

    /** Save / update the scene title */
    updateName() {
      if (!this.isContact)
        return this.$axios
          .post(process.env.VUE_APP_API_HOST + "/dialogue/update/name", {
            eventId: this.eventId,
            name: this.dialogueName
          })
          .catch(errors => {
            console.error(errors);
          });
    },

    /**Delete a dialogue node */
    deleteSelectedNode() {
      this.editPanelOpen = false; //Deactivate the panel

      //Delete all lines connecting to this node and from this node
      this.lines.forEach(line => {
        if (
          line.sourceNode.id == this.selectedNode.id ||
          line.targetNode.id == this.selectedNode.id
        )
          this.deleteLine(line, true);
      });

      delete this.dialogueNodes[this.selectedNode.id];
      this.selectedNode = null;
    },

    /**Create a dialogue node based on the type*/
    addDialogueNode(dialogueType) {
      const newId = "new".concat(this.newIdCounter++);
      const dialogueNode = {
        id: newId,
        text: "",
        position: {
          x: this.$refs.canvasScroll.scrollLeft,
          y: this.$refs.canvasScroll.scrollTop
        },
        type: dialogueType,
        portrait: "Nothing",
        inputDotPosition: { x: 0, y: 0 },
        outputDots: [],
        loaded: false
      }; //Set all the data needed in DialogueNode

      if (dialogueNode.type == "Option")
        //If it's an option dialogue, initialize the skills array
        dialogueNode.skills = [];
      const newNodes = {};
      newNodes[newId] = dialogueNode;
      this.dialogueNodes = Object.assign({}, this.dialogueNodes, newNodes);

      return dialogueNode;
    },

    /**Create a dialogue node */
    loadDialogueNode(data, skillInfo) {
      const dialogueNode = {
        id: data.dialogue_id,
        text: data.value,
        position: { x: data.x, y: data.y },
        type: data.type,
        portrait: data.portrait,
        inputDotPosition: { x: 0, y: 0 },
        outputDots: [],
        characterName: data.character_name ? data.character_name : undefined,
        loaded: true
      }; //Set all the data needed in DialogueNode

      if (data.dialogue_id >= this.newIdCounter)
        //Set the newIdCounter to the maximum value of all loaded nodes to prevent overlap
        this.newIdCounter = data.dialogue_id + 1;

      //Check if there are any skills for this node
      const skillNodes = skillInfo.filter(
        skill => skill.fk_dialogue_id == dialogueNode.id
      );

      if (dialogueNode.type == "Option") {
        //If it's an option dialogue, initialize the skills array
        dialogueNode.feedbackText = data.feedback_text; //Load the feedback text from the database
        dialogueNode.skills = [];
        if (skillNodes.length != 0)
          //Set the skill for the dialogueNode
          skillNodes.forEach(skill =>
            dialogueNode.skills.push({
              id: skill.score_id,
              skillId: skill.fk_skill_id,
              score: skill.score,
              name: skill.value
            })
          );
      }

      const outputDotCount = data.output_count;
      for (let index = 0; index < outputDotCount; index++) {
        this.loadDots(dialogueNode);
      }
      const newNodes = {};
      newNodes[data.dialogue_id] = dialogueNode;
      this.dialogueNodes = Object.assign({}, this.dialogueNodes, newNodes);

      return dialogueNode;
    },

    //Load line from database
    loadDialogueLine() {
      this.lineData.forEach(data => {
        if (data.loaded) {
          return;
        }

        let dotData = {
          //set all dot data
          conditionId: data.condition_Id,
          conditionString: data.conditions,
          dotNr: data.dot_nr,
          fromNodeId: data.fk_node_id_1,
          toNodeId: data.fk_node_id_2
        };
        const node1 = this.dialogueNodes[dotData.fromNodeId];
        const node2 = this.dialogueNodes[dotData.toNodeId];
        let outputDot = this.dialogueNodes[dotData.fromNodeId].outputDots[
          dotData.dotNr
        ];
        if (typeof outputDot === "undefined") {
          return;
        }
        outputDot.position = this.dialogueNodes[dotData.fromNodeId].outputDots[
          dotData.dotNr
        ].position;
        if (
          typeof outputDot.position === "undefined" ||
          typeof node2.inputDotPosition === "undefined"
        ) {
          return;
        }
        if (dotData.conditionString != null) {
          //If there are conditions from the database
          const conditions = dotData.conditionString.split("/");
          while (
            ["", "A", "O", "-", "+"].includes(conditions[conditions.length - 1]) //remove unwanted characters from the end of the array
          )
            conditions.splice(-1, 1);
          if (conditions && conditions.length > 1) {
            conditions.forEach(condition => {
              outputDot.condition.push(condition);
            });
          } else {
            outputDot.condition = ["+", "0"];
          }
        } else {
          outputDot.condition = ["+", "0"]; //Else initialize a default condition
        }
        outputDot.connected = true;
        const line = {
          id: data.condition_id,
          sourceNode: node1,
          targetNode: node2,
          linePosition: {
            x1: outputDot.position.x,
            y1: outputDot.position.y,
            x2: node2.inputDotPosition.x,
            y2: node2.inputDotPosition.y
          },
          sourceDot: outputDot
        };
        data.loaded = true;
        this.lines.push(line);
      });
    },

    /**Create a dialogue node line */
    createLine(node, nodeDot) {
      this.lines.forEach(l => {
        //If a line already connects from this node delete the existing line
        if (l.sourceNode.id == node.id && l.sourceDot.index == nodeDot.index) {
          this.deleteLine(l, true);
        }
      });

      //Set the data for the line, sourceNode and targetNode are object references
      const line = {
        id: "new".concat(this.newIdCounter++), //New keyword for saving
        sourceNode: node,
        targetNode: null,
        sourceDot: nodeDot
      };
      this.selectedLine = line;
      this.lines.push(line);
    },

    /**Delete a dialogue node line on mouse up */
    deleteLine(line) {
      if (line.targetNode) {
        //If there's a target node, that means we also have to delete the parent/child relationships
        line.sourceDot.connected = false;
      }
      this.lines = this.lines.filter(l => l.id !== line.id);

      this.selectedLine = null;
    },

    /**Connect a dialogue node line with a node*/
    connectLine(targetNode) {
      if (this.selectedLine) {
        //If selectedLine is not null, connect the two nodes
        if (targetNode.id == this.selectedLine.sourceNode.id) {
          //If you try connecting the node with itself
          this.printErrorMessage(
            "Dialoog blokken kunnen niet aan zichzelf verbinden",
            3000
          );
          this.deleteLine(this.selectedLine);
          return;
        }
        if (
          this.selectedLine.sourceNode.type == "Choice" &&
          targetNode.type != "Option"
        ) {
          //If you try connecting the a choice node with a non option node
          this.printErrorMessage(
            "Keuzemomenten kan alleen verbonden worden aan de optie dialoog blokken",
            3000
          );
          this.deleteLine(this.selectedLine);
          return;
        }
        if (
          this.selectedLine.sourceNode.type != "Choice" &&
          targetNode.type == "Option"
        ) {
          //If you try connecting an option node with a non choice node
          this.printErrorMessage(
            "Opties kunnen alleen verbonden worden aan keuzemomenten",
            3000
          );
          this.deleteLine(this.selectedLine);
          return;
        }
        this.selectedLine.sourceDot.connected = true;
        this.selectedLine.targetNode = targetNode; //Set the target node of the selected line
        this.selectedLine = null; //Reset the selectedLine
      }
    },

    /**Update Node position */
    updateNodePosition(position, node) {
      //This can be handled in DialogueNode if that is preferred.
      node.position.x = position.left;
      node.position.y = position.top;
    },

    //Create a dot for a node
    createDot(node) {
      if (node.outputDots.length >= 8) return;

      node.outputDots.push({
        index: node.outputDots.length,
        connected: false,
        condition: ["+", " "]
      });
    },

    //Load dots
    loadDots(node) {
      node.outputDots.push({
        index: node.outputDots.length,
        connected: false,
        condition: []
      });
    },

    //Delete a specific dot of a node
    deleteDot(node, dot) {
      if (dot.connected) {
        //If this dot has a connected line, delete the line
        this.lines.forEach(line => {
          if (line.sourceDot == dot) {
            this.deleteLine(line, true);
          }
        });
      }
      node.outputDots.splice(dot.index, 1);
      node.outputDots.map(current => {
        if (current.index > dot.index) current.index--;
      }); /** Lowers every index above the deleted index */
    },
    /** Calculate overlap for two rectangles */
    conditionCheckPositions(rect, otherRect) {
      const otherNodeWidth = otherRect.width;
      const otherNodeHeight = otherRect.height;
      const maxWidthNode = rect.left + rect.width;
      const maxHeightNode = rect.top + rect.height;
      const maxWidthOtherNode = otherRect.left + otherNodeWidth;

      return (
        otherRect.left <= maxWidthNode &&
        maxWidthOtherNode >= rect.left &&
        otherRect.top <= maxHeightNode &&
        otherNodeHeight + otherRect.top >= rect.top
      );
    },
    /** Checks for each case node if there is any overlap with the given rect */
    checkPositionsDialogueNode(rect, sceneid) {
      let overlapping = false;

      for (var key in this.dialogueNodes) {
        if (this.dialogueNodes[key].id != sceneid) {
          const otherNode = this.$refs[
            "dialogue_" + this.dialogueNodes[key].id
          ][0].$refs.moveable.getRect();
          overlapping = this.conditionCheckPositions(rect, otherNode);
        }

        if (overlapping) {
          return true;
        }
      }
      return false;
    },
    /** Checks for the given rect if it overlaps with anything */
    overlapsNode(rect, sceneid) {
      return this.checkPositionsDialogueNode(rect, sceneid);
    },

    //Add an empty skill slot to the node
    addSkillToNode(node, skill) {
      if (!skill)
        //Don't add an empty skill
        return;
      //If the skill has already been added to the node, don't allow it to add again
      if (node.skills.some(e => e.name == skill.name)) {
        return;
      }
      //Use the new keyword for the id for saving later.
      const skillTuple = {
        id: "new".concat(this.newIdCounter++),
        skillId: skill.id,
        score: 0,
        name: skill.name
      };
      node.skills.push(skillTuple); //The order doesn't matter in skills so insert as the last one
    },

    //Remove skill slot from a node
    removeSkillFromNode(node, index) {
      node.skills.splice(index, 1);
    },

    printErrorMessage(msg, time = 2000) {
      //Print an message to the user with a time in ms
      this.errorMessage = msg;
      this.errorTime = time;
      this.errorVisible = true;
    },
    handleMouse(event) {
      this.mousePosition = { x: event.pageX, y: event.pageY }; //The mouse position
    },

    //Set the tree data needed from the current dialogue editor for the conditions menu
    loadCurrentDialogueEvents() {
      this.$refs["conditionModal"].clearTreeChildren("B"); //Clear all data from the parent
      const nodes = this.dialogueNodes;
      for (const key in nodes) {
        const node = nodes[key];
        this.events[this.eventId].dialogue[node.id] = {
          value: node.text,
          name: this.dialogueName,
          type: node.type,
          portrait: node.portrait
        };
        this.$refs["conditionModal"].insertTreeData("B", {
          text: node.id + ": " + this.getDialogueDisplayString(this.eventId, node.id),
          id: "B" + this.eventId + "." + node.id
        });
      }
      this.$nextTick(
        this.$refs["conditionModal"].$refs["conditionTree"].$forceUpdate()
      );
    },
    //Convert the condition id into a suitable string for displaying information in the event explorer.
    getDialogueDisplayString(eventId, dialogueId) {
      if (!this.events) return;
      
      let condData = this.events[eventId];
      if (!condData)
        return;
      
      condData = condData.dialogue[dialogueId];
      let displayString = "";
      if (condData.type == "Start") //Start node
        return displayString + "Start=>";
      else if (condData.type == "Choice") //Choice node
        return displayString + "<Choice>";
      else if (condData.type == "Option") //Option node
        return displayString + " > " + this.quoteString(condData.value);
      else if (condData.type == "Player" || condData.portrait != "Nothing") //Said by someone
        return displayString + " \"" + this.quoteString(condData.value) + "\"";
      else //Else it's narrative text
        return displayString + " \'" + this.quoteString(condData.value) + "\'";
    },
    quoteString(sentence){ //Shorten a string to its first and last three words
      const words = sentence.split(" ");
      if (words.length > 6)
        return words[0] + " " + words[1] + " " + words[2] + " ... " + words[words.length - 3] + " " + words[words.length - 2] + " " + words[words.length - 1];
      else
        return sentence;
    },
    //When the condition button is clicked, open the condition selection menu
    openConditionModal(dotButton, index) {
      this.conditionIndex = index;
      this.modalDotButton = dotButton;
      this.$refs["conditionModal"].toggleConditionPanel(this.eventId);
    },

    //When a condition is selected in the condition selection menu
    conditionModalEventSelected(conditionId, conditionIndex) {
      this.$set(this.modalDotButton.condition, conditionIndex, conditionId);
    }
  },
  computed: {
    character() {
      if (this.selectedNode) return this.selectedNode.portrait;
      else return "Nothing";
    }
  },
  created() {
    this.getDialogue();
    this.getEvents();
    document.addEventListener("mousedown", this.handleMouse);
    document.addEventListener("mousemove", this.handleMouse);
    // Set to no unsaved changes after all initial setup has been handled
    setTimeout(() => {
      this.unsavedChanges = false;
    }, 100);
  },
  destroyed() {
    document.removeEventListener("mousedown", this.handleMouse);
    document.removeEventListener("mousemove", this.handleMouse);
  },
  watch: {
    lines: {
      handler() {
        this.unsavedChanges = true;
      },
      deep: true
    },
    dialogueNodes: {
      handler() {
        this.unsavedChanges = true;
      },
      deep: true
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.unsavedChanges) {
      next();
    } else {
      this.currentNextFunction = next;
      this.showVerifyUnsavedModal = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.dialogue-editor {
  min-width: max-content;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.header-row {
  display: flex;
  margin-right: 6em;
  margin-left: 6em;
  margin-top: 2em;
  margin-bottom: 2em;
  height: 3rem;
}
.header-check {
  margin-left: 2em;
  &:hover {
    color: $green;
  }
}
.canvas-container {
  overflow: hidden;
  position: relative;
}
.canvas {
  position: absolute;
  width: 2000px;
  height: 3000px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
}
.canvas-scroll {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: $box-shadow-white;
  border-radius: 2em;
  overflow: scroll;
}

#mySVG {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
#errorToast,
#saveToast {
  pointer-events: none;
}
</style>
