<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div>
    <HeaderRow :showTitle="false" @navigate="goBack" @save="saveAttributes" />
    <div class="generalsettings d-flex flex-column mx-5 my-3">
      <AttributeOverview
        :attributes="skills"
        :attributeType="'skills'"
        @addAttribute="addAttribute(skills)"
        @removeAttribute="skills = removeAttribute(skills, $event)"
      />
      <AttributeOverview
        :attributes="contacts"
        :attributeType="'contacts'"
        @addAttribute="addAttribute(contacts)"
        @removeAttribute="contacts = removeAttribute(contacts, $event)"
      />
    </div>
    <!-- Component to show the unsaved changes menu -->
    <VerifyModal
      v-show="showVerifyUnsavedModal"
      @cancel-click="
        currentNextFunction(false);
        showVerifyUnsavedModal = false;
      "
      @ok-click="
        currentNextFunction();
        showVerifyUnsavedModal = false;
      "
      okButtonStyle="negative"
      cancelButtonStyle="positive"
      okButtonText="Verlaat pagina"
      cancelButtonText="Blijf hier"
    >
      Weet je zeker dat je deze pagina wilt verlaten zonder op te slaan?
    </VerifyModal>
  </div>
</template>

<script>
import AttributeOverview from "../components/GeneralSettings/AttributeOverview";
import HeaderRow from "../components/HeaderRow";
import VerifyModal from "../components/VerifyModal";

export default {
  components: {
    AttributeOverview,
    HeaderRow,
    VerifyModal
  },
  data() {
    return {
      skills: [],
      contacts: [],
      newIdCounter: 0,
      unsavedChanges: false,
      showVerifyUnsavedModal: false,
      currentNextFunction: function() {}
    };
  },
  methods: {
    //Add skill slot
    addAttribute(attribute) {
      attribute.push({ id: "new".concat(this.newIdCounter++), name: "" });
    },
    //Remove skill slot
    removeAttribute(attribute, id) {
      return attribute.filter(attribute => attribute.id != id);
    },

    //Save settings
    saveAttributes() {
      this.$axios
        .post(process.env.VUE_APP_API_HOST + "/settings/save/attributes", {
          skills: this.skills,
          contacts: this.contacts
        })
        .then(() => this.$router.go())
        .catch(async errors => {
          console.log("something went wrong with saving attributes: " + errors);
        });
    },

    loadAttributes() {
      this.$axios
        .get(process.env.VUE_APP_API_HOST + "/settings/get/attributes/all")
        .then(response => {
          const attributes = response.data;
          attributes.forEach(a => {
            const type = a.type;
            delete a.type;
            if (type == "skill") this.skills.push(a);
            else this.contacts.push(a);
          });
        })
        .catch(async errors => {
          console.log("something went wrong with saving attributes: " + errors);
        })
        .finally(() => (this.unsavedChanges = false));
    },

    /** Go back one page*/
    goBack() {
      this.$router
        .push({
          name: "dashboard"
        })
        .catch(errors => {
          if (errors?.type != 4)
            //type 4 is manually aborted in guard
            console.log("navigation went wrong", errors);
        });
    }
  },
  mounted() {
    this.loadAttributes();
  },
  watch: {
    skills: {
      handler() {
        this.unsavedChanges = true;
      },
      deep: true
    },
    contacts: {
      handler() {
        this.unsavedChanges = true;
      },
      deep: true
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.unsavedChanges) {
      next();
    } else {
      this.currentNextFunction = next;
      this.showVerifyUnsavedModal = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.generalsettings {
  box-shadow: $box-shadow-white;
  border-radius: 2em;
  overflow: hidden;
}
</style>
