<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div class="d-flex flex-column mx-3">
    <!--Start Buttons-->
    <div
      class="d-flex justify-content-center flex-column dialogue-buttons-button-container"
    >
      <button
        class="mb-2 dialogue-buttons-button dialogue-buttons-button-addplayerdialogue p-3"
        @click="$emit('add-dialogue', 'Player')"
      >
        Speler Dialoog
      </button>
      <button
        class="mb-2 dialogue-buttons-button dialogue-buttons-button-addnonplayerdialogue p-3"
        @click="$emit('add-dialogue', 'NonPlayer')"
      >
        Non Speler Dialoog
      </button>

      <button
        class="mb-2 dialogue-buttons-button dialogue-buttons-button-addchoice p-3"
        @click="$emit('add-dialogue', 'Choice')"
      >
        Keuze Moment
      </button>

      <button
        class="mb-2 dialogue-buttons-button dialogue-buttons-button-addoption p-3"
        @click="$emit('add-dialogue', 'Option')"
      >
        Optie Dialoog
      </button>
    </div>
    <!--End Buttons-->

    <!--Icon Selection Pop-up-->
    <div v-if="selectDialogueIcon" class="modal-backdrop">
      <ModalPage>
        <template v-slot:header>
          <b class=" ml-auto"><h4>Selecteer een karakter</h4></b>
          <font-awesome-icon
            @click="toggleSelectDialogueIcon"
            class="close-icon ml-auto"
            icon="times"
          />
        </template>
        <template v-slot:body>
          <div class="row mx-5 mt-4">
            <div
              class="col-3 d-flex justify-content-center align-items-center py-4"
              @click="selectCharacter('Nothing')"
            >
              <div
                class="image-container d-flex justify-content-center align-items-center"
              >
                <font-awesome-icon
                  style="width:4em;height:4em;"
                  icon="ban"
                  class="p-1 character-image"
                  :class="{
                    selectedCharacter: 'Nothing' == selectedNode.portrait
                  }"
                />
              </div>
            </div>
            <div
              v-for="character in characters"
              :key="character.id"
              class="col-3 d-flex justify-content-center align-items-center py-4"
              @click="selectCharacter(character.name)"
            >
              <div
                class="image-container d-flex justify-content-center align-items-center"
              >
                <img
                  :src="character.image"
                  :alt="character.id"
                  class="p-1 character-image"
                  :class="{
                    selectedCharacter: character.name == selectedNode.portrait
                  }"
                />
              </div>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <button class="button mx-auto" @click="toggleSelectDialogueIcon">
            Opslaan
          </button>
        </template>
      </ModalPage>
    </div>

    <!--Show container if showEditPanel is true-->
    <div
      v-if="showEditPanel && selectedNode.type != 'Start'"
      class="dialogue-buttons-container pb-4 "
    >
      <div class="panel-buttons d-flex">
        <!-- Character button -->
        <button
          v-if="selectedNode.type == 'NonPlayer'"
          @click="toggleSelectDialogueIcon"
          class="prop-button mx-2 mt-2"
        >
          <font-awesome-icon
            v-if="selectedNode.portrait == 'Nothing'"
            icon="ban"
            class="ban-icon"
          />
          <img
            v-else
            class="prop-icon"
            :src="
              require('../../assets/Props/' + selectedNode.portrait + '.png')
            "
          />
        </button>
        <!-- Delete button -->
        <button
          :class="selectedNode.type == 'NonPlayer' ? 'trash-icon ml-auto mr-3 mt-2' : 'trash-icon ml-auto mr-3 mt-1'"
          @click="$emit('deleteNode')"
        >
          <font-awesome-icon icon="trash" />
        </button>
      </div>

      <!--Text Field-->
      <div v-if="selectedNode.type != 'Choice'">
        <span class="dialogue-buttons-header border-bottom mb-2 mt-2 pb-2">
          Tekst:
        </span>

        <div class="mx-3">
          <!--Character Name-->
          <input v-if="selectedNode.type == 'NonPlayer'"
            v-model="selectedNode.characterName"
            class="character-name"
            autocomplete="off"
            :maxlength="25"
            placeholder="Naam"
          />
          <textarea
            style="resize: none"
            class="form-control"
            :class="{
              'textbox-with-name':selectedNode.type == 'NonPlayer',
              textbox:selectedNode.type != 'NonPlayer',
            }"
            rows="4"
            v-model="selectedNode.text"
            placeholder="Tekst..."
          />
        </div>
      </div>

      <div class="dialogue-buttons-header border-bottom mt-2 pb-2">
        <div class="mx-auto">Nieuwe optie:
        <font-awesome-icon
          @click="$emit('addDot', selectedNode)"
          icon="plus"
          class="add-icon"
        /></div>
      </div>
      <div class="d-block">
        <div v-for="(dotButton, i) in selectedNode.outputDots" :key="i" class="d-flex justify-content-center mx-1 mt-1 pb-2 border-bottom">
          <div class="d-inline">
            {{dotButton.index + 1}}:
          </div>
          <div class="width-82 d-inline">
            <div v-for="(condition, j) in dotButton.condition" :key="j">
              <ConditionDropdown
                v-if="!isNaN(parseInt(condition)) || condition == ' '"
                :condition="condition"
                :andOrOperator="dotButton.condition[j+1]"
                :negatedIcon="dotButton.condition[j-1] == '-'"
                :index="j"
                :chapterEventData="chapterEventData"

                @onConditionButton="
                  $emit('openConditionModal', dotButton, $event)
                "
                @removeCondition="removeCondition(dotButton, $event)"
                @addCondition="addCondition(dotButton, $event)"
                @toggleNegation="
                  (index, operator) =>
                    toggleNegation(dotButton, index, operator)
                "
                @switchOperator="
                  (index, operator) =>
                    switchOperator(dotButton, index, operator)
                "
                />
            </div>
          </div>
          <div class="d-inline">
            <font-awesome-icon
              @click="$emit('deleteDot', selectedNode, dotButton)"
              icon="times"
              class="cross-icon"
            />
          </div>
        </div>  
      </div>
      <!--Skills menu-->
      <div v-if="selectedNode.type == 'Option'" class="w-100">
        <span class="dialogue-buttons-header border-bottom mb-2">
          Vaardigheden:
        </span>

        <!--Create the dropdown with all available skills-->
        <div
          class="d-flex justify-content-center align-items-center mt-2 mx-4 mb-3"
        >
          <select v-model="skillOption" class="w-100 mx-2">
            <option v-for="(skill, i) in skills" :key="i" :value="skill">{{
              skill.name
            }}</option>
          </select>
          <font-awesome-icon
            @click="$emit('addSkill', skillOption)"
            class="add-icon"
            icon="plus"
          />
        </div>

        <!--Skill input field-->
        <div
          v-for="(skill, i) in selectedNode.skills"
          :key="i"
          class="skills-container w-100 mr-4 my-1 d-flex align-items-center float-right"
        >
          <div class="mr-1 ml-auto d-flex skill-name">{{ skill.name }}:</div>
          <!--Todo: change alignment with longer skill names-->
          <!--Create a number input field for skill scores that only allows scores between -100 and 100-->
          <input
            class="skill-inputfield"
            placeholder="0"
            type="number"
            autocomplete="off"
            v-model="selectedNode.skills[i].score"
            @change="
              selectedNode.skills[i].score = Math.min(
                Math.max($event.target.value, -100),
                100
              )
            "
          />
          <font-awesome-icon
            @click="$emit('removeSkill', i)"
            icon="times"
            class="cross-icon"
          />
        </div>
      </div>
      <!--Text Field-->
      <div v-if="selectedNode.type == 'Option'">
        <span class="dialogue-buttons-header border-bottom border-top mb-2 mt-2 pb-2">
          Feedback:
        </span>

        <div class="mx-3">
          <textarea
            style="resize: none"
            class="form-control"
            rows="4"
            v-model="selectedNode.feedbackText"
            placeholder="Beschrijf de feedback op de gemaakte keuze."
          />
        </div>
      </div>
    </div>
    <!--End container-->
  </div>
</template>

<script>
import ConditionDropdown from "../ConditionDropdown";
import ModalPage from "../DialogueEditor/ModalPage";
import propList from "../../util/propList";

export default {
  props: [
    "showEditPanel",
    "character",
    "skills",
    "chapterEventData",
    "selectedNode"
  ],
  components: { ConditionDropdown, ModalPage },
  data() {
    return {
      selectDialogueIcon: false,
      characters: propList.props.filter(x => x.person),
      skillOption: null
    };
  },
  methods: {
    toggleSelectDialogueIcon() {
      this.selectDialogueIcon = !this.selectDialogueIcon;
    },

    selectCharacter(name) {
      this.selectedNode.portrait = name;
    },

    //update the negator in the condition string
    toggleNegation(dotButton, condIndex, operator) {
      this.$set(dotButton.condition, condIndex - 1, operator);
    },

    //When the And/Or operator switches
    switchOperator(dotButton, condIndex, operator) {
      this.$set(dotButton.condition, condIndex + 1, operator);
    },

    //Remove condition from this dot
    removeCondition(dotButton, condIndex) {
      if (dotButton.condition.length <= 2)
        //Replace with the default if it's the last condition that's left
        dotButton.condition = ["+", "0"];
      else {
        if (condIndex == 1)
          //If the first condition is being removed
          dotButton.condition.splice(condIndex - 1, 3);
        //We have to remove to and/or of this condition, the +/- of this condition, and this condition itself
        else dotButton.condition.splice(condIndex - 2, 3); //We have to remove to and/or of the previous condition, the +/- of this condition, and this condition itself
      }
    },

    //Add condition to a dot
    addCondition(dotButton, condIndex) {
      if (
        dotButton.condition[condIndex] &&
        dotButton.condition[condIndex] == 0
      ) {
        this.$emit(
          "printErrorMessage",
          "Er is nog een conditie die niet is ingevuld"
        );
        return;
      }
      dotButton.condition.push("A"); //Push a default and condition for the previous condition
      dotButton.condition.push("+"); //Push a default + condition to the new condition
      dotButton.condition.push("0"); //Push a default condition as new condition
    }
  },
  watch: {
    selectedNode() {
      //If the selected node has changed, update the current dialogue data again
      this.$emit("loadCurrentDialogueEvents");
    }
  }
};
</script>

<style lang="scss" scoped>
.dialogue-buttons {
  &-container {
    width: 19em;
    box-shadow: $box-shadow-white;
    border-radius: 2em;
    text-align: center;
    position: relative;
    overflow: auto;
    height: fit-content;
    max-height: 100%;
  }

  &-header {
    display: inline-block;
    font-family: $default-font;
    text-align: center;
    width: 100%;
  }

  &-button {
    min-width: 12em;
    font-family: $default-font;
    border-radius: 1.5em;
    border: solid;

    &:hover {
      background-color: $lightgray;
    }

    &:active {
      background-color: $darkgray;
    }

    &:focus {
      outline: none;
    }

    &-info {
      background-color: $black;
    }

    &-addplayerdialogue {
      border-color: $playerDialogue;
    }

    &-addnonplayerdialogue {
      border-color: $nonplayerDialogue;
    }

    &-addchoice {
      background-color: $optionDialogue;
      border-color: $optionDialogue;
      color: white;
      &:hover {
        background-color: $optionDialogueSelected;
      }
      &:active {
        background-color: $optionDialogueClicked;
      }
    }

    &-addoption {
      border-color: $optionDialogue;
    }

    &-container {
      width: 19em;
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5em;
    }
  }

  &-description {
    select {
      text-align: center;
      font-family: $default-font;
      display: inline-block;
      margin-left: 0.8em;
      margin-right: 0.8em;
      margin-top: 0.8em;
      border-radius: 0.8em;
      max-width: 10em;
    }
  }

  &-dropdown-container {
    bottom: 11em;
    select {
      text-align: center;
      font-family: $default-font;
      display: inline-block;
      margin-left: 0em;
      margin-right: 0em;
      margin-top: 0em;
      max-width: 10em;
    }
  }

  &-checkbox {
    border-radius: 0.1em;
    position: center;
    height: auto;
  }
}

.width {
  &-82{
    width: 82%;
  }
}

.height {
  &-5-em{
    height: 5em;
  }
}

.closeicon {
  position: relative;
}

[class^="button"] {
  background-color: $white;
  border: 1px black;
  &:hover {
    background-color: $lightgray;
  }
  &:focus {
    outline: none;
  }
}

.button {
  &-negator {
    align-self: right;
    border-radius: 20px 0px 0px 20px;
    border-style: solid none solid solid;
  }

  &-remove {
    align-self: left;
    border-radius: 0px 20px 0px 0px;
    width: 100%;
    height: 47%;
    border-style: solid solid none none;
  }

  &-add {
    align-self: left;
    border-radius: 0px 0px 20px 0px;
    width: 100%;
    height: 50%;
    border-style: solid solid solid none;
  }
}

.panel-buttons {
  z-index: 0;
  margin-bottom: -2em;
}
.prop-button {
  height: 4.5em;
  width: 4.5em;
  z-index: 1;
  background-color: $semiwhite;
  border-color: $lightgray;
  
  &:hover {
    background-color: $lightgray;
    border-color: $darkgray; 
  }
}

.prop-icon {
  height: 4em;
  width: auto;
}
.ban-icon {
  height: 3em;
  width: auto;
}
.trash-icon {
  height: 2em;
  width: 2em;
  margin-top: 1.8em;
  z-index: 1;
  background-color: $semiwhite;
  border-color: $lightgray;

  &:hover {
    background-color: $lightgray;
    border-color: $darkgray; 
  }
}
.close-icon {
  margin-top: auto;
  margin-bottom: auto;
  &:hover {
    color: red;
    cursor: pointer;
  }
}

.add-icon {
  margin-top: auto;
  margin-bottom: auto;
  &:hover {
    color: cornflowerblue;
    cursor: pointer;
  }
}

.cross-icon {
  cursor: pointer;
  vertical-align: text-bottom;
  text-align: right;
  width: 2.5em;
  &:hover {
    color: red;
    cursor: pointer;
  }
}

.minus-icon {
  cursor: pointer;
  &-end {
    height: 90%;
    cursor: pointer;
  }
  &-inactive {
    color: transparent;
  }
}

.properties-span {
  border-right: $basic-border;
  display: inline-block;
  width: 33.33333%;
}

.properties-header {
  position: relative;
  display: inline-block;
  font-family: $default-font;
  width: 100%;
}

.modal-backdrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.character-image {
  opacity: 0.4;
  max-width: 100%;
  max-height: 100%;
}

.skill-inputfield {
  width: 3.5em;
}

.skill-name {
  word-wrap: break-word;
  max-width: 10em;
  width: max-content;
}

.skills-container {
  overflow: hidden;
  position: relative;
}

.selectedCharacter {
  opacity: 1;
}

.image-container {
  width: 5em;
  height: 5em;
}

.character-name {
  overflow: auto;
  width: 100%;
  padding: 0 12px 0 12px;
  border-radius: 0.25rem 0.25rem 0 0;
  border: $basic-border;
  border-bottom: none;
  &:focus {
    outline-color: $darkgray;
  }
}

.textbox {
  box-shadow: none;
  padding-top: 0;
  border: $basic-border;
  &:focus {
    outline: 2px auto $darkgray;
  }

  &-with-name{
    box-shadow: none;
    padding-top: 0;
    border: $basic-border;
    border-radius: 0 0 0.25rem 0.25rem;
    &:focus{
      outline: 2px auto $darkgray;
    }
  }
}

::placeholder {
  color: $lightgray;
}

#condition-modal {
  border-top: $basic-border;
}
</style>
