<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div
    class="list-chapter-container d-flex w-100"
    :class="{ 'list-chapter-white': color, 'list-chapter-gray': !color }"
  >
    <!-- sets information if it is not part of the header-->
    <div class="row  justify-content-center align-items-center d-flex w-100">
      <!-- title of the chapter-->
      <div class="col-3  ml-n5">
        <p class="list-chapter-name" :class="{ bold: header }">
          {{ title }}
        </p>
      </div>
      <!-- last modified -->
      <div class="col-4 ml-n3">
        <p
          class="list-chapter-modified"
          v-if="header"
          :class="{ bold: header }"
        >
          {{ lastModified }}
        </p>

        <p class="list-chapter-modified" v-else>
          {{ lastModified | moment("HH:mm - DD / MM / YYYY") }}
        </p>
      </div>
      <!-- published in the game -->
      <div class="col-2 ml-n5">
        <p
          class="list-chapter-published"
          v-if="header"
          :class="{ bold: header }"
        >
          {{ published }}
        </p>

        <p class="list-chapter-published ml-1" v-else-if="published">
          <font-awesome-icon
            class="list-chapter-icon"
            icon="check"
            @click="$emit('changePublished', id, 0)"
          />
        </p>

        <p class="list-chapter-published ml-1" v-else>
          <font-awesome-icon
            class="list-chapter-icon"
            icon="times"
            @click="$emit('changePublished', id, 1)"
          />
        </p>
      </div>
      <!-- active on the corkboard -->
      <div class="col-2 ml-n2">
        <p
          class="list-chapter-published"
          v-if="header"
          :class="{ bold: header }"
        >
          {{ used }}
        </p>

        <p class="list-chapter-published ml-1" v-else-if="used">
          <font-awesome-icon
            class="list-chapter-icon"
            icon="minus"
            @click="$emit('changeActive', id, 0)"
          />
        </p>

        <p class="list-chapter-published ml-1" v-else>
          <font-awesome-icon
            class="list-chapter-icon"
            icon="plus"
            @click="$emit('changeActive', id, 1)"
          />
        </p>
      </div>
      <!-- go to edit a chapter -->
      <div class="col-1 ml-n1">
        <p class="list-chapter-edit" v-if="header" :class="{ bold: header }">
          Bewerk hoofdstuk
        </p>
        <p v-else>
          <font-awesome-icon
            class="list-chapter-icon ml-1"
            @click="$emit('navigateToEdit', id)"
            icon="pen"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChapterList",
  props: [
    "id",
    "title",
    "lastModified",
    "color",
    "published",
    "used",
    "header",
    "corkboardName"
  ],
  computed: {
    /** Format date to time function */
    formatDateTime() {
      return this.lastModified;
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  font-family: $default-font;
  font-size: 1em;
}
.list-chapter-white {
  background: white;
}
.bold {
  font-weight: bold;
}
.hide {
  display: none;
}
.list-chapter-gray {
  background: $lightgray;
}
.list-chapter-container {
  word-wrap: normal;
  line-height: 2em;
  text-align: center;
  display: flex;
}
.list-chapter-id {
  text-align: left;
}
.list-chapter-name {
  text-align: left;
  word-break: break-word;
}
.list-chapter-published {
  text-align: left;
}
.list-chapter-edit {
  text-align: left;
}
.list-chapter-icon {
  width: 0.9em;
  &:hover {
    cursor: pointer;
  }
}
.list-chapter-modified {
  text-align: left;
}
</style>
