<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div id="nav">
    <nav class="navbar navbar-expand navbar-dark">
      <router-link to="/" class="navbar-brand">Terminus Editor</router-link>
      <p class="text-white mb-0 copyright">© Utrecht University (ICS)</p>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <span class="navbar-text ml-auto p-0 mr-5" v-if="$route.name != 'Login'">
        <div class="d-flex align-items-center">
          <div class="dropdown">
            <button
              class="btn text-white dropdown-toggle float-right"
              type="button"
              @click.stop="openDropdown = !openDropdown"
            >
              Ingelogd als admin
            </button>
            <div
              :style="{
                height: '0px',
                overflow: 'visible'
              }"
              v-if="openDropdown"
            >
              <a
                class="dropdown-item text-dark bg-light border-top border border-bottom-0 border-dark"
                href="#"
                @click="logout"
                >Uitloggen</a
              >
              <a
                class="dropdown-item text-dark bg-light border border-top-0 border-dark"
                href="#"
                @click="showChangePassword = true"
                >Wachtwoord veranderen</a
              >
            </div>
          </div>
        </div>
      </span>
    </nav>

    <ChangePassword
      :show="showChangePassword"
      @close="showChangePassword = false"
    />
  </div>
</template>

<script>
import ChangePassword from "./ChangePassword";

export default {
  name: "Header",
  data() {
    return {
      showChangePassword: false,
      openDropdown: false
    };
  },
  components: { ChangePassword },
  methods: {
    logout() {
      let logout = () => {
        this.$axios
          .post(process.env.VUE_APP_API_HOST + "/auth/logout")
          .then(() => {
            this.$store.commit("logout");
            this.$router.push({ name: "Login" });
          })
          .catch(() => {});
      };
      logout();
    },
    handlePageClick() {
      this.openDropdown = false;
    }
  },
  created() {
    document.addEventListener("click", this.handlePageClick);
  },
  destroyed() {
    document.removeEventListener("click", this.handlePageClick);
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  background: $black;
  min-width: 25em;
  width: 100vw;
}
.copyright {
  font-size: 0.6em;
}
#nav {
  position: relative;
  flex: 0 1 auto;
  width: 100%;
  display: flex;
  z-index: 1;
}
</style>
