<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div class="main-body d-flex flex-column h-100 ">
    <HeaderRow
      :editingName="false"
      :id="this.id"
      :name="this.name"
      :prefix="'Casus'"
      :saving="saving"
      :chapterEditor="true"
      :isCorkboard="true"
      :editable="true"
      :showTitle="true"
      @updateName="updateName"
      @navigate="goBack"
      @remove="showVerifyDeleteCaseModal = true"
      @save="saveCorkboard"
    />
    <div class="page-body d-flex h-100 pb-3 mt-4 px-5" id="page-body">
      <div
        class="corkboard-container justify-content-center h-100 w-100 mx-3 "
        id="corkboard-canvas-scroll"
        ref="corkboardCanvasScroll"
      >
        <div
          class="corkboard-canvas"
          id="corkboard-canvas"
          ref="corkboardCanvas"
        >
          <!-- Every active node gets loaded by the ChapterNode component-->
          <ChapterNode
            v-for="c in activeChapters"
            :key="c.chapterId"
            :id="c.chapterId"
            :name="c.name"
            :image="backgrounds[c.thumbnail].image"
            :connections="lines"
            :chapter="c"
            :canvas="$refs.corkboardCanvas"
            :scrollCanvas="$refs.corkboardCanvasScroll"
            :ref="'chapter_' + c.chapterId"
            :overlaps="rect => checkPositionsChapterNode(rect, c.chapterId)"
            @selectOutputNode="selectOutputNode"
            @selectInputNode="selectInputNode"
            @setPosition="
              c.corkboardX = $event.x;
              c.corkboardY = $event.y;
            "
            @loadOutputLines="loadOutputLines"
            @deleteChapterConnection="deleteChapterConnection"
            @initInputDots="initInputDots"
          />
          <svg id="mySVG">
            <!-- The lines that are created, are stored in the lines array and loaded by the lines component-->
            <Lines
              v-for="(line, i) in lines.filter(l => !l.notLoaded)"
              :key="i"
              :lineData="line"
              :canvas="$refs.corkboardCanvas"
              :mousePosition="mousePosition"
              :selected="selectedLine == line"
              @deleteLine="deleteLine"
            />
          </svg>
        </div>
      </div>
      <div
        class="scenario-list-container d-flex flex-column  align-items-center h-100 px-3 pl-3"
      >
        <!-- Button to add a new chapter -->
        <div class="d-flex justify-content-between px-5 w-100">
          <button
            class="mb-3 button-bar p-3"
            @click="newCaseModalVisable = true"
          >
            Nieuw Hoofdstuk
          </button>
          <button
            v-if="caseCode == null"
            class="mb-3 button-bar p-3"
            @click="showVerifyPasswordModal = true"
          >
            Toegangscode toevoegen
          </button>
          <button
            v-else
            class="mb-3 button-bar p-3"
            @click="showVerifyPasswordModal = true"
          >
            Toegangscode wijzigen
          </button>
        </div>
        <NewCaseModal
          v-show="newCaseModalVisable"
          :isCase="false"
          :isPassword="false"
          @close="newCaseModalVisable = false"
          @create="createChapter"
        />
        <!-- The list of created chapters in the current case -->
        <div class="scenario-list d-flex w-100">
          <div class="scenario-list-content d-flex flex-column pt-2 px-3 w-100">
            <ChapterList
              title="Naam:"
              id="Casus:"
              lastModified="Laatst aangepast:"
              used="Voeg toe aan/verwijder van prikbord"
              color="0"
              published="Zichtbaar in app:"
              header="true"
            />
            <div class="scenario-scroll">
              <ChapterList
                v-for="(c, i) in chapters"
                :key="c.chapterId"
                :id="c.chapterId"
                :title="c.name"
                :lastModified="c.lastModified"
                :used="c.corkboardActive"
                :published="c.published"
                :color="i % 2"
                :corkboardName="name"
                @changePublished="changePublished"
                @changeActive="changeActive"
                @navigateToEdit="navigateToEdit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Component to show the unsaved changes menu -->
    <VerifyModal
      v-show="showVerifyUnsavedModal"
      @cancel-click="
        currentNextFunction(false);
        showVerifyUnsavedModal = false;
      "
      @ok-click="
        currentNextFunction();
        showVerifyUnsavedModal = false;
      "
      okButtonStyle="negative"
      cancelButtonStyle="positive"
      okButtonText="Verlaat pagina"
      cancelButtonText="Blijf hier"
    >
      Weet je zeker dat je deze pagina wilt verlaten zonder op te slaan?
    </VerifyModal>
    <!-- Component to show the delete menu -->
    <VerifyModal
      v-show="showVerifyDeleteCaseModal"
      @cancel-click="showVerifyDeleteCaseModal = false"
      @ok-click="removeCorkboard"
      okButtonStyle="negative"
      cancelButtonStyle="neutral"
      okButtonText="Verwijder"
      cancelButtonText="Terug"
    >
      Weet je zeker dat je dit wilt verwijderen?
    </VerifyModal>
    <NewCaseModal
      v-show="showVerifyPasswordModal"
      :isCase="false"
      :isPassword="true"
      :password="caseCode"
      @close="showVerifyPasswordModal = false"
      @create="changeCaseCode"
    />
    <ToastMessage
      :message="'Opgeslagen!'"
      ref="toast"
      v-if="saveVisible"
      :close="
        () => {
          saveVisible = false;
        }
      "
      :closeTimeout="2000"
    />
    <ToastMessage
      :message="errorMessage"
      :id="'errorToast'"
      ref="errorToast"
      :error="true"
      v-if="errorVisible"
      :close="
        () => {
          errorVisible = false;
        }
      "
      :closeTimeout="2000"
    />
  </div>
</template>

<script>
import ChapterList from "../components/Corkboard/ChapterList";
import ChapterNode from "../components/Corkboard/ChapterNode";
import NewCaseModal from "../components/NewCaseModal";
import HeaderRow from "../components/HeaderRow";
import VerifyModal from "../components/VerifyModal";
import Lines from "../components/Corkboard/Lines";
import ToastMessage from "../components/layout/ToastMessage";
import backgroundList from "../util/backgroundList";
export default {
  name: "Corkboard",
  components: {
    ChapterList,
    NewCaseModal,
    ChapterNode,
    HeaderRow,
    VerifyModal,
    Lines,
    ToastMessage
  },
  data() {
    return {
      chapters: [],
      activeChapters: [],
      id: parseInt(this.$route.query["case"]),
      name: this.$route.query["name"],
      newIdCounter: 0,
      newCaseModalVisable: false,
      showVerifyDeleteCaseModal: false,
      showVerifyPasswordModal: false,
      caseCode: null,
      saving: false,
      saveVisible: false,
      errorVisible: false,
      errorMessage: "",
      inputNode: null,
      outputNode: null,
      outputDot: null,
      selectedLine: null,
      lines: [],
      inputDots: [],
      unsavedChanges: false,
      showVerifyUnsavedModal: false,
      currentNextFunction: function() {},
      backgrounds: backgroundList.backgrounds,
      mousePosition: {
        x: 0,
        y: 0
      }
    };
  },
  methods: {
    /** Get all chapters that are created on this corkboard */
    getChapters() {
      return this.$axios
        .get(process.env.VUE_APP_API_HOST + "/case/chapters/" + this.id)
        .then(response => {
          this.name = response.data.name;
          this.caseCode = response.data.case_code;
          this.chapters = response.data.chapters;
          this.chapters = this.chapters.map (c => {c.connectedLines = []; return c});
          this.getActiveChapters();
          this.getConnections();
        })
        .catch(async errors => {
          console.log("something went wrong" + errors);
        })
        .finally(() => {
          this.loading = false;
          this.unsavedChanges = false;
        });
    },
    /** called after getting chapters from the database, to keep track of active chapters
     */
    getActiveChapters() {
      this.activeChapters = {};
      for (let i = 0; i < this.chapters.length; i++) {
        let chapter = this.chapters[i];
        if (chapter.corkboardActive == 1) {
          this.$set(this.activeChapters, chapter.chapterId, chapter);
        }
      }
    },
    /** get every connection between chapters on this corkboard*/
    getConnections() {
      return this.$axios
        .get(
          process.env.VUE_APP_API_HOST + "/case/chapters/connections/" + this.id
        )
        .then(response => {
          const lineData = response.data.connections;
          lineData.forEach(line => {
            //create a line object
            let lineProp = {
              connectionId: line.connection_id,
              chapterId: line.fk_chapterfrom_id,
              dotId: line.dot_id,
              targetNode: this.activeChapters[line.fk_chapterto_id],
              sourceNode: this.activeChapters[line.fk_chapterfrom_id],
              notLoaded: true
            };
            //Set the input dot of the line to the correct target dot
            for (let i = 0; i < this.inputDots.length; i++) {
              if (this.inputDots[i].chapterId == line.fk_chapterto_id) {
                lineProp.inputDot = this.inputDots[i];
              }
            }
            lineProp.sourceNode.connectedLines.push(lineProp);
            this.lines.push(lineProp);
          });
        })
        .catch(async errors => {
          console.log("something went wrong" + errors);
        })
        .finally(() => {
          this.unsavedChanges = false;
        });
    },
    /** Load output lines for a node */
    loadOutputLines(outputDots) {
      outputDots.forEach(dot => {
        this.lines.forEach(line => {
          //Set the correct line for the dot. 
          if (dot.initChapterId == line.chapterId && dot.dotId == line.dotId) {
            line.dot = dot;
            line.notLoaded = false;
          }
        })
      });
    },
    /** Create a new chapter */
    createChapter(name) {
      if (name == "") name = "Nieuw Hoofdstuk";

      this.newCaseModalVisable = false;
      this.$axios
        .post(process.env.VUE_APP_API_HOST + "/chapter/create", {
          name: name,
          caseId: this.id
        })
        .then(() => {
          this.$router.go();
        })
        .catch(async errors => {
          console.log("something went wrong " + errors);
        });
    },
    //change if the chapter is on the corkboard or not, if a chapter is set to inactive, all connecting lines are removed.
    changeActive(id, value) {
      if (id == "" || value == null) {
        return;
      }

      if (!value) {
        //Delete the lines connected to this chapter
        this.lines.filter(line => line.chapterId == id).forEach(l => this.deleteLine(l)); //If the input node of the line will be deleted
        this.lines.filter(line => line.targetNode.chapterId == id).forEach(l => this.deleteLine(l)); //If the target node will be deleted
        
        //Reset the position values of the chapter node
        this.activeChapters[id].corkboardActive = value;
        this.activeChapters[id].corkboardX = 0;
        this.activeChapters[id].corkboardY = 0;
      } else if (value) {
        for (const chapter of this.chapters) {
          if (chapter.chapterId == id) {
            chapter.corkboardActive = value;
            break;
          }
        }
      }
      this.getActiveChapters();
    },
    //change if the chapter is available in the game
    changePublished(id, value) {
      if (id == "" || value == null) {
        return;
      }
      for (const chapter of this.chapters) {
        if (chapter.chapterId == id) {
          chapter.published = value;
          break;
        }
      }
    },
    //delete the case and every chapter in it
    removeCorkboard() {
      return this.$axios
        .delete(process.env.VUE_APP_API_HOST + "/case/delete/" + this.id)
        .then(() => {
          this.$router.push({ name: "dashboard" }).catch(errors => {
            if (errors?.type != 4)
              //type 4 is manually aborted in guard
              console.log("navigation went wrong", errors);
          });
        })
        .catch(async errors => {
          console.log("something went wrong " + errors);
        });
    },
    //update the name of the case
    updateName(name) {
      return this.$axios
        .post(process.env.VUE_APP_API_HOST + "/case/update/name", {
          caseId: this.id,
          name: name
        })
        .then(() => {
          this.name = name;
        })
        .catch(async errors => {
          console.log("something went wrong " + errors);
        });
    },
    //selects the chapter node and position from which the line is coming from
    selectOutputNode(outputNode) {
      this.outputNode = outputNode;
      let outputNodeId = outputNode.chapterId;
      let outputDot = outputNode.dotId;

      this.activeChapters[outputNodeId].connectedLines.forEach(l => {
        if (l.chapterId == outputNodeId && l.dotId == outputDot) {
          this.deleteLine(l);
        }
      })
      //create a line object
      let line = {
        connectionId: 'new'.concat(this.newIdCounter++), //New keyword for ids
        chapterId: outputNode.chapterId,
        dotId: outputNode.dotId,
        targetNode: null,
        sourceNode: this.activeChapters[outputNode.chapterId],
        dot: outputNode.dot,
        inputDot: null
      };
      
      this.selectedLine = line;
      this.lines.push(line);
    },
    //selects the chapter node and position from which the line is going to
    selectInputNode(inputNode) {
      //check if an output node is selected
      if (this.outputNode == null) return;
      const outputNodeChapterId = this.outputNode.chapterId;
      const inputNodeChapterId = inputNode.chapterId;
      //check if you're trying to connect with yourself
      if (inputNodeChapterId == outputNodeChapterId) {
        this.outputNode = null;
        return;
      }
      //Check if it's a valid connection
      if (this.checkCircleConnection(inputNodeChapterId, outputNodeChapterId, outputNodeChapterId) &&
          this.checkDuplicateConnection(inputNodeChapterId, outputNodeChapterId)) {
        //Set the target node and deselect the line and add it to the connectedLines of the source node    
        this.selectedLine.inputDot = inputNode;
        this.selectedLine.targetNode = this.activeChapters[inputNodeChapterId];
        this.activeChapters[outputNodeChapterId].connectedLines.push(this.selectedLine);
        this.selectedLine = null;
      }
      else {
        this.deleteLine(this.selectedLine);
      }

      //reset the current output node
      this.outputNode = null;
      this.$forceUpdate();
    },
    //Check for duplicate connections when connecting chapters
    checkDuplicateConnection(targetNodeChapterId, sourceNodeChapterId) {
      //Check for every already existing line if the target node has already been connected to 
      return this.activeChapters[sourceNodeChapterId].connectedLines.every(line => { 
        if (line.targetNode.chapterId == targetNodeChapterId) {
          return false;
        }
        return true;
      });
    },
    //check if the check if the chapter you're trying to connect to is already connected through other chapters
    checkCircleConnection(targetNodeChapterId, sourceNodeChapterId, originalSource) {
      //If there are no outgoing connections, it's not a circle
      if (!this.activeChapters[sourceNodeChapterId].connectedLines)
        return true;
      
      if (targetNodeChapterId == originalSource) { //If the target node is the original source node, it's a circle
        return false;
      }
      //Check every outgoing connection of the target node for circle connection
      return this.activeChapters[targetNodeChapterId].connectedLines.every(line => { 
        return this.checkCircleConnection(line.targetNode.chapterId, targetNodeChapterId, originalSource);
      });
    },
    //delete a line from the lines array, given a line
    deleteLine(line) {
      //Remove the line from the source node connected lines as well
      line.sourceNode.connectedLines = line.sourceNode.connectedLines.filter(l => l.connectionId != line.connectionId);
      this.lines = this.lines.filter(l => l.connectionId != line.connectionId);

      this.selectedLine = null;
    },
    //delete connection between lines if an output dot is deleted
    deleteChapterConnection(chapterId, dotId) {
      for (let i = 0; i < this.lines.length; i++) {
        if (
          this.lines[i].chapterId == chapterId &&
          this.lines[i].dotId == dotId
        ) {
          this.deleteLine(this.lines[i]);
        }
      }
    },
    //fills the nodes from which the connections are going to
    initInputDots(inputDot) {
      this.inputDots.push(inputDot);
    },
    //make a backend call to save the current chapters
    async saveCorkboard(toEdit) {
      this.chapters = this.chapters.map(a => {delete a.connectedLines; return a});
      return this.$axios
        .post(
          process.env.VUE_APP_API_HOST +
            "/case/chapters/connections/update",
          {
            connections: this.lines,
            caseId: this.id,
            corkboardChapters: this.chapters,
          }
        )
        .then(() => {
          if (!toEdit)
            this.$router.go();
          else
            this.unsavedChanges = false;
        })
        .catch(async errors => {
          console.log("update catch " + errors);
        });
    },
    /** Go back one page*/
    goBack() {
      this.$router
        .push({
          name: "dashboard"
        })
        .catch(errors => {
          if (errors?.type != 4)
            //type 4 is manually aborted in guard
            console.log("navigation went wrong", errors);
        });
    },
    /** Calculate overlap for two rectangles */
    conditionCheckPositions(rect, otherRect) {
      const otherNodeWidth = otherRect.width;
      const otherNodeHeight = otherRect.height;
      const maxWidthNode = rect.left + rect.width;
      const maxHeightNode = rect.top + rect.height;
      const maxWidthOtherNode = otherRect.left + otherNodeWidth;

      return (
        otherRect.left <= maxWidthNode &&
        maxWidthOtherNode >= rect.left &&
        otherRect.top <= maxHeightNode &&
        otherNodeHeight + otherRect.top >= rect.top
      );
    },
    /** check if the positions of chapter nodes overlap */
    checkPositionsChapterNode(rect, chapterid) {
      return Object.values(this.activeChapters).some(chapterNode => {
        if (chapterNode.chapterId != chapterid) {
          const otherNode = this.$refs[
            "chapter_" + chapterNode.chapterId
          ][0].$refs.moveable.getRect();
          return this.conditionCheckPositions(rect, otherNode);
        }
      });
    },
    /** Router navigation to edit chapter
     */
    async navigateToEdit(chapterId) {
      await this.saveCorkboard(true); //Save when you edit the chapter
      this.$router
        .push({
          name: "chaptereditor",
          query: { case: this.id, chapter: chapterId }
        })
        .catch(errors => {
          if (errors?.type != 4)
            //type 4 is manually aborted in guard
            console.log("navigation went wrong", errors);
        });
    },
    //get the mouse information
    handleMouse(event) {
      this.mousePosition = { x: event.pageX, y: event.pageY };
    },
    //add, changes or removes a code
    changeCaseCode(code) {
      //no change needed
      if (code == this.caseCode) {
        this.errorMessage = "Je probeert dezelfde code op te slaan";
        this.errorVisible = true;
        return;
      }
      //invalid code
      if (isNaN(this.filterInt(code))) {
        this.errorMessage = "Dit is geen getal";
        this.errorVisible = true;
        return;
      }
      return this.$axios
        .post(process.env.VUE_APP_API_HOST + "/cases/codes", 
        {
          caseId: this.id,
          caseCode: code
        })
        .then(() => {
          if (code == "") {
            this.caseCode = null;
          } else if (!isNaN(this.filterInt(code))) {
            this.caseCode = code;
          }
          this.saveVisible = true;
          this.showVerifyPasswordModal = false;
        })
        .catch(async errors => {
          console.log("update catch", errors.response.status);
          if (errors.response.status == 409) {
            this.errorMessage = "De code die je hebt ingevuld bestaat al";
            this.errorVisible = true;
          }
          return;
        });
    },
    //Strict int parser mozilla
    filterInt(value) {
      if (value == "") {
        return value;
      }
      if (/^[-+]?(\d+|Infinity)$/.test(value)) {
        return Number(value);
      } else {
        return NaN;
      }
    }
  },
  created() {
    this.getChapters();
    document.addEventListener("mousedown", this.handleMouse);
    document.addEventListener("mousemove", this.handleMouse);
  },
  mounted() {
    // Set to no unsaved changes after all initial setup has been handled
    setTimeout(() => {
      this.unsavedChanges = false;
    }, 300);
  },
  destroyed() {
    document.removeEventListener("mousedown", this.handleMouse);
    document.removeEventListener("mousemove", this.handleMouse);
  },
  watch: {
    lines: {
      handler() {
        this.unsavedChanges = true;
      },
      deep: true
    },
    activeChapters: {
      handler() {
        this.unsavedChanges = true;
      },
      deep: true
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.unsavedChanges) {
      next();
    } else {
      this.currentNextFunction = next;
      this.showVerifyUnsavedModal = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.page-body {
  position: relative;
}

.corkboard-container {
  min-width: 250px;
  width: 100%;
  overflow: scroll;
  box-shadow: $box-shadow-white;
  border-radius: 0.5em;
  position: relative;
  z-index: 0;
}
.corkboard-canvas {
  background: url("../assets/Corkboard/BulletinboardCasesCleanBody2.png")
    no-repeat right top scroll;
  background-size: 2000px 2300px;
  position: absolute;
  width: 2000px;
  height: 2300px;
  z-index: 1;
}

.scenario-list-container {
  min-width: 800px;
}

.scenario-list {
  box-shadow: $box-shadow-white;
  border-radius: 2em;
  overflow: hidden;
}
.scenario-scroll {
  overflow-y: auto;
  overflow-x: hidden;
}
.main-body {
  height: 100%;
  position: relative;
  overflow: hidden;
  min-width: max-content;
}
.page-body {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-width: max-content;
}
.button-bar {
  font-family: $default-font;
  border: 0px;
  background-color: $black;
  color: $white;
  border-radius: 2em;
  min-width: 19em;
  &:hover{
    background-color: $lightblack;
  }
  &:focus{
    outline: none;
  }
}
#mySVG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
</style>
