<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <slot></slot>
      </header>
      <footer class="modal-footer">
        <button
          type="button"
          class="button px-2 py-1"
          @click="$emit('cancel-click')"
          :class="cancelButtonStyle"
        >
          {{ this.cancelButtonText }}
        </button>
        <button
          type="button"
          class="button px-2 py-1"
          @click="$emit('ok-click')"
          :class="okButtonStyle"
        >
          {{ this.okButtonText }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "VerifyModal",
  props: [
    "okButtonText",
    "cancelButtonText",
    "okButtonStyle",
    "cancelButtonStyle"
  ]
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-header {
  border: 0px;
  display: flex;
  justify-content: center;
}
.modal-body {
  border: 0px;
  display: flex;
  justify-content: center;
  input {
    border-radius: 1em;
    border: $basic-border;
    &:focus {
      outline: unset;
      box-shadow: $box-shadow-focus;
    }
  }
}
.modal-footer {
  border: 0px;
  display: flex;
  justify-content: space-between;
}
.modal {
  background: #ffffff;
  box-shadow: 0px 0px 1.2em -0.3em;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  border-radius: 2em;
  padding: 1rem;
  top: auto;
  left: auto;
  width: max-content;
  height: min-content;
}
.button {
  font-family: $default-font;
  border-radius: 2em;
  border: 0px;
  &:focus {
    outline: unset;
    box-shadow: $box-shadow-focus;
  }
}
.negative {
  background-color: $red;
  color: $white;
}
.positive {
  background-color: $green;
  color: $white;
}
.neutral {
  background-color: $black;
  color: $white;
}
</style>
