var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialogue-editor d-flex flex-column w-100 h-100"},[(_vm.saveVisible)?_c('ToastMessage',{ref:"saveToast",attrs:{"message":'Opgeslagen!',"id":'saveToast',"close":function () {
        _vm.saveVisible = false;
      },"closeTimeout":2000}}):_vm._e(),(_vm.errorVisible)?_c('ToastMessage',{attrs:{"message":_vm.errorMessage,"id":'errorToast',"error":true,"close":function () {
        _vm.errorVisible = false;
      },"closeTimeout":_vm.errorTime}}):_vm._e(),_c('HeaderRow',{attrs:{"editable":!_vm.isContact,"prefix":'Dialoog',"name":this.dialogueName,"saving":_vm.saving,"showTitle":true},on:{"navigate":_vm.navigateToScene,"save":_vm.saveDialogueTree,"remove":function($event){_vm.showVerifyDeleteDialogueModal = true},"updateName":_vm.editEventTitle}}),_c('div',{staticClass:"canvas-container px-5 py-4 d-flex w-100 h-100"},[_c('div',{ref:"canvasScroll",staticClass:"canvas-scroll mr-3 d-flex",attrs:{"id":"canvas-scroll"}},[_c('div',{ref:"canvas",staticClass:"canvas",attrs:{"id":"canvas"}},[_vm._l((_vm.dialogueNodes),function(node){return _c('DialogueNode',{key:node.id,ref:'dialogue_' + node.id,refInFor:true,attrs:{"node":node,"canvas":_vm.$refs.canvas,"canvasScroll":_vm.$refs.canvasScroll,"overlaps":function (rect) { return _vm.overlapsNode(rect, node.id); },"selected":_vm.selectedNode == node},on:{"openEditPanel":function($event){_vm.editPanelOpen = true;
            _vm.selectedNode = node;},"nodeUpdate":_vm.updateNodePosition,"createArrow":_vm.createLine,"selectInputDot":_vm.connectLine,"setPosition":function($event){node.position.x = $event.x;
            node.position.y = $event.y;},"setDotPosition":function($event){node.outputDots[$event.index].position = $event.position},"loadDialogueLine":_vm.loadDialogueLine,"addDot":_vm.createDot}})}),_c('svg',{attrs:{"id":"mySVG"}},_vm._l((_vm.lines),function(line,i){return _c('DialogueLine',{key:i,attrs:{"lineData":line,"canvas":_vm.$refs.canvas,"mousePosition":_vm.mousePosition,"selectedLine":_vm.selectedLine},on:{"deleteLine":_vm.deleteLine}})}),1)],2)]),_c('DialoguePanel',{ref:"dialoguePanel",attrs:{"showEditPanel":_vm.editPanelOpen,"character":_vm.character,"skills":_vm.skills,"chapterEventData":_vm.events,"selectedNode":_vm.selectedNode},on:{"printErrorMessage":_vm.printErrorMessage,"add-dialogue":_vm.addDialogueNode,"deleteNode":_vm.deleteSelectedNode,"addDot":_vm.createDot,"deleteDot":_vm.deleteDot,"openConditionModal":_vm.openConditionModal,"loadCurrentDialogueEvents":_vm.loadCurrentDialogueEvents,"addSkill":function (skill) { return _vm.addSkillToNode(_vm.selectedNode, skill); },"removeSkill":function (index) { return _vm.removeSkillFromNode(_vm.selectedNode, index); }}}),_c('ConditionModal',{ref:"conditionModal",attrs:{"condIndex":_vm.conditionIndex,"treeID":1,"nodes":_vm.treeData,"events":_vm.events},on:{"selectedCondition":_vm.conditionModalEventSelected}})],1),_c('VerifyModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVerifyDeleteDialogueModal),expression:"showVerifyDeleteDialogueModal"}],attrs:{"okButtonStyle":"negative","cancelButtonStyle":"neutral","okButtonText":"Verwijder","cancelButtonText":"Terug"},on:{"cancel-click":function($event){_vm.showVerifyDeleteDialogueModal = false},"ok-click":function($event){_vm.deleteDialogue();
      _vm.showVerifyDeleteDialogueModal = false;}}},[_vm._v(" Weet je zeker dat je '"+_vm._s(_vm.dialogueName)+"' wilt verwijderen? ")]),_c('VerifyModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVerifyUnsavedModal),expression:"showVerifyUnsavedModal"}],attrs:{"okButtonStyle":"negative","cancelButtonStyle":"positive","okButtonText":"Verlaat pagina","cancelButtonText":"Blijf hier"},on:{"cancel-click":function($event){_vm.currentNextFunction(false);
      _vm.showVerifyUnsavedModal = false;},"ok-click":function($event){_vm.currentNextFunction();
      _vm.showVerifyUnsavedModal = false;}}},[_vm._v(" Weet je zeker dat je deze pagina wilt verlaten zonder op te slaan? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }