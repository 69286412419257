<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div class="panel-button-container d-flex flex-column h-100 ">
    <!-- The button(s) above the actual panel -->
    <div class="d-flex flex-column mx-3">
      <button
        class="mb-3 properties-pane-button properties-pane-button-addscene p-3"
        @click="$emit('addScene', false)"
      >
        Scene toevoegen
      </button>
      <button
        class="mb-3 properties-pane-button properties-pane-button-addscene p-3"
        @click="$emit('addScene', true)"
      >
        Einde toevoegen
      </button>
    </div>

    <!-- The properties panel -->
    <div class="properties-pane-container d-flex flex-column mx-3">
      <!-- The header buttons -->
      <div
        class="properties-header d-flex w-100"
        style="border-bottom: 0.1em solid lightgray"
      >
        <span
          @click="setWindow(1)"
          :class="getActiveStyle(1)"
          class="properties-span properties-span-border properties-span1"
          data-toggle="tooltip"
          title="Casus eigenschappen"
        >
          <small><font-awesome-icon icon="cog"/></small>
        </span>
        <span
          @click="setWindow(2)"
          :class="getActiveStyle(2)"
          class="properties-span properties-span-border properties-span2"
          data-toggle="tooltip"
          title="Scene eigenschappen"
        >
          <small><font-awesome-icon icon="box"/></small>
        </span>
        <span
          @click="setWindow(3)"
          :class="getActiveStyle(3)"
          class="properties-span properties-span-border properties-span3"
          data-toggle="tooltip"
          title="Scene Muziek"
        >
          <small><font-awesome-icon icon="music"/></small>
        </span>
      </div>

      <!-- The default panel, with properties for the current chapter -->
      <div v-if="selectedProperties == 1" class="properties-content pb-4 px-1">
        <!-- The editable description of the chapter -->
        <div
          class="properties-pane-description w-100 d-flex justify-content-center mt-2 pb-2"
        >
          <div class="w-100">
            <span class="properties-pane-header mb-2 mt-2 pb-2"
              >Hoofdstuk Omschrijving:</span
            >
            <div class="mx-3">
              <textarea
                style="resize: none;"
                class="form-control"
                placeholder="Tekst..."
                rows="4"
                :value="description"
                @change="$emit('description-change', $event)"
              ></textarea>
            </div>
          </div>
        </div>

        <!-- Setting for whether the chapter is published to the game -->
        <div
          class="properties-pane-dropdown-container w-100 d-flex justify-content-center"
        >
          <div class="w-100">
            <span class="properties-pane-header mt-2 pb-2">Zichtbaarheid:</span>
            <div class="d-flex justify-content-center w-100">
              <select
                @change="$emit('chapterPublished', parseInt($event.target.value))"
                class="w-100 mt-2 no-focus-outline"
                :value="published"
              >
                <option value="1">Gepubliceerd</option>
                <option value="0">Niet Gepubliceerd</option>
              </select>
            </div>
          </div>
        </div>

        <!-- Setting for the available training or testing mode of the chapter -->
        <div
          class="properties-pane-dropdown-container w-100 d-flex justify-content-center"
        >
          <div class="w-100">
            <span class="properties-pane-header  mt-2 pb-2">Willekeurige Optie Volgorde:</span>
            <div class="d-flex justify-content-center w-100">
              <select
                @change="$emit('chapterRandom', parseInt($event.target.value))"
                :value="random"
                class="w-100 mt-2 no-focus-outline"
              >
                <option value="0" selected>Uit</option>
                <option value="1">Aan</option>
              </select>
            </div>
          </div>
        </div>

        <!-- Setting for the time limit of the chapter -->
        <div
          class="properties-pane-dropdown-container w-100 d-flex justify-content-center"
        >
          <div class="w-100">
            <span class="properties-pane-header  mt-2 pb-2">Timer:</span>
            <span class="mt-2 properties-pane-subheader"
              >Zet op 0 om tijdslimiet uit te schakelen</span
            >

            <div class="input-row">
              <div class="form-group form-inline">
                <input
                  type="number"
                  class="form-control mx-auto"
                  autocomplete="off"
                  min="0"
                  max="500"
                  :value="timer"
                  @change="
                    $emit(
                      'chapterTimer',
                      Math.min(Math.max(0, $event.target.value), 500)
                    )
                  "
                />
                <span class="min">minuten</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- The second tab, with properties for the currently selected scene -->
      <div
        v-else-if="selectedProperties == 2"
        class="properties-content pb-4 px-1"
      >
        <!-- The scene name and when the scene has been created and edited -->
        <div class="mt-3 mb-2 px-1 text-left d-flex justify-content-center">
          <div class="w-100">
            <!-- The scene name of a start or scene node -->
            <div v-if="!selectedScene.isEndNode" class="row w-100 m-0">
              <div class="col-6"><b>Scene:</b></div>
              <div class="col-6 pr-0 scene-name-normal">
                {{ selectedScene.name }}
              </div>
            </div>
            <!-- The type of end node, which will be either a fail or success -->
            <div v-else class="row w-100 m-0">
              <div class="col-6"><b>Soort einde:</b></div>
              <button 
                class="col-6 scene-name-end"
                @click="selectedScene.name == 'Succes' ? $emit('changeName', 'Mislukt') : $emit('changeName', 'Succes')"
              >
                {{ selectedScene.name }}
              </button>
            </div>
            <div class="row w-100 m-0">
              <div class="col-6"><b>Aangemaakt:</b></div>
              <div class="col-6 pr-0">
                {{
                  selectedScene.created
                    ? selectedScene.created
                    : new Date() | moment("DD-MM-YYYY")
                }}
              </div>
            </div>
            <div class="row w-100 m-0">
              <div class="col-6"><b>Aangepast:</b></div>
              <div class="col-6 pr-0">
                {{
                  selectedScene.last_modified
                    ? selectedScene.last_modified
                    : new Date() | moment("DD-MM-YYYY")
                }}
              </div>
            </div>
          </div>
        </div>

        <!-- The editable scene description -->
        <div
          v-if="!selectedScene.isEndNode" 
          class="properties-pane-description w-100 d-flex justify-content-center"
        >
          <div class="w-100">
            <span class="properties-pane-header mb-2 mt-2 pb-2"
              >Scene Omschrijving:</span
            >
            <div class="mx-3">
              <textarea
                style="resize: none;"
                class="form-control"
                rows="4"
                v-model="selectedScene.description"
              ></textarea>
            </div>
          </div>
        </div>

        <!-- A button to delete the scene -->
        <div class="w-100 px-2 mt-4">
          <button
            @click="$emit('removeScene')"
            class="properties-pane-button properties-pane-button-delete w-100"
          >
            Verwijder {{ selectedScene.isEndNode ? "einde" : "scene" }}
          </button>
        </div>
      </div>
      <!-- CONTENT 3 -->
      <div
        v-else-if="selectedProperties == 3"
        class="properties-content pb-4 px-1"
      >
        <div class="w-100">
          <span class="properties-pane-header mb-2 mt-2 pb-2">
            Scene muziek:
          </span>
          <label><b>Geluid:</b></label>
          <div class="input-row">
            <div class="input-row">
              <select
                class="custom-select mb-2 mt-2 pb-2"
                @change="
                  $emit('setSceneMusic', music[$event.target.value]);
                  $forceUpdate();
                "
                :value="selectedScene.music ? selectedScene.music.id : -1"
              >
                <option :key="-1" :value="-1">Geen muziek</option>
                <option
                  v-for="sound in music"
                  :key="sound.id"
                  :value="sound.id"
                >
                  {{ sound.name }}
                </option>
              </select>
            </div>
            <div v-if="selectedScene.music">
              <audio controls controlsList="nodownload" :src="selectedScene.music.audio" ref="audioRef" />
              <av-line :ref-link="'audioRef'" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import soundList from "../../util/soundList";

export default {
  name: "PropertiesPane",
  components: {},
  props: {
    selectedScene: Object,
    selectedProperties: Number,
    soundPath: String,
    description: String,
    published: Number,
    random: Number,
    timer: Number
  },
  data() {
    return {
      music: soundList.music
    };
  },
  methods: {
    /** set properties pane window/tab */
    setWindow(b) {
      // This tab is not clickable if it is tab 2 or 3 and there is no selected scene, or if it is tab 3 and you have selected an end node
      if (((b == 2 || b == 3) && !this.selectedScene) || (b == 3 && this.selectedScene.isEndNode == 1)) {
        return;
      }
      this.$emit("changeProperties", b);
    },
    /** get active class style for properties pane tabs */
    getActiveStyle(pane) {
      // This tab is not clickable if it is tab 2 or 3 and there is no selected scene, or if it is tab 3 and you have selected an end node
      if (((pane == 2 || pane == 3) && !this.selectedScene) || (pane == 3 && this.selectedScene.isEndNode == 1))
        return "notClickable";
      if (this.selectedProperties != pane) return "not-active";
      else return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-button-container {
  height: 100%;
  position: relative;
}
.properties-pane {
  &-container {
    min-width: 19em;
    box-shadow: $box-shadow-white;
    border-radius: 2em;
    text-align: center;
    position: relative;
    overflow: auto;
    height: fit-content;
    max-height: 100%;
  }

  &-header {
    display: inline-block;
    font-family: $default-font;
    text-align: center;
    border-bottom: $basic-border;
    width: 100%;
  }

  &-button {
    min-width: 12em;
    font-family: $default-font;
    border: 0px;
    background-color: $black;
    color: $white;
    border-radius: 2em;

    &:focus {
      outline: none;
    }

    &-info {
      background-color: $black;
    }

    &-addscene {
      min-width: 19em;
      &:hover {
        background-color: $lightblack;
      }
      &:active {
        outline: unset;
        box-shadow: $box-shadow-focus;
      }
    }

    &-success {
      background-color: $green;
    }

    &-delete {
      background-color: $red;
      &:hover {
        background-color: $darkred;
      }
    }
  }

  &-description {
    select {
      text-align: center;
      font-family: $default-font;
      display: inline-block;
      border-radius: 1em;
    }
  }

  &-dropdown-container {
    select {
      text-align: center;
      font-family: $default-font;
      display: inline-block;
      border-radius: 1em;
      max-width: 12em;
    }
  }
}
.scene-name {
  &-end {
    word-wrap: break-word;
    padding: 0;
    max-width: 35%;
    background-color: $white;
    border: 2px solid $semiwhite;
    border-radius: 1em;
    margin-left: 1.5%;
    text-align: center;
    &:hover {
      background-color: $semiwhite;
      border-color: $lightgray;
    }
    &:focus {
      outline: none;
    }
  }
  &-normal {
    word-wrap: break-word;
    background-color: $white;
    text-align: left;
    &:focus {
      outline: none;
    }
  }
}
.properties-header {
  position: relative;
  display: inline-block;
  font-family: $default-font;
}

.not-active {
  color: $darkgray;
  &:hover {
    color: $darkergray;
    cursor: pointer;
  }
}
.properties-span-border {
  border-right: $basic-border;
}
.properties-span {
  display: inline-block;
  width: 100%;
  &:hover {
    background-color: $semiwhite;
    cursor: pointer;
  }
}

.properties-content1 {
  overflow-y: auto;
}

.properties-content {
  height: 100%;
  width: 19em;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.notClickable {
  color: $semiwhite;
  cursor: default !important;
  &:hover {
    background-color: $white;
  }
}

.properties-pane-subheader {
  font-size: small;
}

.no-focus-outline:focus {
  outline: none;
}

.input-row {
  label {
    line-height: 100%;
  }
  select {
    width: 70% !important;
    margin-left: auto;
  }
  input {
    width: 70% !important;
    margin-left: auto;
  }
  button {
    width: 100% !important;
    margin-left: auto;
  }
  position: relative;
}

.min {
  position: absolute;
  top: auto;
  right: 75px;
}

::placeholder {
  color: $lightgray;
}
</style>
