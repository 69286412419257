//This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)

export default {
    sounds: [
        {
            id: "gunshot",
            name: "Geweer schot",
            audio: require("@/assets/Soundeffects/Gunshot.mp3"),
        },
        {
            id: "car_Departing",
            name: "Auto start",
            audio: require("@/assets/Soundeffects/Car_departing.wav"),
            
        },
        {
            id: "Steps",
            name: "Voetstappen",
            audio: require("@/assets/Soundeffects/Footsteps.mp3"),
        },
        {
            id: "keys",
            name: "Sleutels",
            audio: require("@/assets/Soundeffects/Keys.wav"),
        },
        {
            id: "shouting",
            name: "Geschreeuw",
            audio: require("@/assets/Soundeffects/Shout.wav"),
        },
        {
            id: "bonk",
            name: "Bonk",
            audio: require("@/assets/Soundeffects/Dropping.wav"),
        },
        {
            id: "trunkClosing",
            name: "Autodeur slaat dicht",
            audio: require("@/assets/Soundeffects/Trunk.mp3"),
        },
        {
            id: "paperNoise",
            name: "Papier",
            audio: require("@/assets/Soundeffects/Paper.wav"),
        },
        {
            id: "helicopter",
            name: "Helicopter",
            audio: require("@/assets/Soundeffects/Helicopter.wav"),
        },
        {
            id: "sirens",
            name: "Sirenes",
            audio: require("@/assets/Soundeffects/Sirens.wav"),
        },
        {
            id: "talkingMumbling",
            name: "Achtergrond gepraat",
            audio: require("@/assets/Soundeffects/Mumbling.ogg"),
        },
        {
            id: "heavyDoorOpening",
            name: "Zware deur wordt geopend",
            audio: require("@/assets/Soundeffects/Squackydoor.wav"),
        },
        {
            id: "highwayNoise",
            name: "Snelweg",
            audio: require("@/assets/Soundeffects/Highway.mp3"),
        },
        {
            id: "boatTooter",
            name: "Boot toeter",
            audio: require("@/assets/Soundeffects/Boat.wav"),
        },
    ],
    music: [
        {
            id: 0,
            name: "Creepy Music",
            audio: require("@/assets/Music/CreepyBG.mp3"),
        },
        {
            id: 1,
            name: "Investigation Music",
            audio: require("@/assets/Music/Inspection_jam.mp3"),
        },
        {
            id: 2,
            name: "Intense Music",
            audio: require("@/assets/Music/Intense_inspection.mp3"),
        },
        {
            id: 3,
            name: "Relaxed Investigation Music",
            audio: require("@/assets/Music/Investigation_relaxed.mp3"),
        },
        {
            id: 4,
            name: "Relaxed Funky Music",
            audio: require("@/assets/Music/Relaxed_funky.mp3"),
        },
    ]
}
