<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <svg>
    <defs>
      <marker
        id="arrowhead"
        markerWidth="20"
        markerHeight="14"
        refX="0"
        refY="7"
        orient="auto"
      >
        <polygon points="20 0, 0 7, 20 14" />
      </marker>
    </defs>
    <polyline
      :points="
        lineEndPosition.x +
          ',' +
          lineEndPosition.y +
          ' ' +
          lineStartPosition.x +
          ',' +
          lineStartPosition.y
      "
      fill="none"
      stroke-width="1px"
      stroke="black"
      marker-start="url(#arrowhead)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    lineData: undefined,
    canvas: undefined,
    mousePosition: Object,
    selected: Boolean
  },
  methods: {
    //Delete this line when dragging has ended. (In the function itself it will checked if a input dot has been selected)
    handleMouseUp() {
      if (this.selected) {
        this.$emit("deleteLine", this.lineData);
      }
    }
  },
  computed: {
    lineStartPosition() {
      //The start position is the output dot position of the source node
      return {
        x: this.lineData.dot.dotPos.x,
        y: this.lineData.dot.dotPos.y
      };
    },
    lineEndPosition() {
      if (!this.lineData.targetNode) {
        //If there is no end node yet (when dragging) return nothing.
        let canvasPosition = this.canvas.getBoundingClientRect();
        return {
          x: this.mousePosition.x - canvasPosition.left,
          y: this.mousePosition.y - canvasPosition.top
        };
      }
      let inputDot = this.lineData.inputDot;
      return {
        x: inputDot.dotPos.x, //If there's a target node set end position to the input dot of that node
        y: inputDot.dotPos.y
      };
    }
  },
  created() {
    document.addEventListener("mouseup", this.handleMouseUp);
  },
  destroyed() {
    document.removeEventListener("mouseup", this.handleMouseUp);
  }
};
</script>
