//This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)

export default {
  backgrounds: {
    Airport: {
      sceneName: "Vliegveld",
      image: require("@/assets/Backgrounds/Airport.png")
    },
    Amsterdam: {
      sceneName: "Amsterdam",
      image: require("@/assets/Backgrounds/Amsterdam.png")
    },
    Barn: {
      sceneName: "Boederij",
      image: require("@/assets/Backgrounds/Barn.png")
    },
    Border_NL_BE: {
      sceneName: "Belgie Grens",
      image: require("@/assets/Backgrounds/Border_NL_BE.png")
    },
    Border_NL_DE: {
      sceneName: "Duitsland Grens",
      image: require("@/assets/Backgrounds/Border_NL_DE.png")
    },
    City_road: {
      sceneName: "Stads weg",
      image: require("@/assets/Backgrounds/City_road.png")
    },
    Coffee_shop: {
      sceneName: "Koffie shop",
      image: require("@/assets/Backgrounds/CoffeeShop.png")
    },
    Expensive_house: {
      sceneName: "Duur huis",
      image: require("@/assets/Backgrounds/ExpensiveHouse.png")
    },
    Hearing_room: {
      sceneName: "Hoor kamer",
      image: require("@/assets/Backgrounds/Hearing_room.png")
    },
    Forest_road: {
      sceneName: "Bos weg",
      image: require("@/assets/Backgrounds/ForestRoad.png")
    },
    Office: {
      sceneName: "Kantoor",
      image: require("@/assets/Backgrounds/Office.png")
    },
    lab: {
      sceneName: "lab",
      image: require("@/assets/Backgrounds/lab.png")
    },
    Police: {
      sceneName: "Politie Kamer",
      image: require("@/assets/Backgrounds/Police_building_room.png")
    },
    Port: {
      sceneName: "Haven",
      image: require("@/assets/Backgrounds/Port.png")
    },
    Rental_company_exterior: {
      sceneName: "Buitenkant verhuurbedrijf",
      image: require("@/assets/Backgrounds/RentalCompanyExterior.png")
    },
    Rental_company_interior: {
      sceneName: "Binnenkant verhuurbedrijf",
      image: require("@/assets/Backgrounds/RentalCompanyInterior.png")
    },
    Rural_road: {
      sceneName: "Bos weg",
      image: require("@/assets/Backgrounds/Rural_road.png")
    },
    Truck: {
      sceneName: "Vrachtwagen",
      image: require("@/assets/Backgrounds/Truck.png")
    },
    Winkelcentrum: {
      sceneName: "Winkelcentrum",
      image: require("@/assets/Backgrounds/Winkelcentrum.png")
    },
    Woonwijk: {
      sceneName: "Woonwijk",
      image: require("@/assets/Backgrounds/Woonwijk.png")
    },
    Industry: {
      sceneName: "Industrieterrein",
      image: require("@/assets/Backgrounds/Industry.png")
    },
    Kitchen: {
      sceneName: "Keuken",
      image: require("@/assets/Backgrounds/Kitchen.png")
    },
    Restaurant: {
      sceneName: "Restaurant",
      image: require("@/assets/Backgrounds/Restaurant.png")
    },
    Station: {
      sceneName: "Station",
      image: require("@/assets/Backgrounds/Station.png")
    },
    Two_way_road: {
      sceneName: "Tweebaansweg",
      image: require("@/assets/Backgrounds/TwoWayRoad.png")
    },
    Wall_squadcar: {
      sceneName: "Auto parkeerplaats",
      image: require("@/assets/Backgrounds/Wall_squadcar.png")
    },
    Woods_squadcar: {
      sceneName: "Auto bos",
      image: require("@/assets/Backgrounds/Woods_squadcar.png")
    },
    Train: {
      sceneName: "Trein",
      image: require("@/assets/Backgrounds/Train.png")
    },
    Wall: {
      sceneName: "Muur",
      image: require("@/assets/Backgrounds/Wall.png")
    },
    Wood: {
      sceneName: "Bos",
      image: require("@/assets/Backgrounds/Woods.png")
    }
  }
};
