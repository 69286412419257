//This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)

import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";

import Dashboard from "../views/Dashboard.vue";
import ChapterEditor from "../views/ChapterEditor.vue";
import SceneEditor from "../views/SceneEditor.vue";
import NotFound from "../views/404.vue";
import Corkboard from "../views/Corkboard.vue";
import GeneralSettings from "../views/GeneralSettings.vue";
import DialogueEditor from "../views/DialogueEditor.vue";

Vue.use(VueRouter);

/** All routed for router */
const routes = [
  {
    path: "/",
    name: "Login",
    component: Login
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard
  },
  {
    path: "/chaptereditor",
    name: "chaptereditor",
    component: ChapterEditor
  },
  {
    path: "/sceneeditor",
    name: "sceneeditor",
    component: SceneEditor
  },
  {
    path: "/dialogueeditor",
    name: "dialogueeditor",
    component: DialogueEditor
  },
  {
    path: "/404",
    name: "404",
    component: NotFound
  },
  {
    path: "/Case_editor_manual.pdf",
    name: "manual",
  },
  {
    path: "/corkboard",
    name: "corkboard",
    component: Corkboard
  },
  {
    path: "/generalsettings",
    name: "generalsettings",
    component: GeneralSettings
  },
  {
    path: "*",
    name: "404Redirect",
    redirect: { name: "404" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
