<template>
<!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <Moveable v-bind="moveable" @dragStart="openEditPanel" @drag="handleDrag" @dragEnd="handleEndDrag" @scroll="handleScroll"
    :container="canvas"
    :scrollContainer="canvasScroll"
    v-if="loadNode"
    class="node overflow-hidden"
    :className="'dialogue-moveable'"
    :class="{
      playerdialogue:node.type == 'Player',
      nonplayerdialogue:node.type == 'NonPlayer', 
      choicemoment:node.type == 'Choice' && !selected,
      selectedchoice:node.type == 'Choice' && selected,
      optiondialogue:node.type == 'Option',
      startdialogue:node.type == 'Start',
      
      selected:node.type != 'Choice' && selected,
    }"
    :style="{
      transform:
        'matrix(1, 0, 0, 1, ' + node.position.x + ', ' + node.position.y + ')',
    }"
    @click="openEditPanel"
    ref="moveable">
    <div
      class="h-100"
      :id="_uid"
      ref="dialogueNode"
      :class="{
        playerdialogue:node.type == 'Player',
        nonplayerdialogue:node.type == 'NonPlayer', 
        choicemoment:node.type == 'Choice' && !selected,
        selectedchoice:node.type == 'Choice' && selected,
        optiondialogue:node.type == 'Option',
        startdialogue:node.type == 'Start',
        
        selected:node.type != 'Choice' && selected,
      }"
    >
      <!-- Input dot -->
      <div
        class="d-flex justify-content-center h-em-1"
        v-if="this.node.type!='Start'"
      >
        <div 
          class="dot inputdot"
          ref="dotinput"
          @mouseup="$emit('selectInputDot', node)"
        />

        <!-- The portrait of the node -->
        <div
          v-if="this.node.type=='NonPlayer'"
          class="prop-container w-100 align-items-end mr-1 mt-1"
        >
          <div class="icon-box">
            <font-awesome-icon
              v-if="this.node.portrait == 'Nothing'"
              icon="ban"
              class="icon"
            />
            <img
              v-else
              :src="require('../../assets/Props/' + this.node.portrait + '.png')"
              class="icon"
            />
          </div>
        </div>
      </div>
      <div v-if="this.node.type!='Start' && this.node.type!='Choice'" :class="{'gray-line mt-2':!selected, 'gray-line-dark mt-2':selected}"/>
      
      <!-- The body text of the node -->
      <div class="d-flex align-items-center justify-content-center w-100 h-70" v-if="this.node.type!='Start' && this.node.type!='Choice'">
        <div class="w-100 h-100 overflow-auto px-1">
          <span>{{this.node.text}}</span>             
        </div>
      </div>

      <div v-if="this.node.type!='Start' && this.node.type!='Choice'" :class="{'gray-line':!selected, 'gray-line-dark':selected}"/>
      
      <div v-if="this.node.type=='Start'" class="justify-content-center h-100 mt-3 mr-1">
        <span>Start</span>
      </div>
      
      <!-- Output dot -->
      <div class="scenedots d-flex justify-content-center">
        <div class="row">
          <NodeDot 
            v-for="(dot, i) in node.outputDots"
            :key="i"
            :dotData="dot"
            class="col p-0"
            ref="nodeDot"
            @createArrow="createArrow"
            @updateOutputDotPositions="updateOutputDotPositions"
            @mousedown="
              linedrag = true;
              $emit('selectOutputNode', i)
            "
          />
        </div>
      </div>
    </div>
  </Moveable>
</template>

<script>
import NodeDot from "./NodeDot";
import Moveable from "vue-moveable";

export default {
  name: "DialogueNode",
  components: {
    NodeDot,
    Moveable
  },
  props: ["node", "canvas", "canvasScroll", "overlaps", "selected"],
  data() {
    return {
      nodeData: this.node,
      moveable: {
        draggable: true,
        throttleDrag: 1,
        snappable: true,
        scrollable: true,
        scrollThreshold: 0
      },
      loadNode: false,
      linedrag: false
    };
  },
  methods: {
    openEditPanel() {
      this.$emit("openEditPanel");
    },
    createArrow(dotData) {
      this.$emit("createArrow", this.node, dotData); //Create a line when output dot is pressed.
    },
    updateInputDotPosition(nodePosition) {
      //Determine the location where the dialogue lines have to snap to
      if (this.node.type == "Start") 
        return;
      let dotPosition = this.$refs["dotinput"].getBoundingClientRect();

      this.node.inputDotPosition = {
        x:
          this.$refs["dotinput"].offsetLeft +
          nodePosition.x +
          dotPosition.width / 1.25,
        y: nodePosition.y + dotPosition.height
      };
    },
    updateOutputDotPositions() {
      const nodeDots = this.$refs.nodeDot;
      nodeDots?.forEach(dot => {
        const position = dot.offsetDotPosition(this.$refs.moveable.$el);
        if (position.x == position.x && position.y == position.y)
          //Check if the line positions are not NaN, because a deleted node can still there (vue reactivity is slow)
          this.$emit("setDotPosition", { index: dot.dotData.index, position });
      });
      this.$emit("loadDialogueLine");
    },
    /** Updates the positions while the node is dragged */
    handleDrag({ target, transform }) {
      if (this.linedrag) return;

      target.style.transform = transform;
      const newPosition = {
        x:
          parseInt(transform.split(")")[0].split(",")[4]) +
          parseInt(
            transform
              .split("(")[2]
              .split(",")[0]
              .split("px")[0]
          ),
        y:
          parseInt(transform.split(")")[0].split(",")[5]) +
          parseInt(
            transform
              .split("(")[2]
              .split(",")[1]
              .split("px")[0]
          )
      };
      this.$emit("setPosition", newPosition);
      this.updateInputDotPosition(this.node.position);
      this.updateOutputDotPositions();
    },
    /** Checks if there is any overlap with other nodes and if so resets the position */
    handleEndDrag({ lastEvent }) {
      if (this.linedrag) return;

      const rect = this.$refs.moveable.getRect();

      const overlapping = this.overlaps(rect);

      if (overlapping) {
        const oldPosition = {
          x: this.node.position.x - lastEvent.beforeTranslate[0],
          y: this.node.position.y - lastEvent.beforeTranslate[1]
        };
        this.$emit("setPosition", oldPosition);
        this.$nextTick(() => {
          this.updateInputDotPosition(this.node.position);
          this.updateOutputDotPositions();
          this.$refs.moveable.updateRect();
        });
      }
    },
    /** Allows you to scroll across the canvas when dragging this node past the window edges */
    handleScroll({ scrollContainer, direction }) {
      scrollContainer.scrollTo(
        scrollContainer.scrollLeft + direction[0] * 5,
        scrollContainer.scrollTop + direction[1] * 5
      );
    },
    getSize() {
      return this.$refs.moveable.getRect();
    }
  },

  mounted() {
    // if this node isn't loaded or it's a start node without a dot, add a dot
    if (
      !this.node.loaded ||
      (this.node.type == "Start" && this.node.outputDots.length == 0)
    )
      this.$emit("addDot", this.node);

    this.loadNode = true;
    this.$nextTick(() => {
      this.updateInputDotPosition(this.node.position);
      this.updateOutputDotPositions();
    });

    const rect = this.canvas.getBoundingClientRect();
    this.moveable.bounds = {
      top: 0,
      left: 0,
      right: rect.width,
      bottom: rect.height
    };
  }
};
</script>

<style lang="scss">
.dialogue-moveable {
  opacity: 0 !important;
}
</style>
<style lang="scss" scoped>
.node {
  height: 8em;
  width: 10em;
  position: absolute;
  text-align: center;
  justify-content: center;
  border: solid;
  border-radius: 1em;
  background-color: $semiwhite;
  &:hover{
    cursor: pointer;
  }
}

[class^="gray-line"] { 
  height: 1px;
  width: 65%;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.gray-line{
  background: $darkgray;
  &-dark{
    background: $darkergray;
  }
}

.h {
  &-70{
    height: 70%;
  }

  &-em {
    &-1{
      height: 0.80em;
    }
  }
}

.prop-container {
  display: flex;
  flex-direction: column;
}

.icon-box {
  margin-top: -4px;
  width: 12%;
  height: 200%;
}

.icon {
  width: auto;
  height: 100%;
  margin-bottom: 0%;
}

.inputdot {
  position: absolute;
  top: 0px;
  width: 100%;
}

.scenedots {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 0.85em;
  left: 0;
  display: table-cell;
  vertical-align: bottom;
}

.dot {
  width: 0.6em;
  height: 0.6em;
  background-color: $black;
  border-radius: 100%;
  margin: 0.2em;

  &:hover {
    background-color: rgb(0, 120, 240);
  }
}

.selected {
  background-color: $lightgray;
}

.selectedchoice {
  background-color: $optionDialogueSelected;
  border-color: $optionDialogue;
  height: 3em;
}

.playerdialogue {
  border-color: $playerDialogue;
}

.nonplayerdialogue {
  border-color: $nonplayerDialogue;
}

.choicemoment {
  background-color: $optionDialogue;
  border-color: $optionDialogue;
  height: 3em;
}

.optiondialogue {
  border-color: $optionDialogue;
}

.startdialogue {
  border-color: $black;
  height: 4em;
  width: 5em;  
}
.col {
  position: inherit !important;
}
</style>