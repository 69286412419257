<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div class="big-button my-3" @click="$emit('click', navTo)">
    <div class="big-button-icon-container mt-5">
      <font-awesome-icon class="big-button-icon" :icon="iconName" />
    </div>
    <p class="big-button-text mt-3">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "Node",
  props: ["text", "iconName", "navTo"],
  methods: {},
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.big-button {
  width: 17em;
  height: 13em;
  box-shadow: $box-shadow-white;
  border-radius: 2em;
  &:hover {
    cursor: pointer;
  }
}
.big-button-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.big-button-icon {
  color: #afafaf;
  width: 4em;
  height: 4em;
}
.big-button-text {
  color: #afafaf;
  font-family: $default-font;
  font-size: 2em;
  text-align: center;
}
</style>
