<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div class="modal">
    <header class="modal-header">
      <slot name="header"></slot>
    </header>
    <section class="modal-body">
      <slot name="body"></slot>
    </section>
    <footer class="modal-footer">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
export default {
  name: "ModalPage"
};
</script>

<style lang="scss" scoped>
.modal {
  background: #ffffff;
  box-shadow: 0px 0px 1em -0.2em;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  border-radius: 1.5em;

  top: auto;
  left: auto;
  width: 70vw;
  max-width: 120em;
  min-width: 10em;
  height: 85vh;
  max-height: 67.5em;
  min-height: 7.33em;
}

.modal-header {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 10%;
}

.modal-body {
  overflow-y: auto;
  position: relative;
}

.modal-footer {
  width: 100%;
  height: 10%;
}
</style>
