//This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./axiosInstance";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlay,
  faCog,
  faPenFancy,
  faPen,
  faPlus,
  faInfo,
  faInfoCircle,
  faCheck,
  faBookOpen,
  faAngleDown,
  faAngleUp,
  faMobile,
  faBox,
  faImage,
  faTimes,
  faFlag,
  faPaintBrush,
  faSmile,
  faMeh,
  faAngry,
  faBan,
  faSearch,
  faTrash,
  faMinus,
  faStar,
  faClipboard,
  faMusic
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueApexCharts from "vue-apexcharts";
import { DynamicReactiveRefs } from "vue-reactive-refs";
import AudioVisual from "vue-audio-visual";

library.add([
  faPlay,
  faCog,
  faPenFancy,
  faPen,
  faInfo,
  faPlus,
  faInfoCircle,
  faCheck,
  faBookOpen,
  faAngleDown,
  faAngleUp,
  faMobile,
  faBox,
  faImage,
  faTimes,
  faFlag,
  faPaintBrush,
  faSmile,
  faMeh,
  faAngry,
  faBan,
  faSearch,
  faTrash,
  faMinus,
  faStar,
  faClipboard,
  faMusic
]);

Vue.config.productionTip = false;
Vue.config.devtools = process.env.VUE_APP_DEVTOOLS || true;
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("apexchart", VueApexCharts);
Vue.use(AudioVisual);
Vue.use(require("vue-moment"));
Vue.use(VueApexCharts);

Vue.use(DynamicReactiveRefs);

/** Enums for event types */
Vue.prototype.$ENUM_EVENTTYPE = {
  DIALOGUE: "event:dialogue",
  SOUND: "event:sound",
  CHANGESCENE: "event:changeScene",
  HIDE: "event:hide",
  SHOW: "event:show",
  NOTEBOOK: "event:notebook",
  MINIGAME: "event:minigame",
  EMPTYEVENT: "event:emptyEvent"
};

/** Enums for event run types */
Vue.prototype.$ENUM_EVENTRUNTYPE = {
  CLICK: "run:click",
  TIME: "run:time",
  OBSERVE: "run:observe",
  CONDITION: "run:condition"
};

new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount("#app");
