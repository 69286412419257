<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <div
    class="list-item-container"
    :class="{ 'list-item-white': color, 'list-item-gray': !color }"
  >
    <div class="row w-100 mx-1 mb-n3 justify-content-center align-items-center">
      <div class="col-3">
        <p class="list-item-name" :class="{ bold: header }">
          {{ title }}
        </p>
      </div>
      <div class="col-2">
        <p class="list-item-name" :class="{ bold: header }">
          {{ code }}
        </p>
      </div>
      <div class="col-3 ml-n3">
        <p class="list-item-modified" v-if="header" :class="{ bold: header }">
          {{ lastModified }}
        </p>

        <p class="list-item-modified" v-else>
          {{ lastModified | moment("HH:mm - DD / MM / YYYY") }}
        </p>
      </div>
      <div class="col-3 ml-n1 mr-1">
        <p class="list-item-published" v-if="header" :class="{ bold: header }">
          {{ published }}
        </p>

        <p class="list-item-published" v-else-if="published">
          <font-awesome-icon
            class="list-chapter-icon"
            icon="check"
            @click="$emit('changePublished', id, 0)"
          />
          Gepubliceerd
        </p>

        <p class="list-item-published" v-else>
          <font-awesome-icon
            class="list-chapter-icon"
            icon="times"
            @click="$emit('changePublished', id, 1)"
          />
          Niet Gepubliceerd
        </p>
      </div>
      <div class="col-1 mb-3">
        <p class="list-item-edit" v-if="header" :class="{ bold: header }">
          Bewerken
        </p>
        <p v-else>
          <font-awesome-icon
            class="list-item-icon"
            @click="navigateToEdit"
            icon="pen"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListItem",
  props: [
    "id",
    "title",
    "lastModified",
    "color",
    "published",
    "header",
    "code"
  ],
  methods: {
    /** Router navigation to edit chapter */
    navigateToEdit() {
      this.$router.push({ name: "corkboard", query: { case: this.id } });
    }
  },
  computed: {
    /** Format date to time function */
    formatDateTime() {
      return this.lastModified;
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  font-family: $default-font;
  font-size: 1em;
}
.list-item-white {
  background: white;
}
.bold {
  font-weight: bold;
}
.hide {
  display: none;
}
.list-item-gray {
  background: $lightgray;
}
.list-item-container {
  word-wrap: normal;
  line-height: 2em;
  text-align: center;
  display: flex;
}
.list-item-id {
  text-align: left;
}
.list-item-name {
  text-align: left;
  word-break: break-word;
}
.list-item-published {
  text-align: left;
}
.list-item-mode {
  text-align: left;
}
.list-chapter-edit {
  text-align: left;
}
.list-item-icon {
  width: 0.9em;
  &:hover {
    cursor: pointer;
  }
}
.list-item-modified {
  text-align: left;
}
.list-chapter-icon {
  width: 0.9em;
  &:hover {
    cursor: pointer;
  }
}
</style>
