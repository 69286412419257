<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <!-- A list of attributes (currently either skills or contacts), which can be added to, removed from, and renamed -->
  <div class="attributeoverview w-100">
    <table class="table" id="attribute-table">
      <!-- The header -->
      <thead class="thead-light">
        <tr>
          <th scope="col" class="py-2">#</th>
          <th scope="col" class="py-2 w-100">
            {{ attributeType == "skills" ? "Vaardigheden" : "Contacten" }}
          </th>
          <th class="right"></th>
        </tr>
      </thead>
      <!-- The list of attributes -->
      <tr v-for="(attribute, i) in attributes" :key="attribute.id">
        <td scope="row">{{ i + 1 }}</td>
        <!-- The attribute name -->
        <td class="pl-5 w-100">
          <input
            class="form-control"
            :maxlength="25"
            autocomplete="off"
            v-model="attribute.name"
          />
        </td>
        <!-- The delete button -->
        <td class="right pr-4">
          <button
            class="btn btn-danger"
            @click="$emit('removeAttribute', attribute.id)"
          >
            Verwijder
          </button>
        </td>
      </tr>
    </table>

    <!-- The 'add new attribute' button -->
    <button
      class="btn ml-4 add-attribute-button"
      @click="$emit('addAttribute')"
    >
      Nieuwe
      {{ attributeType == "skills" ? "vaardigheid" : "contacten" }} toevoegen
    </button>
  </div>
</template>

<script>
export default {
  name: "AttributeOverview",
  props: ["attributes", "attributeType"]
};
</script>

<style lang="scss" scoped>
.attributeoverview {
  height: fit-content;
  max-height: 100%;
}
table {
  border-collapse: separate !important;
  border-spacing: 0;
}
.add-attribute-button {
  margin-bottom: 1em;
  background-color: $black;
  border: none;
  color: $white;
}
</style>
