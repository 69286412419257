<template>
  <div>
    <div class="w-100 h-100 d-flex mx-2 mt-1">
      <!--If the condition is a proper number (and not an operator)-->
      <div class="width-12 height-3-5-em">
        <button class="button-negator w-100 h-100" @click="toggleNegation">
          <!--Deactivate the icon if the operator is +-->
          <font-awesome-icon
            v-if="!this.negatedIcon"
            icon="minus"
            class="negator-icon-inactive fa-sm"
          />
          <font-awesome-icon v-else icon="minus" class="negator-icon fa-sm"/>
        </button>
      </div>
      <!--Dropdown-->
      <div class="width-76 height-3-5-em">
        <button
          class="dropdown-container w-100 height-3-5-em"
          @click="$emit('onConditionButton', index)"
        >
          {{
            this.condition && this.condition != 0
              ? this.getConditionDisplayName
              : "bladeren"
          }}
        </button>
      </div>
      <!--End Dropdown-->
      <div class="width-12 height-3-5-em">
        <button class="button-remove" @click="$emit('removeCondition', index)">
          <font-awesome-icon icon="times" class="fa-sm"/>
        </button>
        <button class="button-add" @click="$emit('addCondition', index)">
          <font-awesome-icon icon="plus" class="fa-sm"/>
        </button>
      </div>
    </div>
    <select
      v-if="andOrOperator"
      class="dropdown-container-and-or width-10 align-center"
      :value="andOrOperator"
      @change="$emit('switchOperator', index, $event.target.value);"
    >
      <option value="A">En</option>
      <option value="O">Of</option>
    </select>
  </div>
</template>

<script>
import EventDict from "../util/EventEnumToName";

export default {
  props: [
    "condition",
    "index",
    "chapterEventData",
    "negatedIcon",
    "andOrOperator"
  ],
  data() {
    return {
      eventDictionary: EventDict,
    };
  },
  methods: {
    toggleNegation() {
      const toggledNegatedIcon = !this.negatedIcon;
      if (toggledNegatedIcon) //If it's plus, set to -, else set to +
        this.$emit('toggleNegation', this.index, '-');
      else
        this.$emit('toggleNegation', this.index, '+');
    },
    quoteString(sentence){ //Shorten a string to its first and last two words
      const words = sentence.split(" ");
      if (words.length > 4)
        return words[0] + " " + words[1] + " ... " + words[words.length - 2] + " " + words[words.length - 1];
      else
        return sentence;
    }
  },
  computed: {
    //Convert the condition id into a suitable string displaying information of the condition on the condition button.
    getConditionDisplayName() {
      if (!this.chapterEventData) return;
      
      let condData = this.chapterEventData[this.condition.split('.')[0]];
      if (!condData)
        return;

      let displayString = condData.name;
      if (this.condition.includes('.')){ //If it's a dialogue node as condition
        condData = condData.dialogue[this.condition.split('.')[1]];
        displayString += ": ";
        if (condData.type == "Start") //Start node
          return displayString + "Start=>";
        else if (condData.type == "Choice") //Choice node
          return displayString + "<Choice>";
        else if (condData.type == "Option") //Option node
          return displayString + "> " + this.quoteString(condData.value);
        else if (condData.type == "Player" || condData.portrait != "Nothing") //Said by someone
          return displayString + "\"" + this.quoteString(condData.value) + "\"";
        else //Else it's narrative text
          return displayString + "\'" + this.quoteString(condData.value) + "\'";
      }
      else //Else the event type and the event name
        return this.eventDictionary[condData.eventType] + ": " + displayString;
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-container {
  overflow: auto;
  padding: 0;
  bottom: 11em;
  border: 1px black;
  border-style: solid;
  &:focus {
    outline: none;
  }
  &-and-or {
    border-radius: 10px;
    margin-left: 1em;
    margin-top: 5px;
    &:focus {
      outline: none;
    }
  }
}

.width {
  &-10{
    width: 10;
  }
  &-12{
    width: 12%;
  }
  &-76{
    width: 76%;
  }
}

.height {
  &-3-5-em{
    height: 3.5em;
  }
}

.negator-icon {
  &-inactive {
    color: rgba(0, 0, 0, 0.2);
  }
}

[class^="button"] {
  padding: 0;
  background-color: $white;
  border: 1px solid black;
  &:hover {
    background-color: $semiwhite;
  }
  &:active {
    background-color: $lightgray;
  }
  &:focus {
    outline: none;
  }
}

.button {
  &-negator {
    align-self: right;
    border-radius: 20px 0px 0px 20px;
    border-style: solid none solid solid;
  }

  &-remove {
    align-self: left;
    border-radius: 0px 20px 0px 0px;
    width: 100%;
    height: 50%;
    border-style: solid solid solid none;
    border-color: black black lightgray;
  }

  &-add {
    align-self: left;
    border-radius: 0px 0px 20px 0px;
    width: 100%;
    height: 50%;
    border-style: solid solid solid none;
    border-color: lightgray black black;
  }
}

* {
  box-sizing: border-box;
}
</style>
