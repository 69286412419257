<template>
  <!--This program has been developed by students from the bachelor Computer Science at Utrecht University within the Software Project course. © Copyright Utrecht University (Department of Information and Computing Sciences)-->
  <svg>
    <defs>
      <marker
        id="arrowhead"
        markerWidth="20"
        markerHeight="14"
        refX="0"
        refY="7"
        orient="auto"
      >
        <polygon points="20 0, 0 7, 20 14" />
      </marker>
    </defs>
    <polyline
      :points="
        lineEndPosition.x +
          ',' +
          lineEndPosition.y +
          ' ' +
          lineStartPosition.x +
          ',' +
          lineStartPosition.y
      "
      fill="none"
      stroke-width="1px"
      stroke="black"
      marker-start="url(#arrowhead)"
    />
  </svg>
</template>

<script>
export default {
  props: ["lineData","canvas","mousePosition", "selectedLine"],
  methods: {
    handleMouseUp(event) {
      if (this.selectedLine == this.lineData)
        this.$emit('deleteLine', this.lineData); 
    }
  },
  computed: {
    lineStartPosition() { //The start position is the output dot position of the source node
      return {
        x:
          this.lineData.sourceNode.position.x + 
          this.lineData.sourceNode.outputDots[this.lineData.sourceDot.index]
            .position.x - 0.5, //The subtractions are to align it with the middle of the dot
        y:
          this.lineData.sourceNode.position.y + 
          this.lineData.sourceNode.outputDots[this.lineData.sourceDot.index]
            .position.y - 4.5 // ^
      }
    },
    lineEndPosition() {
      if (!this.lineData.targetNode) { //If there is no end node yet (when dragging) return nothing.
        return {
          x: this.mousePosition.x - this.canvas.getBoundingClientRect().left,
          y: this.mousePosition.y - this.canvas.getBoundingClientRect().top
        };
      }
      return { //If there's a target node set end position to the input dot of that node
        x: this.lineData.targetNode.inputDotPosition.x - 0.5, //The subtractions are to align it with the middle of the dot
        y: this.lineData.targetNode.inputDotPosition.y + 1.5 // ^
      }
    },
  },
  created() {
    //Delete this line when dragging has ended. (In the function itself it will checked if a input dot has been selected)
    document.addEventListener("mouseup", this.handleMouseUp);
  },
  destroyed() {
    document.removeEventListener("mouseup", this.handleMouseUp);
  }
};
</script>
